import React from 'react'

import { useConfirmationPopup } from '~stores/slices/confirmation.slice'
import { usePreviewPopup } from '~stores/slices/preview.slice'

export const NotificationsHolder = () => {
  const previewComponent = usePreviewPopup()
  const component = useConfirmationPopup()

  return (
    <>
      {previewComponent}
      {component}
    </>
  )
}
