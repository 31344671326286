import React, { FC } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import styles from '~components/TemplateBlockComponent/blocks/template-blocks.module.scss'
import { TemplateFrameConnection } from '~components/TemplateBlockComponent/frames/template-frame-connection'
import { ITemplateBlockProperties } from '~models/template-block.model'

export interface TemplateConnectionBlockProps extends ITemplateBlockProperties {}

export const TemplateConnectionBlock: FC<TemplateConnectionBlockProps> = ({ view }) => {
  const blockClass = view === TemplateBlocksView.pure ? styles.templateBlockPure : styles.templateBlock

  return (
    <div className={blockClass}>
      <TemplateFrameConnection text="Connections" view={view} />
    </div>
  )
}
