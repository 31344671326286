import { Typography } from '@mui/material'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FileError, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { getBase64 } from '~helpers/get-base64'

import styles from './drop-zone..module.scss'

interface DropZoneProps {
  handleImageBase64: (image: string | null) => void
  handleImageBlob: (image: Blob) => void
  initClearImage?: boolean
  isLoading?: boolean
}

export const DropZone: FC<DropZoneProps> = ({ handleImageBlob, handleImageBase64, initClearImage, isLoading }) => {
  const { t: translate } = useTranslation()
  const [files, setFiles] = useState<File[]>([])
  const [imageBase64, setImageBase64] = useState<string | null>(null)

  const removeImage = useCallback(() => {
    setFiles([])
    setImageBase64(null)
  }, [])

  const setDropImage = (image: Blob) => {
    handleImageBlob(image)
    getBase64(image).then((base64) => {
      if (typeof base64 === 'string') {
        setImageBase64(base64)
      }
    })
  }

  const getDropzoneErrors = (errors: FileError[]): string => {
    if (errors.some((error) => error.code === 'file-invalid-type')) {
      return translate('wrong-file-type')
    }

    if (errors.some((error) => error.code === 'file-too-large')) {
      return translate('the-file-is-too-large')
    }

    let response = ''

    for (let i = 0; i < errors.length; i += 1) {
      response += errors[i].message.replace('/*', '')
    }

    return response
  }

  const { getRootProps, isDragAccept, isDragReject, getInputProps, fileRejections } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg', '.jpe'],
    },
    maxSize: 5242880,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
      setDropImage(acceptedFiles[0])
    },
  })

  const dropClassName = useMemo(() => {
    let className = styles.dropZone

    className += isDragAccept ? ` ${styles.onDrop}` : ''
    className += isDragReject || fileRejections.length ? ` ${styles.onReject}` : ''

    return className
  }, [isDragAccept, isDragReject, fileRejections])

  useEffect(() => {
    handleImageBase64(imageBase64)
  }, [handleImageBase64, imageBase64])

  useEffect(() => {
    removeImage()
  }, [initClearImage, removeImage])

  return (
    <div className={`${styles.cropContainer} ${isLoading ? 'box-loading' : ''}`}>
      <div className={styles.hasLoader}>
        {!files.length || !imageBase64 ? (
          <div {...getRootProps({ className: dropClassName })}>
            <input {...getInputProps()} />
            <div>
              <Typography variant="body1">
                Drag and drop image file here, <br /> or click to select file
              </Typography>
              {fileRejections.length ? (
                <Typography color="red" mt={1} variant="body1">
                  {getDropzoneErrors(fileRejections[0].errors)}
                </Typography>
              ) : null}
            </div>
          </div>
        ) : (
          <div className={styles.imgPreview}>
            <img alt="" src={imageBase64} />
          </div>
        )}
      </div>
    </div>
  )
}
