export const getAmountOfMatches = (arr: any[], values: any[]) => {
  let amount = 0

  values.forEach((value) => {
    if (arr.includes(value)) {
      amount += 1
    }
  })

  return amount
}
