import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TemplateType } from '~/enums/template-type.enum'
import { TemplateTableCells } from '~/enums/templates.enum'
import { EmptyTableRow } from '~components/EmptyTableRow/empty-table-row'
import { Spinner } from '~components/Spinner/spinner'
import { TableSpinner } from '~components/TableSpinner/table-spinner'
import { TemplatesTableRow } from '~components/TemplatesTableRow/templates-table-row'
import { ITemplateListModel, ITemplateTableCells } from '~models/template.model'
import { useTemplateIsFetching } from '~stores/slices/template.slice'

interface TemplatesTableProps {
  templates?: ITemplateListModel[]
  isFetching: boolean
  isLoading: boolean
  searchRequest?: string
  handleSortBy: (sortBy: string) => void
  handleIsSortAscending: (isSortAscending: boolean) => void
  headCells: ITemplateTableCells[]
  type?: TemplateType
}

export const TemplatesTable: FC<TemplatesTableProps> = ({
  searchRequest,
  templates,
  isFetching,
  isLoading,
  handleSortBy,
  handleIsSortAscending,
  headCells,
  type = TemplateType.Transit,
}) => {
  const { t: translate } = useTranslation()
  const templateIsFetching = useTemplateIsFetching()

  const [sortBy, setSortBy] = useState<string>(TemplateTableCells.createdAt)
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>()
  const [isSortAscending, setIsSortAscending] = useState<boolean>(false)

  const handleSort = useCallback(
    (id: string) => {
      if (id === sortBy) {
        setIsSortAscending(!isSortAscending)
      } else {
        setIsSortAscending(true)
      }

      setSortBy(id)
    },
    [isSortAscending, sortBy],
  )

  useEffect(() => (isSortAscending ? setOrder('asc') : setOrder('desc')), [isSortAscending])

  useEffect(() => {
    handleSortBy(sortBy)
  }, [handleSortBy, sortBy])

  useEffect(() => {
    handleIsSortAscending(isSortAscending)
  }, [handleIsSortAscending, isSortAscending])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <TableContainer
        className={isFetching || templateIsFetching ? 'loading has-loader' : 'has-loader'}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(({ id, align, width, label, sortable, style }) => (
                <TableCell align={align} key={id} sx={style} width={width}>
                  <TableSortLabel
                    active={Boolean(templates?.length) && sortable && sortBy === id}
                    direction={sortBy === id ? order : 'asc'}
                    disabled={!templates?.length || !sortable}
                    hideSortIcon={!sortable}
                    onClick={() => handleSort(id)}
                  >
                    {id === TemplateTableCells.pages ? (
                      <span className="pages-holder">{translate(label)}</span>
                    ) : (
                      <>{translate(label)}</>
                    )}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {templates?.length ? (
            <TableBody>
              {templates.map((item) => (
                <TemplatesTableRow key={item.id} templateItem={item} templateType={type} />
              ))}
            </TableBody>
          ) : null}
        </Table>
        {!templates?.length && (
          <EmptyTableRow>
            {searchRequest ? translate('no-templates-found') : translate('you-dont-have-any-templates-yet')}
          </EmptyTableRow>
        )}
        <TableSpinner show={isFetching || templateIsFetching} />
      </TableContainer>
    </>
  )
}
