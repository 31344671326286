import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

interface IImageBlocks {
  data: {
    [key: string]: string
  }
  fetchingImages: string[]
}

const initialState: IImageBlocks = {
  data: {},
  fetchingImages: [],
}

const imageBlocksSlice = createSlice({
  name: 'imageBlocksSlice',
  initialState,
  reducers: {
    setImageBlock: (state, { payload }: PayloadAction<{ image: string; imageBase64: string }>) => {
      state.data[payload.image] = payload.imageBase64
    },
    setFetchingImage: (state, { payload }: PayloadAction<{ image: string }>) => {
      state.fetchingImages.push(payload.image)
    },
    removeFetchingImage: (state, { payload }: PayloadAction<{ image: string }>) => {
      const targetIndex = state.fetchingImages.findIndex((image) => image === payload.image)

      state.fetchingImages.splice(targetIndex, 1)
    },
    clearImageBlocks: (state) => {
      state.data = {}
    },
  },
})

export const getImageBlocks = (state: RootState) => state.imageBlocks.data
export const getFetchingImages = (state: RootState) => state.imageBlocks.fetchingImages
export const useImageBlocks = () => useAppSelector(getImageBlocks)
export const useFetchingImages = () => useAppSelector(getFetchingImages)

export const {
  reducer: imageBlocksReducer,
  actions: { setImageBlock, clearImageBlocks, setFetchingImage, removeFetchingImage },
} = imageBlocksSlice
