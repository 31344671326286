import { InfoOutlined } from '@mui/icons-material'
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DeployedTag } from '~components/DeployedTag/deployed-tag'
import { sortByFields } from '~helpers/sort-by-fields'
import { IBusStopTemplatesModel } from '~models/bus-stop-templates.model'
import { BusStopTemplatesTablePopover } from '~pages/BusStop/components/bus-stop-templates-table-popover'

interface BusStopMainTableRowProps {
  busStopItem: IBusStopTemplatesModel
}

export const BusStopTemplatesTableRow: FC<BusStopMainTableRowProps> = ({ busStopItem }) => {
  const { t: translate } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const { id, code, name, templates } = busStopItem

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <TableRow key={id}>
      <TableCell>{code}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        {templates.length ? (
          <>
            <div className="deployed-tag-holder">
              {sortByFields([...templates], false, 'type').map((template) => (
                <DeployedTag key={template.id} template={template} />
              ))}
              <Tooltip title={translate('details')}>
                <IconButton className="btn-info" onClick={handlePopoverOpen}>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </div>
            <BusStopTemplatesTablePopover
              anchorElement={anchorEl}
              busStopName={name}
              onClose={handlePopoverClose}
              templates={templates}
            />
          </>
        ) : (
          translate('none')
        )}
      </TableCell>
    </TableRow>
  )
}
