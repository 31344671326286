import { Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const NotFound = () => {
  const { t: translate } = useTranslation()

  return (
    <Container>
      <Typography align="center" variant="h4">
        {translate('not-found')}
      </Typography>
    </Container>
  )
}
