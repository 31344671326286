import { TemplateType } from '~/enums/template-type.enum'
import { TemplateAction, TemplateStatusesEnum as statuses } from '~/enums/templates.enum'
import { IActionItem } from '~models/menu-actions.model'

interface IResponse {
  title: string
  message: string
  agreeButtonText?: string
  additionalButtonText?: string
}

export const getNotificationMessage = (selectedItem: IActionItem) => {
  const { action, status, hasChanges, templateType } = selectedItem

  const response: IResponse = { title: '', message: '', agreeButtonText: '' }

  if (action === 'delete' && status === statuses.Draft)
    return {
      title: 'delete-template',
      message: 'template-will-be-permanently-deleted',
      agreeButtonText: TemplateAction[action],
    } as IResponse

  if (action === 'delete' && status !== statuses.Draft)
    return {
      title: 'template-not-deleted',
      message: 'template-was-not-be-deleted',
    } as IResponse

  if (action === 'preview')
    return {
      title: 'unsaved-changes',
      message: 'this-template-has-some-unsaved-changes-short',
      agreeButtonText: 'discard-and-preview',
      additionalButtonText: 'save-changes-and-preview',
    } as IResponse

  if (templateType === TemplateType.Emergency && action === 'publish' && hasChanges) {
    return {
      title: 'publish-template',
      message: 'template-unsaved-message',
      agreeButtonText: 'discard-and-publish',
      additionalButtonText: 'save-changes-and-publish',
    } as IResponse
  }

  if (action === 'publish') {
    if (hasChanges) {
      return {
        title: 'publish-template',
        message: 'template-unsaved-message',
        agreeButtonText: 'discard-and-publish',
        additionalButtonText: 'save-changes-and-publish',
      } as IResponse
    }

    return {
      title: 'publish-template',
      message: 'template-will-be-publish',
      agreeButtonText: TemplateAction[action],
    } as IResponse
  }

  if (action === 'setDraft')
    return {
      title: 'are-you-sure',
      message: 'template-will-be-moved-to-draft',
      agreeButtonText: TemplateAction[action],
    } as IResponse

  return response
}
