import React, { FC, useMemo } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import styles from '~components/TemplateBlockComponent/blocks/template-blocks.module.scss'
import { TemplateFrameSimple } from '~components/TemplateBlockComponent/frames/template-frame-simple'
import { ITemplateBlockProperties } from '~models/template-block.model'

export interface TemplateStaticLabelBlockProps extends ITemplateBlockProperties {
  mode: 'route' | 'destination' | 'stop' | string
}

export const TemplateStaticLabelBlock: FC<TemplateStaticLabelBlockProps> = ({ mode, view }) => {
  const blockClass = view === TemplateBlocksView.pure ? styles.templateBlockPure : styles.templateBlock

  const text = useMemo(() => {
    if (mode === 'route') return 'Route'
    if (mode === 'destination') return 'Destination'
    if (mode === 'stop') return 'Stop'

    return null
  }, [mode])

  return (
    <div className={blockClass}>{text && <TemplateFrameSimple dataType="static-text" text={text} view={view} />}</div>
  )
}
