import {
  Checkbox,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BusStopsTableCells } from '~/enums/bus-stops.enum'
import { EmptyTableRow } from '~components/EmptyTableRow/empty-table-row'
import { Spinner } from '~components/Spinner/spinner'
import { TableSpinner } from '~components/TableSpinner/table-spinner'
import { getAmountOfMatches } from '~helpers/get-amount-of-matches'
import { IBusStopModel, IBusStopTableCells } from '~models/bus-stop.model'

interface TemplatesTableProps {
  busStops?: IBusStopModel[]
  isFetching: boolean
  isLoading: boolean
  searchRequest?: string
  handleSortBy: (sortBy: BusStopsTableCells) => void
  handleIsSortAscending: (isSortAscending: boolean) => void
  handleBusStops: (busStops: number[]) => void
  headCells: IBusStopTableCells[]
  singleMode?: boolean
}

export const BusStopsTable: FC<TemplatesTableProps> = ({
  searchRequest,
  busStops,
  isFetching,
  isLoading,
  handleSortBy,
  handleIsSortAscending,
  handleBusStops,
  headCells,
  singleMode,
}) => {
  const { t: translate } = useTranslation()

  const [sortBy, setSortBy] = useState<BusStopsTableCells>(BusStopsTableCells.stopCode)
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>()
  const [isSortAscending, setIsSortAscending] = useState<boolean>(true)

  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [currentRowsIds, setCurrentRowsIds] = useState<number[]>([])

  const [rowsCount, setRowsCount] = useState<number>(0)
  const [numSelected, setNumSelected] = useState<number>(0)

  useEffect(() => (isSortAscending ? setOrder('asc') : setOrder('desc')), [isSortAscending])

  useEffect(() => {
    handleSortBy(sortBy)
  }, [handleSortBy, sortBy])

  useEffect(() => {
    handleIsSortAscending(isSortAscending)
  }, [handleIsSortAscending, isSortAscending])

  useEffect(() => {
    handleBusStops(selectedIds)
  }, [handleBusStops, selectedIds])

  useEffect(() => {
    const amountOfMatches = getAmountOfMatches(currentRowsIds, selectedIds)

    setNumSelected(amountOfMatches)
  }, [selectedIds, currentRowsIds])

  useEffect(() => {
    if (busStops) {
      const rowsIds = busStops.map(({ busStopId }) => busStopId)

      setCurrentRowsIds([...rowsIds])
      setRowsCount(busStops.length)
    }
  }, [busStops])

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && busStops) {
        const mergedIds = [...selectedIds, ...currentRowsIds]
        const newSelectedIds = mergedIds.filter((item, index) => mergedIds.indexOf(item) === index)

        setSelectedIds([...newSelectedIds])

        return
      }

      const newSelectedIds = selectedIds.filter((item) => !currentRowsIds.some((n) => n === item))

      setSelectedIds([...newSelectedIds])
    },
    [busStops, selectedIds, currentRowsIds],
  )

  const handleSelect = (id: number) => {
    if (singleMode) {
      setSelectedIds([id])

      return
    }

    let updatedList = [...selectedIds]
    const selectedIndex = selectedIds.indexOf(id)

    if (selectedIndex !== -1) {
      updatedList.splice(selectedIndex, 1)
    } else {
      updatedList = [...selectedIds, id]
    }

    setSelectedIds([...updatedList])
  }

  const isSelected = (id: number) => selectedIds.includes(id)

  const handleSort = useCallback(
    (id: BusStopsTableCells) => {
      if (id === sortBy) {
        setIsSortAscending(!isSortAscending)
      } else {
        setIsSortAscending(true)
      }

      setSortBy(id)
    },
    [isSortAscending, sortBy],
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <TableContainer className={isFetching ? 'loading has-loader' : 'has-loader'}>
      <Table className="table-md" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              {!singleMode && (
                <Checkbox
                  checked={numSelected > 0 && numSelected === rowsCount}
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowsCount}
                  onChange={handleSelectAll}
                />
              )}
            </TableCell>
            {headCells.map(({ id, align, width, label, sortable, style }) => (
              <TableCell align={align} key={id} sx={style} width={width}>
                <TableSortLabel
                  active={Boolean(busStops?.length) && sortable && sortBy === id}
                  direction={sortBy === id ? order : 'asc'}
                  disabled={!busStops?.length || !sortable}
                  hideSortIcon={!sortable}
                  onClick={() => handleSort(id)}
                >
                  {translate(label)}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {busStops?.length ? (
          <TableBody>
            {busStops.map((busStopItem) => {
              const { busStopId, code, name } = busStopItem
              const isItemSelected = isSelected(busStopId)

              return (
                <TableRow
                  hover
                  key={busStopId}
                  onClick={() => handleSelect(busStopId)}
                  role="checkbox"
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    {singleMode ? (
                      <Radio checked={isItemSelected} color="primary" name="radio-buttons" value={busStopId} />
                    ) : (
                      <Checkbox checked={isItemSelected} color="primary" value={busStopId} />
                    )}
                  </TableCell>
                  <TableCell>{code}</TableCell>
                  <TableCell>{name}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        ) : null}
      </Table>
      {!busStops?.length && (
        <EmptyTableRow>{searchRequest ? translate('no-results-matching') : translate('stops-not-found')}</EmptyTableRow>
      )}
      <TableSpinner show={isFetching} />
    </TableContainer>
  )
}
