import { Action, combineReducers, configureStore, Middleware, Reducer, ThunkAction } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import undoable, { groupByActionTypes, includeAction } from 'redux-undo'

import { agencyApi } from '~stores/services/agency.api'
import { busServiceApi } from '~stores/services/bus-service.api'
import { busStopsApi } from '~stores/services/bus-stops.api'
import { layoutsApi } from '~stores/services/layouts.api'
import { templateApi } from '~stores/services/template.api'
import { agencyLogoReducer } from '~stores/slices/agency-logo.slice'
import { authReducer } from '~stores/slices/auth.slice'
import { confirmationReducer } from '~stores/slices/confirmation.slice'
import { dragBlockReducer } from '~stores/slices/drag-block.slice'
import { imageBlocksReducer } from '~stores/slices/image-blocks.slice'
import { layoutReducer } from '~stores/slices/layout.slice'
import { layoutBlocksReducer } from '~stores/slices/layout-blocks.slice'
import { layoutDynamicBlocksReducer } from '~stores/slices/layout-dynamic-blocks.slice'
import { previewReducer } from '~stores/slices/preview.slice'
import { templateReducer } from '~stores/slices/template.slice'

const persistConfig = {
  key: 'root',
  storage,
}

export const combineApi = [busServiceApi, templateApi, layoutsApi, busStopsApi, agencyApi]

const apiReducers: { [key: string]: Reducer } = {}

combineApi.map((api) => (apiReducers[api.reducerPath] = api.reducer))

export const reducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  agencyLogo: agencyLogoReducer,
  dragBlock: dragBlockReducer,
  confirmation: confirmationReducer,
  preview: previewReducer,
  layout: layoutReducer,
  layoutBlocks: layoutBlocksReducer,
  layoutDynamicBlocks: layoutDynamicBlocksReducer,
  imageBlocks: imageBlocksReducer,
  template: undoable(templateReducer, {
    filter: includeAction([
      'templateSlice/setTemplateBlocks',
      'templateSlice/setTemplateBlock',
      'templateSlice/duplicateTemplatePage',
      'templateSlice/deleteTemplatePage',
      'templateSlice/setTemplatePages',
      'templateSlice/addTemplatePage',
    ]),
    groupBy: groupByActionTypes('templateSlice/setTemplateActivePage'),
  }),

  ...apiReducers,
})

const middlewares: Middleware[] = combineApi.map((api) => api.middleware)

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
