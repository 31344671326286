export enum JustifyContent {
  flexStart = 'flex-start',
  center = 'center',
  flexEnd = 'flex-end',
  spaceBetween = 'space-between',
}

export enum AlignItems {
  flexStart = 'flex-start',
  center = 'center',
  flexEnd = 'flex-end',
}

export enum TextAlign {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify',
}

export type JustifyContentKeys = keyof typeof JustifyContent
export type AlignItemsKeys = keyof typeof AlignItems
