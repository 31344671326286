import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'

import { IAuthData } from '~models/auth.model'
import { IUserModel } from '~models/user.model'
import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

interface IAuth {
  data: IAuthData
}

const initialState: IAuth = {
  data: {
    accessToken: '',
    accessTokenExpireTime: '',
    user: {
      agencyId: 0,
      name: '',
      userName: '',
      tenantId: '',
      email: '',
      city: '',
      country: '',
      role: '',
    },
  },
}

const authSlice = createSlice({
  name: 'AuthSlice',
  initialState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.data.accessToken = payload
    },
    setUser: (state, { payload }: PayloadAction<IUserModel>) => {
      state.data.user = payload
    },
    saveAgencyId: (state, action: PayloadAction<number>) => {
      state.data.user.agencyId = action.payload
    },
    clearPersist: (state) => {
      storage.removeItem('persist:root')
      localStorage.clear()
      sessionStorage.clear()

      state.data = initialState.data

      return state
    },
  },
})

export const selectAgencyId = (state: RootState) => state.auth.data.user.agencyId
export const selectUser = (state: RootState) => state.auth.data.user
export const selectAccessToken = (state: RootState) => state.auth.data.accessToken

export const useAgencyId = () => useAppSelector(selectAgencyId)
export const useUser = () => useAppSelector(selectUser)
export const useAccessToken = () => useAppSelector(selectAccessToken)

export const {
  reducer: authReducer,
  actions: { setToken, setUser, saveAgencyId, clearPersist },
} = authSlice
