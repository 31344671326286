import { useEffect, useMemo } from 'react'

import { DisplaySizeKeys } from '~/enums/display-size'
import { DEFAULT_DISPLAY_SIZE } from '~constants/constants'
import { splitByCapitalLetter } from '~helpers/split-by-capital-letter'

interface GetCanvasPropertiesProps {
  windowHeight?: number
  windowWidth?: number
  containerWidth?: number
  headerPanelDisabled?: boolean
  footerPanelDisabled?: boolean
  asideBar?: 'one' | 'two'
  offsetY?: number
  skipCssVariables?: boolean
  fullScreen?: boolean
  displaySize?: DisplaySizeKeys
}

interface ICanvasProperties {
  [key: string]: number
}

type ICanvasConfig = {
  [key in DisplaySizeKeys]: {
    canvasFullWidth: number
    canvasRatio: number
    canvasCols: number
    canvasRows: number
    canvasBaseFontSize: number
    canvasPaddingPercent: number
    canvasGutterPercent: number
  }
}

const canvasConfig: ICanvasConfig = {
  ThirteenInchesEInk: {
    canvasFullWidth: 1600,
    canvasRatio: 0.75,
    canvasCols: 12,
    canvasRows: 24,
    canvasBaseFontSize: 10,
    canvasPaddingPercent: 0.01875,
    canvasGutterPercent: 0.010625,
  },
  TwentyEightInchesEInk: {
    canvasFullWidth: 4260,
    canvasRatio: 9 / 32,
    canvasCols: 36,
    canvasRows: 36,
    canvasBaseFontSize: 7.5,
    canvasPaddingPercent: 0.005,
    canvasGutterPercent: 0.0034,
  },
}

export const useCanvasProperties = ({
  windowHeight,
  windowWidth,
  containerWidth,
  headerPanelDisabled,
  footerPanelDisabled,
  asideBar,
  offsetY,
  skipCssVariables,
  fullScreen,
  displaySize = DEFAULT_DISPLAY_SIZE,
}: GetCanvasPropertiesProps) => {
  const {
    canvasFullWidth,
    canvasCols,
    canvasRows,
    canvasRatio,
    canvasGutterPercent,
    canvasPaddingPercent,
    canvasBaseFontSize,
  } = canvasConfig[displaySize]

  const canvasHeaderPanelHeightPercent = 0.10582333
  const canvasFooterPanelHeightPercent = 0.0810582333

  const pageHeightOffset = fullScreen ? 0 : offsetY ? offsetY : 120
  const canvasPaddingX = 48
  const asideWidth = 251

  let pageWidthOffset: number = fullScreen ? 0 : 20

  switch (asideBar) {
    case 'one':
      pageWidthOffset = canvasPaddingX + asideWidth
      break
    case 'two':
      pageWidthOffset = canvasPaddingX + asideWidth * 2
      break

    default:
      break
  }

  let canvasOuterWidth: number = 1000

  if (windowHeight) {
    canvasOuterWidth = (windowHeight - pageHeightOffset) / canvasRatio
  }

  if (windowWidth && canvasOuterWidth > windowWidth - pageWidthOffset) {
    canvasOuterWidth = windowWidth - pageWidthOffset
  }

  if (containerWidth) {
    canvasOuterWidth = containerWidth
  }

  const canvasPadding = canvasOuterWidth * canvasPaddingPercent
  const canvasWidth = canvasOuterWidth - canvasPadding * 2
  const canvasScale = canvasOuterWidth / canvasFullWidth
  const canvasFontSize = canvasScale * canvasBaseFontSize

  const canvasOuterHeight = canvasOuterWidth * canvasRatio

  const canvasFooterPanelHeight = footerPanelDisabled
    ? 0
    : Math.ceil(canvasOuterHeight * canvasFooterPanelHeightPercent)
  const canvasHeaderPanelHeight = headerPanelDisabled
    ? 0
    : Math.ceil(canvasOuterHeight * canvasHeaderPanelHeightPercent)
  const canvasHeight = canvasOuterHeight - canvasFooterPanelHeight - canvasHeaderPanelHeight - canvasPadding * 2

  const canvasGutter = canvasOuterWidth * canvasGutterPercent

  const canvasColWidth = canvasWidth / canvasCols
  const canvasRowHeight = canvasHeight / canvasRows

  const canvasProperties: ICanvasProperties = useMemo(
    () => ({
      canvasOuterWidth,
      canvasWidth,
      canvasScale,
      canvasPadding,
      canvasGutter,
      canvasFontSize,
      canvasCols,
      canvasRows,
      canvasRatio,
      canvasOuterHeight,
      canvasHeight,
      canvasFooterPanelHeight,
      canvasHeaderPanelHeight,
      canvasColWidth,
      canvasRowHeight,
    }),
    [
      canvasColWidth,
      canvasFontSize,
      canvasFooterPanelHeight,
      canvasGutter,
      canvasHeaderPanelHeight,
      canvasHeight,
      canvasOuterHeight,
      canvasOuterWidth,
      canvasPadding,
      canvasRowHeight,
      canvasScale,
      canvasWidth,
    ],
  )

  useEffect(() => {
    if (!skipCssVariables) {
      const styleElement = document.documentElement.style

      for (const key in canvasProperties) {
        if (key === 'canvasCols' || key === 'canvasRows' || key === 'canvasRatio') {
          styleElement.setProperty(`--${splitByCapitalLetter(key)}`, String(canvasProperties[key]))
        } else {
          styleElement.setProperty(`--${splitByCapitalLetter(key)}`, `${canvasProperties[key]}px`)
        }
      }
    }
  }, [canvasProperties, skipCssVariables])

  return canvasProperties
}
