import { Editor, IAllProps } from '@tinymce/tinymce-react'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'

import { AlignItems } from '~/enums/align-settings.enum'
import { PALETTE_64, TEXT_DEFAULT_FONT_SIZE } from '~constants/constants'
import { useAppDispatch } from '~stores/hooks'
import { setTemplateBlock } from '~stores/slices/template.slice'

const getFontSizeValues = (): string => {
  let fontSizeValues: string = ''

  for (let i = Number(TEXT_DEFAULT_FONT_SIZE); i <= 19; i += 1) {
    fontSizeValues += `${i}em=${i}rem `
  }

  return fontSizeValues.trim()
}

const getColorMap = () => {
  const colors: string[] = []

  PALETTE_64.forEach((item) => {
    colors.push(item)
    colors.push('')
  })

  return colors
}

const wysiwygConfig: IAllProps = {
  init: {
    inline: true,
    // fixed_toolbar_container: '#mytoolbar',
    toolbar: 'fontfamily fontsize forecolor | alignleft aligncenter alignright alignjustify',
    font_family_formats:
      'Times New Roman=times new roman,times,serif; Verdana=verdana,geneva,sans-serif; ' +
      'Helvetica=helvetica,arial,sans-serif; Tahoma=tahoma,verdana,segoe,sans-serif; ' +
      'Calibri=calibri, candara, segoe, "segoe ui", optima, sans-serif;',
    font_size_formats: getFontSizeValues(),
    formats: {
      // Changes the default format for the bold button to produce a span with a bold class
      // bold: { inline: 'span', classes: 'bold' },
    },
    custom_colors: false,
    color_map: getColorMap(),
    // color_default_foreground: getColorMap()[0],
    menubar: false,
    setup(editor) {
      // editor.form
      editor.on('PreInit', () => {
        editor.getBody().style.fontSize = `${TEXT_DEFAULT_FONT_SIZE}rem`
      })
    },
  },
}

interface WysiwygEditorProps {
  text: string
  alignItems?: AlignItems
  backgroundColor?: string
  blockId?: string
}

export const WysiwygEditor: FC<WysiwygEditorProps> = ({ text, blockId, alignItems, backgroundColor }) => {
  const dispatch = useAppDispatch()

  const [value, setValue] = useState(text)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setValue(text)
  }, [text])

  const onBlur = () => {
    if (blockId) {
      dispatch(setTemplateBlock({ id: blockId, text: value }))
    }

    setIsEditing(false)
  }

  const onClick = () => {
    setIsEditing(true)
  }

  const change = (value: string) => {
    setValue(value)
  }

  const handleChangeSettings = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.currentTarget.value)

    if (blockId) {
      dispatch(setTemplateBlock({ id: blockId, backgroundColor: event.currentTarget.value }))
    }
  }

  return (
    <div className={`wysiwyg-holder ${isEditing ? 'editing' : ''}`}>
      {/* <div className={`custom-toolbar ${isEditing ? 'editing' : ''}`} id="mytoolbar"> */}
      {/*   <div className="custom-toolbar-line"> */}
      {/*     <ColorPicker */}
      {/*       defaultChecked={backgroundColor || '#000'} */}
      {/*       name="backgroundColor" */}
      {/*       onChange={(e) => handleChangeSettings(e)} */}
      {/*     /> */}
      {/*     <div className="tox"> */}
      {/*       <VerticalAlignButtons alignItems={alignItems} blockId={blockId} /> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </div> */}
      <Editor
        apiKey="ye05bnz7t6if1a97ovopxc77sv261qbsfzsym306cywnpct2"
        init={{
          ...wysiwygConfig.init,
        }}
        onBlur={() => onBlur()}
        onClick={() => onClick()}
        onEditorChange={(value) => change(value)}
        value={value}
      />
    </div>
  )
}
