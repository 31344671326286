import React, { FC } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import styles from '~components/TemplateBlockComponent/blocks/template-blocks.module.scss'
import { TemplateFrameIcons } from '~components/TemplateBlockComponent/frames/template-frame-icons'
import { TemplateFrameSimple } from '~components/TemplateBlockComponent/frames/template-frame-simple'
import { ITemplateBlockProperties } from '~models/template-block.model'

export interface TemplateRouteBlockProps extends ITemplateBlockProperties {
  mode: 'route' | 'destination' | 'route-destination' | 'route-icons' | 'route-icons-destination' | 'message' | string
  routeIndex?: number
}

export const TemplateRouteBlock: FC<TemplateRouteBlockProps> = ({ mode, view, routeIndex }) => {
  const blockClass = view === TemplateBlocksView.pure ? styles.templateBlockPure : styles.templateBlock

  return (
    <div className={`${blockClass} ${styles.inversionView}`}>
      {mode === 'route' ? (
        <TemplateFrameSimple data-route={routeIndex} dataType="route" text="Route #" view={view} />
      ) : mode === 'destination' ? (
        <TemplateFrameSimple data-route={routeIndex} dataType="route" text="Destination" view={view} />
      ) : mode === 'route-destination' ? (
        <>
          <TemplateFrameSimple
            data-route={routeIndex}
            dataType="route"
            text="Route #"
            title="Destination"
            view={view}
          />
        </>
      ) : mode === 'route-icons' ? (
        <TemplateFrameIcons
          data-route={routeIndex}
          dataType="route"
          iconBottom="CM"
          iconTop="CT"
          text="Route #"
          view={view}
        />
      ) : mode === 'route-icons-destination' ? (
        <>
          <TemplateFrameSimple align="left" dataType="route" text="Destination" view={view} />
          <TemplateFrameIcons dataType="route" iconBottom="CM" iconTop="CT" text="Route #" view={view} />
        </>
      ) : null}
    </div>
  )
}
