import React, { FC, useEffect, useState } from 'react'

import { showByFormat } from '~helpers/date-helper'

interface TemplateTimeProps {
  format?: string
  preview?: boolean
}

export const TemplateDate: FC<TemplateTimeProps> = ({ format = 'YYYY-MM-DD', preview }) => {
  const [date, setDate] = useState('')

  useEffect(() => {
    setDate(showByFormat(Date.now(), format))
  }, [format])

  return <>{preview ? <span data-date={format} /> : date}</>
}
