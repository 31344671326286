import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { PageUrls } from '~/enums/page-urls.enum'

export const TemplateNotFound = () => {
  const { t: translate } = useTranslation()

  return (
    <Container>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <div>
          <Typography fontWeight="bold" marginBottom={2} variant="h2">
            Oops!
          </Typography>
          <Typography marginBottom={4} variant="h4">
            {translate('template-not-found')}
          </Typography>
          <Button component={NavLink} size="large" to={PageUrls.Templates} variant="contained">
            {translate('go-to-home')}
          </Button>
        </div>
      </Box>
    </Container>
  )
}
