import { CircularProgress, Fade } from '@mui/material'
import React, { FC } from 'react'

import styles from './page-spinner.module.scss'

interface PageSpinnerProps {
  show: boolean
  position?: 'fixed' | 'absolute'
}

export const PageSpinner: FC<PageSpinnerProps> = ({ show, position = 'fixed' }) => (
  <div className={`${styles.pageSpinnerBackdrop} ${show ? styles.show : ''}`} style={{ position }}>
    <Fade
      className={styles.pageSpinner}
      in={show}
      style={{
        transitionDelay: show ? '100ms' : '0ms',
      }}
      unmountOnExit
    >
      <CircularProgress color="primary" size={32} />
    </Fade>
  </div>
)
