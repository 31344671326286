import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavigateOptions } from 'react-router/lib/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { ActionCreators } from 'redux-undo'

import { TemplateType } from '~/enums/template-type.enum'
import { useMount } from '~/hooks/use-mount'
import { CloseModal } from '~components/Modal/components/close-modal'
import { NotificationPopup } from '~components/Modal/NotificationPopup/notification-popup'
import { getErrorsValues } from '~helpers/get-errors-values'
import { getTemplateUrl } from '~helpers/get-template-url'
import { getNotificationMessage } from '~helpers/notification-message'
import { IErrorRequest } from '~models/error-request.model'
import { IActionItem } from '~models/menu-actions.model'
import { IPublishDetailsRequest } from '~models/template.model'
import { useAppDispatch } from '~stores/hooks'
import {
  useDeleteEmergencyTemplateMutation,
  useDeleteTransitTemplateMutation,
  usePostEmergencyTemplatePublishMutation,
  usePostTransitTemplatePublishMutation,
  usePostTransitTemplateSetStatusDraftMutation,
} from '~stores/services/template.api'
import { useAgencyId } from '~stores/slices/auth.slice'
import {
  hideConfirmationPopup,
  showConfirmationPopup,
  useIsOpenConfirmationPopup,
} from '~stores/slices/confirmation.slice'
import { hidePreviewPopup } from '~stores/slices/preview.slice'
import {
  saveAndPostTemplate,
  saveAndPreviewTemplate,
  setConfirmNavigation,
  setTemplateIsFetching,
} from '~stores/slices/template.slice'

const saveAndPublishStyles = {
  '& .MuiDialogActions-root': {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
    gap: '0.375rem',
  },
}

interface ConfirmationPopupProps {
  selectedItem: IActionItem
  publishDetails?: IPublishDetailsRequest
  redirectUrl?: string
  navigateOptions?: NavigateOptions
}

export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  selectedItem,
  publishDetails,
  redirectUrl,
  navigateOptions,
}) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const agencyId = useAgencyId()

  const isOpen = useIsOpenConfirmationPopup()
  const location = useLocation()
  const navigate = useNavigate()
  const [previousLocation, setPreviousLocation] = useState(location.pathname)
  const { action, templateId, hasChanges, templateType } = selectedItem
  const [isLoading, setIsLoading] = useState(false)

  const { mounted } = useMount({ opened: isOpen, confirmationPopup: true })

  const notificationMessage = useMemo(() => getNotificationMessage(selectedItem), [selectedItem])

  const handleClose = useCallback(() => {
    dispatch(hideConfirmationPopup())
  }, [dispatch])

  const [deleteTransitTemplate] = useDeleteTransitTemplateMutation()
  const [deleteEmergencyTemplate] = useDeleteEmergencyTemplateMutation()
  const [publishTemplate] = usePostTransitTemplatePublishMutation()
  const [publishEmergencyTemplate] = usePostEmergencyTemplatePublishMutation()
  const [setStatusDraft] = usePostTransitTemplateSetStatusDraftMutation()

  const handleAgree = useCallback(async () => {
    dispatch(setTemplateIsFetching(true))
    setIsLoading(true)

    if (action === 'delete' && templateId) {
      try {
        if (templateType === TemplateType.Emergency) {
          await deleteEmergencyTemplate({ agencyId, templateId }).unwrap()
        } else {
          await deleteTransitTemplate({ agencyId, templateId }).unwrap()
        }

        enqueueSnackbar(translate('template-deleted'))
        handleClose()
        dispatch(setConfirmNavigation(true))
        if (location.pathname.includes(getTemplateUrl(templateType))) {
          navigate(getTemplateUrl(templateType, 'listUrl'), { ...navigateOptions, replace: true })
        }
      } catch (err) {
        const error = err as IErrorRequest

        dispatch(
          showConfirmationPopup(
            <NotificationPopup
              message={getErrorsValues(error.data).join(' ')}
              title={translate('template-not-deleted')}
            />,
          ),
        )
      }
    }

    if (action === 'publish' && templateId) {
      try {
        if (templateType === TemplateType.Emergency && publishDetails) {
          await publishEmergencyTemplate({
            templateId,
            publishDetails,
          }).unwrap()
        } else {
          await publishTemplate({ templateId }).unwrap()
        }

        enqueueSnackbar(translate('template-published'))
        handleClose()
      } catch (err) {
        const error = err as IErrorRequest

        console.error(error)

        dispatch(
          showConfirmationPopup(
            <NotificationPopup
              message={getErrorsValues(error.data).join(' ')}
              title={translate('failed-to-publish')}
            />,
          ),
        )
      }
    }

    if (action === 'preview') {
      dispatch(setConfirmNavigation(true))

      if (redirectUrl) {
        navigate(redirectUrl, navigateOptions)
      }

      handleClose()
      dispatch(hidePreviewPopup())
    }

    if (action === 'setDraft' && templateId) {
      try {
        await setStatusDraft({ templateId }).unwrap()
        enqueueSnackbar(translate('template-moved-to-draft'))

        handleClose()
      } catch (err) {
        const error = err as IErrorRequest

        console.error(error)

        dispatch(
          showConfirmationPopup(
            <NotificationPopup
              message={getErrorsValues(error.data).join(' ')}
              title={translate('template-was-not-moved-to-draft')}
            />,
          ),
        )
      }
    }

    setIsLoading(false)
    dispatch(setTemplateIsFetching(false))
    dispatch(ActionCreators.clearHistory())
  }, [
    action,
    templateId,
    templateType,
    translate,
    handleClose,
    location.pathname,
    deleteEmergencyTemplate,
    agencyId,
    deleteTransitTemplate,
    navigate,
    publishDetails,
    publishEmergencyTemplate,
    publishTemplate,
    redirectUrl,
    setStatusDraft,
  ])

  const handleAdditionalAction = useCallback(() => {
    handleClose()

    if (templateType === TemplateType.Emergency && hasChanges) {
      dispatch(saveAndPostTemplate(true))

      return
    }

    if (action === 'publish' && hasChanges) {
      dispatch(saveAndPostTemplate(true))

      return
    }

    if (action === 'preview' && redirectUrl) {
      dispatch(saveAndPreviewTemplate(redirectUrl))
      dispatch(hidePreviewPopup())
    }
  }, [templateType, handleClose, action, hasChanges, redirectUrl, dispatch])

  useEffect(() => {
    if (previousLocation !== location.pathname) {
      handleClose()
      setPreviousLocation(location.pathname)
    }
  }, [location])

  if (!notificationMessage || !mounted) {
    return null
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={isOpen}
      sx={hasChanges && (action === 'publish' || action === 'preview') ? saveAndPublishStyles : null}
    >
      <DialogTitle id="alert-dialog-title">
        {translate(notificationMessage.title)}
        <CloseModal handleClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Trans>{notificationMessage.message}</Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        {notificationMessage.agreeButtonText && (
          <LoadingButton autoFocus loading={isLoading} onClick={handleAgree}>
            {translate(notificationMessage.agreeButtonText)}
          </LoadingButton>
        )}
        {notificationMessage.additionalButtonText && (
          <Button onClick={handleAdditionalAction}>{translate(notificationMessage.additionalButtonText)}</Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
