const BaseUrls = {
  Create: 'create',
  Preview: 'preview',
}

export const PageUrls = {
  Templates: '/',
  Template: '/template',
  get TemplateCreate() {
    return `${this.Template}/${BaseUrls.Create}`
  },
  DefaultTemplate: '/default-template',
  get PreviewDefaultTemplate() {
    return `${this.DefaultTemplate}/${BaseUrls.Preview}`
  },
  BannerConfigurator: '/banner-configurator',
  PreviewPage: '/preview-page',
  get PreviewTemplate() {
    return `${this.PreviewPage}/${BaseUrls.Preview}`
  },
  CarouselTemplate: '/carousel-template',
  EmergencyTemplates: '/emergency-templates',
  EmergencyTemplate: '/emergency-template',
  get EmergencyTemplateCreate() {
    return `${this.EmergencyTemplate}/${BaseUrls.Create}`
  },
  get PreviewEmergencyTemplate() {
    return `${this.EmergencyTemplate}/${BaseUrls.Preview}`
  },
  Settings: '/settings',
  BusStops: '/bus-stops',
  BusStop: '/bus-stop',
} as const
