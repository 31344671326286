import { Button, Container, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DisplayConfig, DisplaySize, DisplaySizeKeys } from '~/enums/display-size'
import { TemplateType } from '~/enums/template-type.enum'
import { TemplateTableCells } from '~/enums/templates.enum'
import { DisplaySizePopup } from '~components/Modal/DisplaySizePopup/display-size-popup'
import { Spinner } from '~components/Spinner/spinner'
import { TablePagination } from '~components/TablePagination/table-pagination'
import { TableSearch } from '~components/TableSearch/table-search'
import { TemplatesTable } from '~components/TemplatesTable/templates-table'
import { getObjectKeys } from '~helpers/get-object-keys'
import { getLocalStoragePageSize, setLocalStoragePageSize } from '~helpers/local-storage-page-size'
import { IPagination } from '~models/pagination.model'
import { ITemplateListModel, ITemplateTableCells } from '~models/template.model'
import { useAppDispatch } from '~stores/hooks'
import { useGetTransitTemplateListQuery } from '~stores/services/template.api'
import { showConfirmationPopup } from '~stores/slices/confirmation.slice'

const headCells: ITemplateTableCells[] = [
  {
    id: TemplateTableCells.name,
    label: 'name',
    sortable: true,
  },
  {
    id: TemplateTableCells.status,
    width: 130,
    label: 'status',
    sortable: true,
  },
  {
    id: TemplateTableCells.pages,
    width: 1,
    label: 'pages',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: TemplateTableCells.createdAt,
    label: 'date-created',
    width: 1,
    sortable: true,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: TemplateTableCells.createdByUsername,
    label: 'created-by',
    width: 150,
    sortable: false,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: TemplateTableCells.lastModifiedAt,
    label: 'last-updated',
    width: 1,
    sortable: true,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: TemplateTableCells.lastModifiedByUsername,
    label: 'updated-by',
    width: 150,
    sortable: false,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: TemplateTableCells.actions,
    label: '',
    width: 1,
  },
]

export const TransitTemplateList = () => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()

  const [pageNumber, setPageNumber] = useState(1)
  const [searchRequest, setSearchRequest] = useState<string>('')
  const [sortBy, setSortBy] = useState<string>(TemplateTableCells.createdAt)
  const [isSortAscending, setIsSortAscending] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(() => getLocalStoragePageSize())
  const [display, setDisplay] = useState<DisplaySizeKeys>('ThirteenInchesEInk')

  const [templates, setTemplates] = useState<ITemplateListModel[]>()
  const [pagination, setPagination] = useState<IPagination>()

  const {
    data: templatesData,
    isLoading,
    isFetching,
  } = useGetTransitTemplateListQuery({
    pageNumber,
    pageSize,
    ...(searchRequest && { name: searchRequest }),
    sortBy,
    isSortAscending,
    display,
  })

  useEffect(() => {
    if (!isLoading && templatesData) {
      setTemplates([...templatesData.result])
      setPagination({ ...templatesData.pagination })
    }
  }, [isLoading, templatesData])

  useEffect(() => {
    setLocalStoragePageSize(pageSize)
  }, [pageSize])

  const handleSearch = useCallback((value: string) => {
    setSearchRequest(value)
    setPageNumber(1)
  }, [])

  const handleChangeTab = (event: SyntheticEvent, value: DisplaySizeKeys) => {
    setPageNumber(1)
    setDisplay(value)
  }

  const handleNewTemplate = () => {
    dispatch(showConfirmationPopup(<DisplaySizePopup type={TemplateType.Transit} />))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Container>
      <Grid2 alignItems="center" container spacing={2} sx={{ mb: 2 }}>
        <Grid2 xs>
          <Typography variant="h5">
            {translate('templates')} {pagination?.TotalCount && `(${pagination.TotalCount})`}
          </Typography>
        </Grid2>
        <Grid2 style={{ marginRight: '1.5rem' }}>
          <ToggleButtonGroup
            className="tabs"
            color="primary"
            exclusive
            onChange={handleChangeTab}
            size="small"
            value={display}
          >
            {getObjectKeys(DisplaySize).map((key) => (
              <ToggleButton key={key} value={key}>
                {translate(DisplayConfig[key].label)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid2>
        <Grid2 style={{ marginRight: '1.5rem' }}>
          <TableSearch handleSearch={handleSearch} />
        </Grid2>
        <Grid2>
          <Button onClick={() => handleNewTemplate()} size="medium" variant="contained">
            {translate('add-new')}
          </Button>
        </Grid2>
      </Grid2>
      <TemplatesTable
        handleIsSortAscending={setIsSortAscending}
        handleSortBy={setSortBy}
        headCells={headCells}
        isFetching={isFetching}
        isLoading={isLoading}
        searchRequest={searchRequest}
        templates={templates}
      />
      {pagination && (
        <TablePagination
          handlePageIndex={setPageNumber}
          handlePageSize={setPageSize}
          isFetching={isFetching}
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalCount={pagination.TotalCount}
          totalPages={pagination.TotalPages}
        />
      )}
    </Container>
  )
}
