import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IConfirmPopupText } from '~models/template.model'

const cancelButtonStyles = {
  '& .MuiDialogActions-root': {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
    gap: '0.375rem',
  },
}

interface ConfirmLeavingPopupProps {
  showDialog: boolean
  sx?: SxProps<Theme>
  confirmNavigationAndSave: () => void
  confirmNavigation: () => void
  cancelNavigation: () => void
  confirmPopupText: IConfirmPopupText
}

export const ConfirmLeavingPopup: FC<ConfirmLeavingPopupProps> = ({
  confirmNavigation,
  cancelNavigation,
  showDialog,
  sx,
  confirmNavigationAndSave,
  confirmPopupText,
}) => {
  const { t: translate } = useTranslation()
  const { title, message, saveText, notSaveText, cancelText } = confirmPopupText

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={cancelNavigation}
      open={showDialog}
      sx={{ ...sx, ...(cancelText && { ...cancelButtonStyles }) }}
    >
      <DialogTitle id="alert-dialog-title">
        {translate(title)}
        <IconButton
          aria-label="close"
          onClick={cancelNavigation}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{translate(message)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <Button color="inherit" onClick={cancelNavigation}>
            {translate(cancelText)}
          </Button>
        )}
        <Button color={cancelText ? 'primary' : 'inherit'} onClick={confirmNavigation}>
          {translate(notSaveText)}
        </Button>
        <Button autoFocus onClick={confirmNavigationAndSave}>
          {translate(saveText)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
