import React, { FC } from 'react'

import { ITemplateBlockModel } from '~models/template-block.model'
import { useAppDispatch } from '~stores/hooks'
import { setAddingBlock, setDragBlock, setIsDrag } from '~stores/slices/drag-block.slice'

import styles from './template-add-block.module.scss'

interface TemplateAddBlockProps {
  block: ITemplateBlockModel
  children: JSX.Element
}

export const TemplateAddBlock: FC<TemplateAddBlockProps> = ({ block, children }) => {
  const dispatch = useAppDispatch()

  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const scrollBar = e.currentTarget.closest('.blocks-scroll-bar')
    const scrollBarPosition = scrollBar?.scrollTop || 0

    // e.dataTransfer.setDragImage(e.currentTarget, 0, 0)

    dispatch(setIsDrag(true))

    dispatch(
      setDragBlock({
        block,
        cursorOffset: {
          x: e.pageX - e.currentTarget.offsetLeft,
          y: e.pageY + scrollBarPosition - e.currentTarget.offsetTop,
        },
      }),
    )
  }

  const addDragBlock = () => {
    dispatch(
      setDragBlock({
        block,
      }),
    )
    dispatch(setAddingBlock(true))
  }

  const onDragEnd = () => {
    dispatch(setIsDrag(false))
  }

  let dataHeight = 'md'

  if (block.properties.rows < 6) {
    dataHeight = 'xs'
  }

  if (block.properties.rows >= 6 && block.properties.rows < 12) {
    dataHeight = 'sm'
  }

  if (block.properties.rows > 18) {
    dataHeight = 'lg'
  }

  return (
    <div
      className={styles.dragBlock}
      data-height={dataHeight}
      draggable
      onClick={addDragBlock}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      {children}
    </div>
  )
}
