import { BaseQueryMeta } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { createApi } from '@reduxjs/toolkit/dist/query/react'

import { DisplaySizeKeys } from '~/enums/display-size'
import { IPaginationRequest } from '~models/pagination.model'
import {
  IEmergencyTemplatePublishRequest,
  ITemplate,
  ITemplateForSending,
  ITemplateGetResponse,
  ITemplateImageResponse,
  ITemplateListGetResponse,
  ITemplateListWithPagination,
  ITemplatePublishResponse,
  ITemplateResponse,
} from '~models/template.model'
import { ITemplateBlock } from '~models/template-block.model'
import { staggeredBaseQueryWithBailOut } from '~stores/helpers/staggered-base-query-with-bail-out'

export const templateApi = createApi({
  reducerPath: 'templateApi',
  baseQuery: staggeredBaseQueryWithBailOut('Template/'),
  tagTypes: [
    'TransitTemplate',
    'TransitTemplateDelete',
    'DefaultTemplate',
    'TemplateImage',
    'TemplateBlocks',
    'EmergencyTemplate',
    'EmergencyTemplateDelete',
  ],
  endpoints: (build) => ({
    // Template Blocks
    getTransitTemplateBlocks: build.query<ITemplateBlock, void>({
      query: () => ({ url: 'block/transit' }),
      providesTags: ['TemplateBlocks'],
    }),
    getDefaultTemplateBlocks: build.query<ITemplateBlock, void>({
      query: () => ({ url: 'block/default' }),
      providesTags: ['TemplateBlocks'],
    }),
    getEmergencyTemplateBlocks: build.query<ITemplateBlock, void>({
      query: () => ({ url: 'block/emergency' }),
      providesTags: ['TemplateBlocks'],
    }),

    // Transit Template
    getTransitTemplateList: build.query<ITemplateListWithPagination, IPaginationRequest>({
      query: ({ pageSize, pageNumber, name, isSortAscending, sortBy, display }) => ({
        url: '/transit',
        params: {
          agencyId: '',
          pageSize,
          pageNumber,
          name,
          sortBy,
          isSortAscending,
          display,
        },
      }),
      transformResponse: (response: ITemplateListGetResponse, meta: BaseQueryMeta<any>) => {
        const pagination = JSON.parse(meta.response.headers.get('x-pagination'))

        return { ...response, pagination }
      },
      providesTags: ['TransitTemplate', 'TransitTemplateDelete'],
    }),
    postTransitTemplate: build.mutation<ITemplateResponse, ITemplateForSending>({
      query: (queryArg) => ({
        url: '/transit',
        method: 'POST',
        body: { ...queryArg },
      }),
      invalidatesTags: ['TransitTemplate'],
    }),
    putTransitTemplate: build.mutation<ITemplateResponse, ITemplateForSending>({
      query: (queryArg) => ({
        url: 'transit',
        method: 'PUT',
        body: { ...queryArg },
      }),
      invalidatesTags: ['TransitTemplate'],
    }),
    getTransitTemplate: build.query<ITemplateGetResponse, { templateId: number }>({
      query: ({ templateId }) => ({
        url: `transit/${templateId}`,
      }),
      providesTags: ['TransitTemplate'],
    }),
    deleteTransitTemplate: build.mutation<ITemplateResponse, { agencyId: number; templateId: number }>({
      query: ({ agencyId, templateId }) => ({
        url: `transit/${agencyId}/${templateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TransitTemplateDelete'],
    }),
    postTransitTemplatePublish: build.mutation<ITemplatePublishResponse, { templateId: number }>({
      query: ({ templateId }) => ({
        url: `transit/${templateId}/publish`,
        method: 'POST',
      }),
      invalidatesTags: ['TransitTemplate'],
    }),
    postTransitTemplateSetStatusDraft: build.mutation<ITemplateResponse, { templateId: number }>({
      query: ({ templateId }) => ({
        url: `transit/${templateId}/set-status-draft`,
        method: 'POST',
      }),
      invalidatesTags: ['TransitTemplate'],
    }),

    // Emergency Template
    getEmergencyTemplateList: build.query<ITemplateListWithPagination, IPaginationRequest>({
      query: ({ pageSize, pageNumber, name, isSortAscending, sortBy, display }) => ({
        url: 'emergency',
        params: {
          agencyId: '',
          pageSize,
          pageNumber,
          name,
          sortBy,
          isSortAscending,
          display,
        },
      }),
      transformResponse: (response: ITemplateListGetResponse, meta: BaseQueryMeta<any>) => {
        const pagination = JSON.parse(meta.response.headers.get('x-pagination'))

        return { ...response, pagination }
      },
      providesTags: ['EmergencyTemplate', 'EmergencyTemplateDelete'],
    }),
    getEmergencyTemplate: build.query<ITemplateGetResponse, { templateId: number }>({
      query: ({ templateId }) => ({
        url: `emergency/${templateId}`,
      }),
      providesTags: ['EmergencyTemplate'],
    }),
    putEmergencyTemplate: build.mutation<ITemplateResponse, ITemplateForSending>({
      query: (queryArg) => ({
        url: 'emergency',
        method: 'PUT',
        body: { ...queryArg },
      }),
      invalidatesTags: ['EmergencyTemplate'],
    }),
    postEmergencyTemplate: build.mutation<ITemplateResponse, ITemplateForSending>({
      query: (queryArg) => ({
        url: 'emergency',
        method: 'POST',
        body: { ...queryArg },
      }),
      invalidatesTags: ['EmergencyTemplate'],
    }),
    deleteEmergencyTemplate: build.mutation<ITemplateResponse, { agencyId: number; templateId: number }>({
      query: ({ agencyId, templateId }) => ({
        url: `emergency/${agencyId}/${templateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmergencyTemplateDelete'],
    }),
    postEmergencyTemplatePublish: build.mutation<ITemplateResponse, IEmergencyTemplatePublishRequest>({
      query: ({ templateId, publishDetails }) => ({
        url: `emergency/${templateId}/publish`,
        method: 'POST',
        body: { ...publishDetails },
      }),
      invalidatesTags: ['EmergencyTemplate'],
    }),
    postEmergencyTemplateDeactivate: build.mutation<ITemplatePublishResponse, { templateId: number }>({
      query: ({ templateId }) => ({
        url: `emergency/${templateId}/deactivate`,
        method: 'POST',
      }),
      invalidatesTags: ['EmergencyTemplate'],
    }),

    // Default Template
    getDefaultTemplate: build.query<ITemplateGetResponse, { display: DisplaySizeKeys }>({
      query: ({ display }) => ({
        url: 'default',
        params: {
          agencyId: true,
          display,
        },
      }),
      providesTags: ['DefaultTemplate'],
    }),
    putDefaultTemplate: build.mutation<ITemplate, ITemplateForSending>({
      query: (queryArg) => ({
        url: 'default',
        method: 'PUT',
        body: { ...queryArg },
      }),
      invalidatesTags: ['DefaultTemplate'],
    }),

    // Default Image
    postTemplateImage: build.mutation<ITemplateImageResponse, { agencyId: number; image: FormData }>({
      query: ({ agencyId, image }) => ({ url: `${agencyId}/image`, method: 'POST', body: image }),
      invalidatesTags: ['TemplateImage'],
    }),
    deleteTemplateImage: build.mutation<null, { agencyId: number; fileName: string }>({
      query: ({ agencyId, fileName }) => ({ url: `${agencyId}/image/${fileName}`, method: 'DELETE' }),
      invalidatesTags: ['TemplateImage'],
    }),
  }),
})

export const {
  useGetTransitTemplateBlocksQuery,
  useGetDefaultTemplateBlocksQuery,
  useGetEmergencyTemplateBlocksQuery,

  useGetTransitTemplateListQuery,
  usePostTransitTemplateMutation,
  usePutTransitTemplateMutation,
  useGetTransitTemplateQuery,
  useLazyGetTransitTemplateQuery,
  useDeleteTransitTemplateMutation,
  usePostTransitTemplatePublishMutation,
  usePostTransitTemplateSetStatusDraftMutation,

  useGetEmergencyTemplateListQuery,
  useGetEmergencyTemplateQuery,
  usePutEmergencyTemplateMutation,
  usePostEmergencyTemplateMutation,
  useDeleteEmergencyTemplateMutation,
  usePostEmergencyTemplatePublishMutation,
  usePostEmergencyTemplateDeactivateMutation,

  useGetDefaultTemplateQuery,
  usePutDefaultTemplateMutation,

  usePostTemplateImageMutation,
  useDeleteTemplateImageMutation,
} = templateApi
