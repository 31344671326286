import { ArrowBackIosNew, ArrowForwardIos, Pause, PlayArrow, Stop } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

import { useCanvasProperties } from '~/hooks/use-canvas-properties'
import { useWindowSize } from '~/hooks/use-window-size'
import { CanvasFooter } from '~components/Canvas/components/canvas-footer'
import { CanvasHeader } from '~components/Canvas/components/canvas-header'
import { PreviewCanvasPage } from '~components/PreviewCanvas/preview-canvas-page'
import { Spinner } from '~components/Spinner/spinner'
import { ILayout } from '~models/layouts.model'
import { ITemplate } from '~models/template.model'
import { useGetLayoutsQuery } from '~stores/services/layouts.api'

import styles from './template-carousel.module.scss'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

interface TemplateCarouselProps {
  template: ITemplate
  handleOpen: (value: boolean) => void
}

export const TemplateCarousel: FC<TemplateCarouselProps> = ({ template, handleOpen }) => {
  const [windowWidth, windowHeight] = useWindowSize()

  const [activeStep, setActiveStep] = useState(0)
  const [autoPlay, setAutoPlay] = useState(true)
  const modalRef = useRef<HTMLDivElement | null>(null)

  const pages = useMemo(() => template.pages, [template.pages])
  const pagesTotal = useMemo(() => pages.length, [pages.length])
  const interval = useMemo(() => template.delay * 1000 || 1000, [template.delay])

  const { data: layoutData, isLoading: layoutsDataIsLoading } = useGetLayoutsQuery()
  const [layout, setLayout] = useState<ILayout>()

  useEffect(() => {
    if (layoutData?.result && !layoutsDataIsLoading) {
      setLayout({ ...layoutData.result })
    }
  }, [layoutData?.result, layoutsDataIsLoading])

  const { canvasRowHeight, canvasColWidth } = useCanvasProperties({
    windowWidth,
    windowHeight,
    headerPanelDisabled: !layout?.header.isActive,
    footerPanelDisabled: !layout?.footer.isActive,
    fullScreen: true,
    displaySize: template.display,
  })

  const handleNext = useCallback(() => {
    if (pagesTotal - 1 === activeStep) {
      setActiveStep(0)

      return
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [activeStep, pagesTotal])

  const handlePrev = useCallback(() => {
    if (activeStep === 0) {
      setActiveStep(pagesTotal - 1)

      return
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }, [activeStep, pagesTotal])

  const handleAutoPlay = () => {
    setAutoPlay((prev) => !prev)
  }

  const handleStepChange = (step: number) => {
    if (autoPlay) {
      setActiveStep(step)
    }
  }

  useEffect(() => {
    const refTimeout = setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.focus()
      }
    })

    return () => clearTimeout(refTimeout)
  }, [modalRef])

  const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'ArrowRight') {
      handleNext()
    }

    if (event.code === 'ArrowLeft') {
      handlePrev()
    }

    if (event.code === 'Space') {
      handleAutoPlay()
    }

    if (event.code === 'Escape') {
      handleOpen(false)
    }
  }

  if (layoutsDataIsLoading) {
    return <Spinner />
  }

  if (!layout) {
    return null
  }

  return (
    <div className={styles.carouselModal} onKeyDown={keyDownHandler} ref={modalRef} tabIndex={0}>
      <aside className={styles.carouselModalControls}>
        <IconButton onClick={() => handleOpen(false)}>
          <Stop />
        </IconButton>
        <IconButton onClick={handlePrev}>
          <ArrowBackIosNew />
        </IconButton>
        <IconButton onClick={handleAutoPlay}>{autoPlay ? <Pause /> : <PlayArrow />}</IconButton>
        <IconButton onClick={handleNext}>
          <ArrowForwardIos />
        </IconButton>
      </aside>
      <div className={styles.carouselModalContent}>
        {layout.header.isActive && (
          <CanvasHeader headerBlocks={layout.header.blocks} headerCssStyles={layout.header.cssStyles} />
        )}
        <AutoPlaySwipeableViews index={activeStep} interval={interval} onChangeIndex={handleStepChange}>
          {pages.map((page) => (
            <PreviewCanvasPage
              canvasColWidth={canvasColWidth}
              canvasRowHeight={canvasRowHeight}
              key={page.id}
              page={page}
            />
          ))}
        </AutoPlaySwipeableViews>
        {layout.footer.isActive ? (
          <CanvasFooter
            activePageIndex={activeStep}
            footerBlocks={layout.footer.blocks}
            footerCssStyles={layout.footer.cssStyles}
            template={template}
          />
        ) : (
          <div className="canvas_panelFooter" style={{ display: 'none' }} />
        )}
      </div>
    </div>
  )
}
