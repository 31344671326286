import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material'
import * as React from 'react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DropZone } from '~components/DropZone/drop-zone'
import { useAppDispatch } from '~stores/hooks'
import { setAgencyLogoBlob, setAgencyLogoTemp } from '~stores/slices/agency-logo.slice'

interface LogoPopupProps {
  isOpen: boolean
  handleClose: () => void
}

export const LogoPopup: FC<LogoPopupProps> = ({ isOpen, handleClose }) => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()

  const [logoBase64, setLogoBase64] = useState<string | null>(null)
  const [logoBlob, setLogoBlob] = useState<Blob>()
  const [clearImage, setClearImage] = useState(false)

  const saveLogoToLocalStorage = () => {
    if (logoBlob && logoBase64) {
      dispatch(setAgencyLogoBlob(logoBlob))
    }

    if (logoBase64) {
      dispatch(setAgencyLogoTemp(logoBase64))
    }

    handleClose()
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={isOpen}>
      <DialogTitle>
        {translate('choose-logo')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DropZone handleImageBase64={setLogoBase64} handleImageBlob={setLogoBlob} initClearImage={clearImage} />
      <DialogActions sx={{ p: 2 }}>
        {logoBase64 && (
          <Button color="error" onClick={() => setClearImage(!clearImage)} sx={{ mr: 'auto' }}>
            {translate('clear-logo')}
          </Button>
        )}
        <Button color="inherit" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        {logoBase64 && (
          <Button autoFocus onClick={saveLogoToLocalStorage} variant="outlined">
            {translate('upload')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
