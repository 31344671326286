import { ILayoutBlocksGroup } from '~models/layouts.model'

export const updateFooterBlocksOrder = (layoutBlocks: ILayoutBlocksGroup[], id: string, direction: string) =>
  layoutBlocks
    .map((block) => {
      let col = 1

      if (direction === 'right') {
        col = block.id === id ? 2 : 1
      } else {
        col = block.id === id ? 1 : 2
      }

      return {
        ...block,
        position: {
          col,
        },
      }
    })
    .sort((a, b) => a.position.col - b.position.col)
