import React, { FC, useMemo } from 'react'

import { ITemplatePage } from '~models/template.model'
import { useTemplateActivePage } from '~stores/slices/template.slice'

interface CanvasPaginationProps {
  pages?: ITemplatePage[]
  paginationHidden?: boolean
  activePageIndex?: number
}

export const CanvasPagination: FC<CanvasPaginationProps> = ({ pages, paginationHidden, activePageIndex }) => {
  const activePageId = useTemplateActivePage()
  const activePageNumber = useMemo(() => {
    if (!pages) return

    const activePage = pages.find((page) => page.id === activePageId)

    if (activePage) return activePage.orderNumber + 1
  }, [activePageId, pages])

  if (paginationHidden) {
    return <div className="pagination pagination-generate" />
  }

  return (
    <div className="pagination">
      {!pages ? (
        <ul className="pagination-list">
          <li />
          <li className="active" />
          <li />
        </ul>
      ) : pages.length < 6 ? (
        <ul className="pagination-list">
          {pages.map((page, index) =>
            activePageIndex !== undefined ? (
              <li className={activePageIndex === index ? 'active' : ''} key={page.id} />
            ) : (
              <li className={activePageId === page.id ? 'active' : ''} key={page.id} />
            ),
          )}
        </ul>
      ) : (
        <div className="pagination-text">
          {(activePageIndex && activePageIndex + 1) || activePageNumber} of {pages.length}
        </div>
      )}
    </div>
  )
}
