import { MenuItem } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useSnackbar } from 'notistack'
import React, { FC, MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PageUrls } from '~/enums/page-urls.enum'
import { TemplateType } from '~/enums/template-type.enum'
import { TemplateStatusesEnum } from '~/enums/templates.enum'
import { DropdownMenu } from '~components/DropdownMenu/dropdown-menu'
import { PreviewButton } from '~components/PreviewButton/preview-button'
import { useAppDispatch } from '~stores/hooks'
import { useDeleteEmergencyTemplateMutation } from '~stores/services/template.api'
import { useAgencyId } from '~stores/slices/auth.slice'
import { setConfirmNavigation, setTemplateIsFetching } from '~stores/slices/template.slice'

interface EmergencyTemplateActionsProps {
  templateId: number
  status: TemplateStatusesEnum
  builderView?: boolean
  hasChanges?: boolean
  handlePublishPopup?: (value: boolean) => void
}

export const EmergencyTemplateActions: FC<EmergencyTemplateActionsProps> = ({
  templateId,
  status,
  builderView,
  hasChanges,
  handlePublishPopup,
}) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()
  const navigate = useNavigate()
  const agencyId = useAgencyId()

  const [dropClose, setDropClose] = useState(false)

  const [deleteEmergencyTemplate] = useDeleteEmergencyTemplateMutation()

  const handleDeleteEmergencyTemplate = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      try {
        event.stopPropagation()
        setDropClose(true)

        await confirm({
          title: translate('delete-template'),
          description: translate('template-will-be-permanently-deleted'),
          confirmationText: translate('delete'),
        })

        dispatch(setTemplateIsFetching(true))

        await deleteEmergencyTemplate({ agencyId, templateId }).unwrap()

        dispatch(setConfirmNavigation(true))

        enqueueSnackbar(translate('template-deleted'))

        if (location.pathname.includes(PageUrls.EmergencyTemplate)) {
          navigate(PageUrls.EmergencyTemplates, { replace: true })
        }
      } catch (err) {
        console.error(err)
        enqueueSnackbar(translate('template-not-deleted'), { variant: 'warning' })
      } finally {
        dispatch(setTemplateIsFetching(false))
      }
    },
    [confirm, deleteEmergencyTemplate, enqueueSnackbar, templateId, navigate, translate],
  )

  const handlePublishEmergencyTemplate = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      if (handlePublishPopup) {
        handlePublishPopup(true)
      }

      setDropClose(true)
    },
    [handlePublishPopup],
  )

  return (
    <DropdownMenu dropClose={dropClose} handleDrop={setDropClose}>
      <PreviewButton
        handleClick={() => setDropClose(true)}
        selectedItem={{
          hasChanges,
          status,
          templateId,
          templateType: TemplateType.Emergency,
          action: 'preview',
        }}
        tag={MenuItem}
      />
      {builderView && <MenuItem onClick={handlePublishEmergencyTemplate}>{translate('publish')}</MenuItem>}
      {status === TemplateStatusesEnum.Draft && (
        <MenuItem onClick={(event) => handleDeleteEmergencyTemplate(event)}>{translate('delete')}</MenuItem>
      )}
    </DropdownMenu>
  )
}
