import { TextField } from '@mui/material'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MIN_DELAY as minDelay } from '~constants/constants'
import { IErrorRequestData } from '~models/error-request.model'

interface TemplateDelayInputProps {
  templateDelay: number
  errorsData: IErrorRequestData | null
  handleDelay: (value: number) => void
  hasErrorCallBack: (value: boolean) => void
  name?: string
}

export const TemplateDelayInput: FC<TemplateDelayInputProps> = ({
  templateDelay,
  errorsData,
  handleDelay,
  hasErrorCallBack,
  name = 'Delay',
}) => {
  const { t: translate } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [hasError, setHasError] = useState(false)

  const onChangeDelay = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const valueNumber = Number(event.currentTarget.value)

      if (event.currentTarget.value.length < 4) {
        handleDelay(valueNumber)
      }
    },
    [handleDelay],
  )

  useEffect(() => {
    if (templateDelay < minDelay) {
      setErrorMessage(`${translate('min-delay', { delay: minDelay })}`)
    } else {
      setErrorMessage(null)
    }
  }, [templateDelay])

  useEffect(() => {
    if (errorsData?.errors?.hasOwnProperty(name)) {
      setErrorMessage(translate('min-delay', { delay: minDelay }))
    }
  }, [errorsData, name])

  useEffect(() => setHasError(Boolean(errorMessage)), [errorMessage])

  useEffect(() => {
    if (hasErrorCallBack) {
      hasErrorCallBack(hasError)
    }
  }, [hasError, hasErrorCallBack])

  return (
    <TextField
      InputProps={{
        endAdornment: 'sec',
        inputProps: { min: 2 },
      }}
      error={hasError}
      helperText={errorMessage}
      label={translate('delay')}
      name={name}
      onChange={onChangeDelay}
      placeholder="Delay"
      size="small"
      sx={{ width: 60, ml: 2 }}
      type="number"
      value={String(templateDelay)}
      variant="standard"
    />
  )
}
