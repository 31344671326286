import { TableCell, TableRow } from '@mui/material'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { TemplateType } from '~/enums/template-type.enum'
import { Status } from '~components/Status/status'
import { TemplateActions } from '~components/TemplateActions/template-actions'
import { TemplateStatuses } from '~constants/constants'
import { showByFormat } from '~helpers/date-helper'
import { getTemplateUrl } from '~helpers/get-template-url'
import { ITemplateListModel } from '~models/template.model'
import { EmergencyTemplateActions } from '~pages/EmergencyTemplate/components/emergency-template-actions'

interface TemplateItemProps {
  templateItem: ITemplateListModel
  templateType?: TemplateType
}

export const TemplatesTableRow: FC<TemplateItemProps> = ({ templateItem, templateType = TemplateType.Transit }) => {
  const { id, status, name, templatePagesCount, createdAt, lastModifiedAt, createdByUsername, lastModifiedByUsername } =
    templateItem
  const getStatus = TemplateStatuses[status]
  const navigate = useNavigate()

  return (
    <TableRow hover key={id} onClick={() => navigate(`${getTemplateUrl(templateType)}/${id}`)}>
      <TableCell>{name}</TableCell>
      <TableCell>
        <Status status={getStatus} />
      </TableCell>
      <TableCell>
        <span className="pages-holder">{templatePagesCount}</span>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {showByFormat(createdAt, 'MMM D, YYYY')} <br />
        {showByFormat(createdAt, 'h:mm A')}
      </TableCell>
      <TableCell>{createdByUsername}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {lastModifiedAt ? (
          <>
            {showByFormat(lastModifiedAt || createdAt, 'MMM D, YYYY')} <br />
            {showByFormat(lastModifiedAt || createdAt, 'h:mm A')}
          </>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>{lastModifiedAt ? lastModifiedByUsername : '-'}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {templateType === TemplateType.Transit ? (
          <TemplateActions name={name} status={status} templateId={id} templateType={templateType} />
        ) : (
          <EmergencyTemplateActions status={status} templateId={id} />
        )}
      </TableCell>
    </TableRow>
  )
}
