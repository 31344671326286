import { Container, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BusStopTemplateTableCells } from '~/enums/bus-stop-templates.enum'
import { Spinner } from '~components/Spinner/spinner'
import { TablePagination } from '~components/TablePagination/table-pagination'
import { TableSearch } from '~components/TableSearch/table-search'
import { ROWS_PER_PAGE as rowsPerPage } from '~constants/constants'
import { getLocalStoragePageSize, setLocalStoragePageSize } from '~helpers/local-storage-page-size'
import { IBusStopTemplatesModel, IBusStopTemplatesTableCells } from '~models/bus-stop-templates.model'
import { IPagination } from '~models/pagination.model'
import { BusStopTemplatesTable } from '~pages/BusStop/components/bus-stop-templates-table'
import { useGetBusStopTemplatesListQuery } from '~stores/services/bus-stops.api'

const headCells: IBusStopTemplatesTableCells[] = [
  {
    id: BusStopTemplateTableCells.code,
    label: 'Stop code',
    sortable: true,
  },
  {
    id: BusStopTemplateTableCells.name,
    label: 'Stop name',
    sortable: true,
  },
  {
    id: BusStopTemplateTableCells.templates,
    label: 'Templates',
    sortable: false,
  },
]

export const BusStopList = () => {
  const { t: translate } = useTranslation()

  const [pageNumber, setPageNumber] = useState(1)
  const [searchRequest, setSearchRequest] = useState<string>('')
  const [sortBy, setSortBy] = useState<string>(BusStopTemplateTableCells.id)
  const [isSortAscending, setIsSortAscending] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(() => getLocalStoragePageSize() || rowsPerPage[0])

  const [busStops, setBusStops] = useState<IBusStopTemplatesModel[]>()
  const [pagination, setPagination] = useState<IPagination>()

  const {
    data: busStopsData,
    isLoading,
    isFetching,
  } = useGetBusStopTemplatesListQuery({
    pageNumber,
    pageSize,
    ...(searchRequest && { search: searchRequest }),
    sortBy,
    isSortAscending,
  })

  useEffect(() => {
    setLocalStoragePageSize(pageSize)
    if (!isLoading && busStopsData) {
      setBusStops([...busStopsData.result])
      setPagination({ ...busStopsData.pagination })
    }
  }, [pageSize, isLoading, busStopsData])

  const handleSearch = useCallback((value: string) => {
    setSearchRequest(value)
    setPageNumber(1)
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Container>
      <Grid2 alignItems="center" container spacing={2} sx={{ mb: 2 }}>
        <Grid2 xs>
          <Typography variant="h5">
            {translate('bus-stops')} {pagination?.TotalCount && `(${pagination.TotalCount})`}
          </Typography>
        </Grid2>
        <Grid2 style={{ marginRight: '1.5rem' }}>
          <TableSearch handleSearch={handleSearch} placeholder={translate('stop-code-or-name')} />
        </Grid2>
      </Grid2>
      <BusStopTemplatesTable
        busStops={busStops}
        handleIsSortAscending={setIsSortAscending}
        handleSortBy={setSortBy}
        headCells={headCells}
        isFetching={isFetching}
        isLoading={isLoading}
        searchRequest={searchRequest}
      />
      {pagination && (
        <TablePagination
          handlePageIndex={setPageNumber}
          handlePageSize={setPageSize}
          isFetching={isFetching}
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalCount={pagination.TotalCount}
          totalPages={pagination.TotalPages}
        />
      )}
    </Container>
  )
}
