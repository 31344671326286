import { Typography } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DisplayConfig, DisplaySizeKeys } from '~/enums/display-size'

interface DisplayTypeProps {
  display: DisplaySizeKeys
}

export const DisplayType: FC<DisplayTypeProps> = ({ display }) => {
  const { t: translate } = useTranslation()

  return (
    <Typography sx={{ fontSize: '0.875rem', fontWeight: 500, textTransform: 'uppercase' }}>
      {translate(DisplayConfig[display].label)}
    </Typography>
  )
}
