export enum TemplateBlocksView {
  pure = 'pure',
  fakeData = 'fake-data',
  realData = 'real-data',
}

export enum BlocksFakeData {
  pure = 'pure',
  fakeData = 'fake-data',
  realData = 'real-data',
}

export enum TemplateComponentName {
  route = 'TemplateRouteBlock',
  eta = 'TemplateEtaBlock',
  connection = 'TemplateConnectionBlock',
  text = 'TemplateStaticTextBlock',
  image = 'TemplateStaticImageBlock',
  message = 'TemplateMessageBlock',
}
