import axios from 'axios'
import React, { FC, useEffect, useState } from 'react'

import { LogoPopup } from '~components/Modal/LogoPopup/logo-popup'
import { BASE_API } from '~constants/constants'
import { getBase64 } from '~helpers/get-base64'
import { useAppDispatch } from '~stores/hooks'
import { clearAgencyLogoTemp, setAgencyLogo, useAgencyLogo, useAgencyLogoTemp } from '~stores/slices/agency-logo.slice'
import { useAccessToken, useAgencyId } from '~stores/slices/auth.slice'

import styles from './template-logo.module.scss'

interface TemplateLogoProps {
  readOnly?: boolean
  modalOpen?: boolean
  handleModalOpen?: (val: boolean) => void
}

export const TemplateLogo: FC<TemplateLogoProps> = ({ modalOpen = false, handleModalOpen, readOnly }) => {
  const dispatch = useAppDispatch()
  const agencyId = useAgencyId()
  const agencyLogo = useAgencyLogo()
  const agencyLogoTemp = useAgencyLogoTemp()
  const accessToken = useAccessToken()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!agencyLogo) {
      axios
        .get(`${BASE_API}/Agency/${agencyId}/logo`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: 'blob',
        })
        .then((response) => {
          getBase64(response.data).then((base64) => {
            if (typeof base64 === 'string') {
              dispatch(setAgencyLogo(base64))
            }
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [agencyId, agencyLogo, dispatch])

  useEffect(
    () => () => {
      if (agencyLogoTemp) {
        dispatch(clearAgencyLogoTemp())
      }
    },
    [agencyLogoTemp, dispatch],
  )

  useEffect(() => {
    setOpen(modalOpen)
  }, [modalOpen])

  const handleClose = () => {
    if (handleModalOpen) {
      handleModalOpen(false)
    }

    setOpen(false)
  }

  return (
    <>
      <div className={styles.logoBlock}>
        {agencyLogoTemp ? (
          <img alt="Logo" src={agencyLogoTemp} />
        ) : agencyLogo ? (
          <img alt="Logo" src={agencyLogo} />
        ) : !readOnly ? (
          <span className={styles.logoBlockText}>Add logo</span>
        ) : (
          <span className={styles.logoBlockText}>No logo</span>
        )}
      </div>
      <LogoPopup handleClose={handleClose} isOpen={open} />
    </>
  )
}
