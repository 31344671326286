import { ArrowBack, InfoOutlined } from '@mui/icons-material'
import { Button, Divider, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'

import { DisplayConfig, DisplaySize, DisplaySizeKeys } from '~/enums/display-size'
import { PageUrls } from '~/enums/page-urls.enum'
import { TemplateType } from '~/enums/template-type.enum'
import { useNavigateBlocker } from '~/hooks/use-navigate-blocker'
import { ConfirmLeavingPopup } from '~components/Modal/ConfirmLeavingPopup/confirm-leaving-popup'
import { DeploymentDetailsPopup } from '~components/Modal/DeploymentDetaisPopup/deployment-details-popup'
import { PreviewButton } from '~components/PreviewButton/preview-button'
import { Status } from '~components/Status/status'
import { TemplateDelayInput } from '~components/TemplateDelayInput/template-delay-input'
import { TemplateUndo } from '~components/TemplateUndo/template-undo'
import { TemplateStatuses } from '~constants/constants'
import { isPublishingStatus } from '~helpers/check-template-status'
import { getErrorsValues } from '~helpers/get-errors-values'
import { getObjectKeys } from '~helpers/get-object-keys'
import { templatesNotEqual } from '~helpers/templates-not-equal'
import { IErrorRequest, IErrorRequestData } from '~models/error-request.model'
import { IConfirmPopupText, ITemplate } from '~models/template.model'
import styles from '~pages/TransitTemplate/components/TemplateTopPanel/template-top-panel.module.scss'
import { useAppDispatch } from '~stores/hooks'
import { usePutDefaultTemplateMutation } from '~stores/services/template.api'
import { useAgencyId } from '~stores/slices/auth.slice'
import {
  saveAndPreviewTemplate,
  setConfirmNavigation,
  setTemplateErrors,
  useConfirmNavigationTrigger,
  useSaveAndRedirectToPreview,
} from '~stores/slices/template.slice'

interface TemplateTopPanelProps {
  template: ITemplate
  readOnly?: boolean
  onChangeDisplaySize: (val: DisplaySizeKeys) => void
  handleLoading: (val: boolean) => void
}

export const DefaultTemplateTopPanel: FC<TemplateTopPanelProps> = ({
  template,
  readOnly,
  onChangeDisplaySize,
  handleLoading,
}) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const agencyId = useAgencyId()

  const [deploymentDetailsPopupOpen, setDeploymentDetailsPopupOpen] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [errorsData, setErrorsData] = useState<IErrorRequestData | null>(null)
  const [hasDelayError, setHasDelayError] = useState(false)
  const [hasEmptyPage, setHasEmptyPage] = useState(false)
  const [activeDisplay, setActiveDisplay] = useState<DisplaySizeKeys>('ThirteenInchesEInk')
  const [activeDisplayTemp, setActiveDisplayTemp] = useState<DisplaySizeKeys>(activeDisplay)

  const saveAndRedirectToPreview = useSaveAndRedirectToPreview()
  const confirmNavigationTrigger = useConfirmNavigationTrigger()

  const [isNavigateBlocked, setIsNavigateBlocked] = useState(false)
  const [showConfirmPopup, confirmNavigation, cancelNavigation] = useNavigateBlocker(isNavigateBlocked)

  const [initialTemplate, setInitialTemplate] = useState<ITemplate | null>(null)
  const [templateDelay, setTemplateDelay] = useState(0)

  const [confirmPopupText, setConfirmPopupText] = useState<IConfirmPopupText>({
    title: 'changes-not-saved',
    message: 'do-you-want-to-save-changes-before-leaving',
    notSaveText: 'dont-save',
    saveText: 'save-and-publish',
  })

  const [showConfirmChangeDisplayPopup, setShowConfirmChangeDisplayPopup] = useState(false)

  const [updateDefaultTemplate, { isLoading: updateDefaultTemplateIsLoading }] = usePutDefaultTemplateMutation()

  useEffect(() => {
    setTemplateDelay(template.delay)
  }, [template.delay])

  const getEnqueueSnackbarErrors = useCallback(
    (errorData: IErrorRequestData) => {
      getErrorsValues(errorData).forEach((error) => {
        enqueueSnackbar(<div style={{ maxWidth: 300 }}>{error}</div>, {
          variant: 'error',
        })
      })
    },
    [enqueueSnackbar],
  )

  const handleTemplate = useCallback(async () => {
    if (hasEmptyPage) {
      enqueueSnackbar(translate('each-template-page-must-contain-at-least-one-content-block'), { variant: 'error' })

      return
    }

    try {
      await updateDefaultTemplate({
        ...template,
        agencyId,
        delay: templateDelay,
      }).unwrap()

      enqueueSnackbar(translate('changes-saved'))

      if (saveAndRedirectToPreview) {
        navigate(saveAndRedirectToPreview)
        confirmNavigation()
        dispatch(saveAndPreviewTemplate(null))
      }

      setErrorsData(null)
      dispatch(setTemplateErrors(null))

      setInitialTemplate({ ...template, delay: templateDelay })

      if (showConfirmPopup) {
        confirmNavigation()
      }
    } catch (err) {
      const error = err as IErrorRequest

      console.error(error)
      setErrorsData(error.data)
      dispatch(setTemplateErrors(error.data))

      getEnqueueSnackbarErrors(error.data)
      cancelNavigation()
    }
  }, [
    cancelNavigation,
    confirmNavigation,
    dispatch,
    enqueueSnackbar,
    getEnqueueSnackbarErrors,
    showConfirmPopup,
    template,
    templateDelay,
    translate,
    updateDefaultTemplate,
    saveAndRedirectToPreview,
    hasEmptyPage,
  ])

  useEffect(
    () => () => {
      dispatch(saveAndPreviewTemplate(null))
    },
    [dispatch],
  )

  const confirmNavigationAndSave = useCallback(() => {
    if (hasDelayError) {
      cancelNavigation()
      enqueueSnackbar(translate('default-template-was-not-saved'), { variant: 'error' })

      return
    }

    handleTemplate()
  }, [cancelNavigation, handleTemplate, hasDelayError])

  useEffect(() => {
    if (saveAndRedirectToPreview) {
      handleTemplate()
    }
  }, [saveAndRedirectToPreview])

  useEffect(() => {
    handleLoading(updateDefaultTemplateIsLoading)
  }, [handleLoading, updateDefaultTemplateIsLoading])

  useEffect(() => {
    if (template.pages[0].id && !initialTemplate) {
      setInitialTemplate(template)
    }

    if (templatesNotEqual({ initialTemplate, template, templateDelay })) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
  }, [template, initialTemplate, templateDelay])

  useEffect(() => {
    if (template.pages.some((page) => !page.blocks.length)) {
      setHasEmptyPage(true)

      return
    }

    setHasEmptyPage(false)
  }, [template])

  useEffect(() => {
    if (hasChanges && !readOnly) {
      setIsNavigateBlocked(true)

      return
    }

    setIsNavigateBlocked(false)
  }, [hasChanges, readOnly])

  useEffect(() => {
    if (confirmNavigationTrigger) {
      confirmNavigation()
      dispatch(setConfirmNavigation(false))
    }
  }, [confirmNavigation, confirmNavigationTrigger, dispatch])

  const handleActiveDisplay = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as DisplaySizeKeys

    if (isNavigateBlocked) {
      setShowConfirmChangeDisplayPopup(true)
      setActiveDisplayTemp(value)

      return
    }

    setActiveDisplay(value)
  }

  const closeConfirmChangeDisplayPopup = () => {
    setShowConfirmChangeDisplayPopup(false)
  }

  const cancelChangeDisplay = () => {
    closeConfirmChangeDisplayPopup()
  }

  const confirmChangeDisplayAndSave = useCallback(() => {
    closeConfirmChangeDisplayPopup()
    if (hasDelayError) {
      enqueueSnackbar(translate('default-template-was-not-saved'), { variant: 'error' })

      return
    }

    handleTemplate()
    setActiveDisplay(activeDisplayTemp)
  }, [enqueueSnackbar, handleTemplate, hasDelayError, translate, activeDisplayTemp])

  const confirmChangeDisplay = useCallback(() => {
    closeConfirmChangeDisplayPopup()
    setActiveDisplay(activeDisplayTemp)
  }, [activeDisplayTemp])

  useEffect(() => {
    onChangeDisplaySize(activeDisplay)
  }, [activeDisplay, onChangeDisplaySize])

  return (
    <>
      <div className={`${styles.templateTopPanel} templateTopPanel-root`}>
        <IconButton className={styles.backButton} component={NavLink} to={PageUrls.Templates}>
          <ArrowBack />
        </IconButton>
        <Typography className={styles.templateName}>{translate('default-template')}</Typography>
        {!readOnly && (
          <>
            <Divider flexItem orientation="vertical" />
            <TemplateUndo />
          </>
        )}
        <Divider flexItem orientation="vertical" />
        <RadioGroup name="rd-display-size" onChange={handleActiveDisplay} row value={activeDisplay}>
          {getObjectKeys(DisplaySize).map((key) => (
            <FormControlLabel
              control={<Radio color="primary" value={key} />}
              key={key}
              label={translate(DisplayConfig[key].label)}
            />
          ))}
        </RadioGroup>
        <div style={{ marginLeft: 'auto' }} />
        <Status
          button={
            isPublishingStatus(template.status) && (
              <IconButton onClick={() => setDeploymentDetailsPopupOpen(true)}>
                <InfoOutlined />
              </IconButton>
            )
          }
          secondary={
            isPublishingStatus(template.status) &&
            template.publishDetails && (
              <Typography variant="body2">
                {translate('x-y-units', {
                  x: template.publishDetails.updatedBusStopsCount,
                  y: template.publishDetails.totalBusStopsCount,
                })}
              </Typography>
            )
          }
          status={TemplateStatuses[template.status]}
        />
        {readOnly ? (
          <div className={styles.fakeTextField}>
            <label>{translate('delay')}:</label>
            <strong>{templateDelay}</strong> sec
          </div>
        ) : (
          <TemplateDelayInput
            errorsData={errorsData}
            handleDelay={setTemplateDelay}
            hasErrorCallBack={setHasDelayError}
            templateDelay={templateDelay}
          />
        )}
        <PreviewButton
          navigateOptions={{ state: { displaySize: activeDisplay } }}
          selectedItem={{
            status: template.status,
            hasChanges,
            templateType: TemplateType.Default,
            action: 'preview',
          }}
          sx={{ ml: 2 }}
          tag={Button}
          variant="outlined"
        />
        {!readOnly && (
          <Button disabled={hasDelayError || !hasChanges} onClick={handleTemplate} size="medium" variant="contained">
            {translate('save-and-publish')}
          </Button>
        )}
      </div>
      <ConfirmLeavingPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        confirmNavigationAndSave={confirmNavigationAndSave}
        confirmPopupText={confirmPopupText}
        showDialog={showConfirmPopup}
      />
      <ConfirmLeavingPopup
        cancelNavigation={cancelChangeDisplay}
        confirmNavigation={confirmChangeDisplay}
        confirmNavigationAndSave={confirmChangeDisplayAndSave}
        confirmPopupText={confirmPopupText}
        showDialog={showConfirmChangeDisplayPopup}
      />
      {template.publishDetails && (
        <DeploymentDetailsPopup
          handleClose={() => setDeploymentDetailsPopupOpen(false)}
          isOpen={deploymentDetailsPopupOpen}
          publishDetails={template.publishDetails}
        />
      )}
    </>
  )
}
