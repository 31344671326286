import React, { FC } from 'react'

import styles from './input-group.module.scss'

interface FormGroupProps {
  label: string
  labelWidth?: number
  groupMargin?: number | string
  children: React.ReactNode
}

export const InputGroup: FC<FormGroupProps> = ({ label, labelWidth, groupMargin, children }) => (
  <div className={styles.formGroup} style={{ margin: groupMargin }}>
    <strong className={styles.controlLabel} style={{ width: labelWidth }}>
      {label}
    </strong>
    <div className={styles.controlHolder}>{children}</div>
  </div>
)
