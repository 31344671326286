import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BusStopTemplateTableCells } from '~/enums/bus-stop-templates.enum'
import { EmptyTableRow } from '~components/EmptyTableRow/empty-table-row'
import { Spinner } from '~components/Spinner/spinner'
import { TableSpinner } from '~components/TableSpinner/table-spinner'
import { IBusStopTemplatesModel, IBusStopTemplatesTableCells } from '~models/bus-stop-templates.model'
import { BusStopTemplatesTableRow } from '~pages/BusStop/components/bus-stop-templates-table-row'
import { useTemplateIsFetching } from '~stores/slices/template.slice'

interface BusStopTemplatesTableProps {
  busStops?: IBusStopTemplatesModel[]
  isFetching: boolean
  isLoading: boolean
  searchRequest?: string
  handleSortBy: (sortBy: string) => void
  handleIsSortAscending: (isSortAscending: boolean) => void
  headCells: IBusStopTemplatesTableCells[]
}

export const BusStopTemplatesTable: FC<BusStopTemplatesTableProps> = ({
  searchRequest,
  busStops,
  isFetching,
  isLoading,
  handleSortBy,
  handleIsSortAscending,
  headCells,
}) => {
  const { t: translate } = useTranslation()
  const busStopIsFetching = useTemplateIsFetching()

  const [sortBy, setSortBy] = useState<string>(BusStopTemplateTableCells.id)
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>()
  const [isSortAscending, setIsSortAscending] = useState<boolean>(false)

  const handleSort = useCallback(
    (id: string) => {
      if (id === sortBy) {
        setIsSortAscending(!isSortAscending)
      } else {
        setIsSortAscending(true)
      }

      setSortBy(id)
    },
    [isSortAscending, sortBy],
  )

  useEffect(() => (isSortAscending ? setOrder('asc') : setOrder('desc')), [isSortAscending])

  useEffect(() => {
    handleSortBy(sortBy)
  }, [handleSortBy, sortBy])

  useEffect(() => {
    handleIsSortAscending(isSortAscending)
  }, [handleIsSortAscending, isSortAscending])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <TableContainer
        className={isFetching || busStopIsFetching ? 'loading has-loader' : 'has-loader'}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(({ id, align, width, label, sortable, style }) => (
                <TableCell align={align} key={id} sx={style} width={width}>
                  <TableSortLabel
                    active={Boolean(busStops?.length) && sortable && sortBy === id}
                    direction={sortBy === id ? order : 'asc'}
                    disabled={!busStops?.length || !sortable}
                    hideSortIcon={!sortable}
                    onClick={() => handleSort(id)}
                  >
                    {id === BusStopTemplateTableCells.pages ? (
                      <span className="pages-holder">{translate(label)}</span>
                    ) : (
                      <>{translate(label)}</>
                    )}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {busStops?.length ? (
            <TableBody>
              {busStops.map((item) => (
                <BusStopTemplatesTableRow busStopItem={item} key={item.id} />
              ))}
            </TableBody>
          ) : null}
        </Table>
        {!busStops?.length && (
          <EmptyTableRow>
            {searchRequest ? translate('no-templates-found') : translate('you-dont-have-any-bus-stops-yet')}
          </EmptyTableRow>
        )}
        <TableSpinner show={isFetching || busStopIsFetching} />
      </TableContainer>
    </>
  )
}
