import { Close, ControlPoint, DragIndicator, Settings } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSortable } from 'react-sortablejs'

import { LayoutBlockNames } from '~/enums/layout-block-names.enum'
import { LayoutPanels } from '~/enums/layout-panels.enum'
import { TemplateLogo } from '~components/TemplateLogo/template-logo'
import { ILayoutBlocksGroup, ILayoutCssStyles } from '~models/layouts.model'
import styles from '~pages/Configurator/configurator.module.scss'
import { useAppDispatch } from '~stores/hooks'
import {
  removeLayoutFieldErrors,
  setLayoutActiveBlockSettings,
  setLayoutActiveBlocksPanel,
  setLayoutActiveToolsPanel,
  useLayoutActiveBlockListPanel,
  useLayoutActiveToolsPanel,
  useLayoutFieldErrors,
  useLayoutHeaderCssStyles,
} from '~stores/slices/layout.slice'

interface ConfiguratorHeaderProps {
  headerBlocks: ILayoutBlocksGroup[]
  handleBlock: (id: number, panel: LayoutPanels | null) => void
  onSortBlocks: (newState: ILayoutBlocksGroup[], panel: LayoutPanels) => void
  temporaryCssStyles: ILayoutCssStyles
}

export const ConfiguratorHeader: FC<ConfiguratorHeaderProps> = ({
  headerBlocks,
  handleBlock,
  onSortBlocks,
  temporaryCssStyles,
}) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const [onChoose, setOnChoose] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const layoutFieldErrors = useLayoutFieldErrors()
  const headerCssStyles = useLayoutHeaderCssStyles()
  const layoutActiveLayoutToolsPanel = useLayoutActiveToolsPanel()
  const layoutActiveBlockListPanel = useLayoutActiveBlockListPanel()

  const handleConfigToolsPopup = useCallback((panel: LayoutPanels) => {
    dispatch(setLayoutActiveToolsPanel(panel))
  }, [])

  const handleConfigBlockListPopup = useCallback((panel: LayoutPanels) => {
    dispatch(setLayoutActiveBlocksPanel(panel))
  }, [])

  const handleBlockSettings = useCallback((blockGroup: ILayoutBlocksGroup) => {
    dispatch(setLayoutActiveBlockSettings(blockGroup))
  }, [])

  const getBlockError = useCallback(
    (componentName: LayoutBlockNames) => {
      if (layoutFieldErrors) {
        return layoutFieldErrors.some((error) => componentName.includes(error))
      }
    },
    [layoutFieldErrors],
  )

  const handleRemoveBlock = (id: number, panel: LayoutPanels | null, componentName: LayoutBlockNames) => {
    dispatch(removeLayoutFieldErrors(componentName))

    handleBlock(id, panel)
  }

  return (
    <header
      className={styles.panelHeader}
      style={layoutActiveLayoutToolsPanel === LayoutPanels.header ? temporaryCssStyles : headerCssStyles}
    >
      <ReactSortable
        animation={300}
        className={`${styles.panelHeaderHolder} ${onChoose ? 'on-choose' : ''}`}
        delay={0}
        direction="horizontal"
        handle=".handle"
        list={headerBlocks.map((block) => ({ ...block, chosen: true }))}
        onChoose={() => setOnChoose(true)}
        onUnchoose={() => setOnChoose(false)}
        setList={(newState) => onSortBlocks(newState, LayoutPanels.header)}
      >
        {headerBlocks.map((blockGroup) => {
          const { id, layoutBlockId, block, timeFormat, showDayOfTheWeek, customText, serviceEmail, servicePhone } =
            blockGroup
          const hasCustomSettings = timeFormat ?? showDayOfTheWeek ?? customText ?? serviceEmail ?? servicePhone
          const isLogo = block.componentName === LayoutBlockNames.logo

          return (
            <div className={`${styles.panelBlock} ${isLogo ? styles.LayoutLogoBlock : ''}`} key={id}>
              <div className={styles.panelBlockActions}>
                <IconButton className="handle" color="inherit" size="small" title={translate('sort')}>
                  <DragIndicator />
                </IconButton>
                {hasCustomSettings !== undefined && (
                  <IconButton
                    color="inherit"
                    onClick={() => handleBlockSettings(blockGroup)}
                    size="small"
                    title={translate('settings')}
                  >
                    <Settings />
                  </IconButton>
                )}
                {isLogo && (
                  <IconButton
                    color="inherit"
                    onClick={() => setModalOpen(true)}
                    size="small"
                    title={translate('settings')}
                  >
                    <Settings />
                  </IconButton>
                )}
                <IconButton
                  color="inherit"
                  onClick={() => handleRemoveBlock(layoutBlockId, LayoutPanels.header, block.componentName)}
                  size="small"
                  title={translate('remove')}
                >
                  <Close />
                </IconButton>
              </div>
              {getBlockError(block.componentName) && <span className={styles.errorMark} />}
              {isLogo ? <TemplateLogo handleModalOpen={setModalOpen} modalOpen={modalOpen} /> : <>{block.name}</>}
            </div>
          )
        })}
      </ReactSortable>
      <div
        className={`${styles.panelActions} ${
          layoutActiveLayoutToolsPanel || layoutActiveBlockListPanel ? styles.hide : ''
        }`}
      >
        <IconButton color="inherit" onClick={() => handleConfigBlockListPopup(LayoutPanels.header)}>
          <ControlPoint />
        </IconButton>
        <IconButton color="inherit" onClick={() => handleConfigToolsPopup(LayoutPanels.header)}>
          <Settings />
        </IconButton>
      </div>
    </header>
  )
}
