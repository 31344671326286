import dayjs from 'dayjs'

import { IBusServiceModel } from '~models/bus-service.model'

function waitForElement(selector: string): Promise<Element | null> {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      // eslint-disable-next-line no-promise-executor-return
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  })
}

export const renderTemplate = async (busServices: IBusServiceModel[], busNumbers: string[]) => {
  await waitForElement("[class*='canvas_panelFooter']")

  const canadaDate = new Date()

  const nowHours = dayjs(canadaDate).format('HH')
  const nowMinutes = dayjs(canadaDate).format('mm')
  const nowTimeInMinutes = Number(nowHours) * 60 + Number(nowMinutes)

  const renderData = (parentClass: string) => {
    const routeBoxes = document.querySelectorAll(`[class*=${parentClass}] [data-name='route']`)

    if (routeBoxes.length) {
      const etaBoxes = document.querySelectorAll(`[class*=${parentClass}] [data-name='eta']`)

      const routeIndexes: { [key: string]: string } = {}

      routeBoxes.forEach((routElement, index) => {
        const routeNumber = routElement.getAttribute('data-route')
        const filteredBuses = busServices.filter((bus) => bus.routeShortName === busNumbers[index])

        if (routeNumber) {
          routeIndexes[routeNumber] = busNumbers[index]
        }

        if (!filteredBuses.length) {
          routElement.textContent = '-'

          return
        }

        const [firstBus] = filteredBuses

        const { routeShortName, routeLongName, hasMetroTransfer, hasTrainTransfer } = firstBus

        const container = routElement.closest('[class*="templateBlock"]')
        const destination = container?.querySelector("[data-title='destination']")
        const metro = container?.querySelector("[data-icon='metro']")
        const train = container?.querySelector("[data-icon='train']")
        const iconsHolder = container?.querySelector("[class*='iconsHolder']")

        if (destination) {
          destination.textContent = routeLongName
        }

        if (metro && !hasMetroTransfer) {
          metro.remove()
        }

        if (train && !hasTrainTransfer) {
          train.remove()
        }

        if (iconsHolder && !iconsHolder.childNodes.length) {
          iconsHolder.remove()
          container?.classList.add('without-icons')
        }

        routElement.textContent = routeShortName
      })

      etaBoxes.forEach((etaElement) => {
        const dataRoute = etaElement.getAttribute('data-route')
        const dataEta = etaElement.getAttribute('data-eta')

        if (dataRoute) {
          const routeIndex = Number(dataRoute)
          const etaIndex = dataEta ? Number(dataEta) - 1 : 0

          const filteredRoutesByName = busServices.filter((bus) => bus.routeShortName === routeIndexes[routeIndex])

          const routeByEtaIndex = filteredRoutesByName[etaIndex]

          const container = etaElement.closest('[class*="iconsBox"]')
          const wheelchair = container?.querySelector("[data-icon='wheelchair']")
          const passengerLoad = container?.querySelector("[data-icon='passenger-load']")
          const iconsHolder = container?.querySelector("[class*='iconsHolder']")

          let time = '<span class="time-text__unit empty">-</span>'

          if (routeByEtaIndex) {
            const [hours, minutes] = routeByEtaIndex.stopArrivalTime.split(':')

            const routeTimeInMinutes = Number(hours) * 60 + Number(minutes)
            const timeDiff = routeTimeInMinutes - nowTimeInMinutes

            if (timeDiff <= 360 && timeDiff >= 60) {
              time = `<span class="time-text__number">${hours}</span>
                <span class="time-text__unit">h</span>
                <span class="time-text__number">${minutes}</span>`
            }

            if (timeDiff < 60) {
              time = `<span class="time-text__number">${timeDiff}</span>
                <span class="time-text__unit">mins</span>`
            }

            if (wheelchair && !routeByEtaIndex.wheelchair) {
              wheelchair.remove()
            }

            if (passengerLoad && !routeByEtaIndex.passengerLoad) {
              passengerLoad.remove()
            }

            if (iconsHolder && !iconsHolder.childNodes.length) {
              iconsHolder.remove()
            }
          } else if (iconsHolder) {
            iconsHolder.remove()
          }

          etaElement.innerHTML = time
        }
      })
    }
  }

  renderData('preview-canvas')

  setTimeout(() => {
    renderData('template-aside-pages')
  }, 100)

  if (busNumbers.length === 0) {
    const iconsHolders = document.querySelectorAll('[class*="iconsHolder"]')

    iconsHolders.forEach((iconsBox) => {
      iconsBox.remove()
    })
  }
}
