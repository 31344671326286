export const isDarkColor = (color?: string) => {
  if (!color) return false

  const hexColor = color.slice(1, -1)

  const r = parseInt(hexColor.slice(0, 2), 16)
  const g = parseInt(hexColor.slice(2, 4), 16)
  const b = parseInt(hexColor.slice(4, 6), 16)

  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  return !(yiq >= 128)
}
