import { ITemplate, ITemplatePageBlocks } from '~models/template.model'

type NewRouteIndexes = {
  [key: string | number]: number
}

const updateRouteIndexes = (blocks: ITemplatePageBlocks[], newRouteIndexes: NewRouteIndexes) =>
  blocks.map((block) => {
    if (!block.routeIndex) {
      return block
    }

    return {
      ...block,
      routeIndex: newRouteIndexes[block.routeIndex] || block.routeIndex,
    }
  })

export const orderRouteIndexes = (template: ITemplate) => {
  const [transitPage] = template.pages
  const transitBlocks = transitPage.blocks

  const routeIndexes: number[] = []

  transitBlocks.forEach(({ routeIndex }) => {
    if (routeIndex && !routeIndexes.includes(routeIndex)) {
      routeIndexes.push(routeIndex)
    }
  })

  routeIndexes.sort()

  const newRouteIndexes: NewRouteIndexes = {}

  for (let i = 1; i <= routeIndexes.length; i += 1) {
    if (routeIndexes[i - 1] !== i) {
      newRouteIndexes[routeIndexes[i - 1]] = i
    }
  }

  const orderedTransitBlocks = updateRouteIndexes(transitBlocks, newRouteIndexes)

  const newPages = [...template.pages]

  newPages[0] = { ...transitPage, blocks: orderedTransitBlocks }

  return newPages
}
