import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ILayoutDynamicBlocks } from '~models/layout-blocks.model'
import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

interface ILayoutDynamicBlocksSlice {
  data: ILayoutDynamicBlocks
  layoutDynamicBlocksTrigger: boolean
}

const initialState: ILayoutDynamicBlocksSlice = {
  data: {
    stopName: '',
    stopCode: '',
    website: '',
  },
  layoutDynamicBlocksTrigger: false,
}

const layoutDynamicBlocksSlice = createSlice({
  name: 'layoutBlocksSlice',
  initialState,
  reducers: {
    setLayoutDynamicBlocks: (state, { payload }: PayloadAction<ILayoutDynamicBlocks>) => {
      state.data = payload
    },
    setLayoutDynamicBlocksTrigger: (state, { payload }: PayloadAction<boolean>) => {
      state.layoutDynamicBlocksTrigger = payload
    },
  },
})

export const getLayoutDynamicBlocks = (state: RootState) => state.layoutDynamicBlocks.data
export const getLayoutDynamicBlocksTrigger = (state: RootState) => state.layoutDynamicBlocks.layoutDynamicBlocksTrigger
export const useLayoutDynamicBlocks = () => useAppSelector(getLayoutDynamicBlocks)
export const useLayoutDynamicBlocksTrigger = () => useAppSelector(getLayoutDynamicBlocksTrigger)

export const {
  reducer: layoutDynamicBlocksReducer,
  actions: { setLayoutDynamicBlocks, setLayoutDynamicBlocksTrigger },
} = layoutDynamicBlocksSlice
