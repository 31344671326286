import React, { FC } from 'react'

import { LayoutBlockNames } from '~/enums/layout-block-names.enum'
import { TemplateDate } from '~components/TemplateDate/template-date'
import { TemplateLogo } from '~components/TemplateLogo/template-logo'
import { TemplateTime } from '~components/TemplateTime/template-time'
import { ILayoutDynamicBlocks } from '~models/layout-blocks.model'
import { ILayoutBlocksGroup } from '~models/layouts.model'

interface CanvasLayoutBlocksProps {
  layoutBlock: ILayoutBlocksGroup
  layoutDynamicBlocks?: ILayoutDynamicBlocks
  preview?: boolean
}

export const CanvasLayoutBlocks: FC<CanvasLayoutBlocksProps> = ({ layoutBlock, layoutDynamicBlocks, preview }) => {
  const { block, timeFormat, showDayOfTheWeek, customText, dateFormat, serviceEmail, servicePhone } = layoutBlock

  return (
    <span data-block={block.properties.mode}>
      {block.componentName === LayoutBlockNames.time ? (
        <TemplateTime
          format={timeFormat}
          preview={preview}
          showDayOfTheWeek={Boolean(showDayOfTheWeek)}
          staticMode={Boolean(layoutDynamicBlocks)}
        />
      ) : block.componentName === LayoutBlockNames.date ? (
        <TemplateDate format={dateFormat} preview={preview} />
      ) : block.componentName === LayoutBlockNames.customText ? (
        <>{customText || block.name}</>
      ) : block.componentName === LayoutBlockNames.serviceEmail ? (
        <>{serviceEmail || block.name}</>
      ) : block.componentName === LayoutBlockNames.servicePhone ? (
        <>{servicePhone || block.name}</>
      ) : block.componentName === LayoutBlockNames.logo ? (
        <TemplateLogo readOnly />
      ) : block.componentName === LayoutBlockNames.stopName ? (
        <>{layoutDynamicBlocks?.stopName || block.name}</>
      ) : block.componentName === LayoutBlockNames.stopCode ? (
        <>{layoutDynamicBlocks?.stopCode || block.name}</>
      ) : block.componentName === LayoutBlockNames.website ? (
        <>{layoutDynamicBlocks?.website || block.name}</>
      ) : (
        <>{preview ? block.properties.mode : block.name}</>
      )}
    </span>
  )
}
