import { Button } from '@mui/material'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { LayoutPanels } from '~/enums/layout-panels.enum'
import { ILayoutBlockModel } from '~models/layout-blocks.model'
import { ConfiguratorPopup } from '~pages/Configurator/components/configurator-popup'
import styles from '~pages/Configurator/configurator.module.scss'
import { useAppDispatch } from '~stores/hooks'
import { setLayoutActiveBlocksPanel, useLayout, useLayoutActiveBlockListPanel } from '~stores/slices/layout.slice'

interface ConfiguratorBlocksProps {
  layoutBlocks: ILayoutBlockModel[]
  handleBlock: (id: number, panel: LayoutPanels | null) => void
}

export const ConfiguratorBlockList: FC<ConfiguratorBlocksProps> = ({ layoutBlocks, handleBlock }) => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()

  const layout = useLayout()
  const layoutActiveBlockListPanel = useLayoutActiveBlockListPanel()

  const getItemProps = (panel: LayoutPanels | null | undefined) => {
    let className = ''
    let isAvailable = true

    if (layoutActiveBlockListPanel) {
      const limit = layout[layoutActiveBlockListPanel].maxBlocks

      if (panel) {
        if (panel !== layoutActiveBlockListPanel) {
          className += ` ${styles.disabled}`
          isAvailable = false
        } else {
          className += ` ${styles.active}`
        }
      } else if (limit && layout[layoutActiveBlockListPanel].blocks.length >= limit) {
        className += ` ${styles.reachedLimit}`
        isAvailable = false
      }
    }

    return { className, isAvailable }
  }

  const handleOpen = useCallback((val: boolean) => {
    if (!val) {
      dispatch(setLayoutActiveBlocksPanel(null))
    }
  }, [])

  if (!layoutActiveBlockListPanel) {
    return null
  }

  return (
    <ConfiguratorPopup
      handleOpen={handleOpen}
      open={Boolean(layoutActiveBlockListPanel)}
      title={translate(`${layoutActiveBlockListPanel}-blocks`)}
    >
      <div className={styles.blocksDropLists}>
        {layoutBlocks.map(({ name, id, panel }) => (
          <div
            className={`${styles.blocksDropItem} ${getItemProps(panel).className}`}
            key={id}
            onClick={() => (getItemProps(panel).isAvailable ? handleBlock(id, layoutActiveBlockListPanel) : null)}
          >
            {name}
          </div>
        ))}
      </div>
      <div className={styles.configuratorPopupFooter}>
        <Button onClick={() => handleOpen(false)}>{translate('close')}</Button>
      </div>
    </ConfiguratorPopup>
  )
}
