import { MenuItem, Pagination, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ROWS_PER_PAGE as rowsPerPage } from '~constants/constants'

import styles from './table-pagination.module.scss'

interface TablePaginationProps {
  totalCount: number
  totalPages: number
  isFetching: boolean
  pageNumber: number
  pageSize: number
  searchRequest?: string
  handlePageSize: (pageSize: number) => void
  handlePageIndex: (pageIndex: number) => void
}

export const TablePagination: FC<TablePaginationProps> = ({
  totalCount,
  totalPages,
  pageNumber,
  pageSize,
  isFetching,
  searchRequest,
  handlePageSize,
  handlePageIndex,
}) => {
  const { t: translate } = useTranslation()

  const [previousSearchRequest, setPreviousSearchRequest] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!isFetching && previousSearchRequest !== searchRequest) {
      handlePageIndex(1)
      setPreviousSearchRequest(searchRequest)
    }
  }, [searchRequest, isFetching, previousSearchRequest])

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    handlePageIndex(value)
  }

  const handleRowsChange = (event: SelectChangeEvent<typeof pageSize>) => {
    handlePageSize(Number(event.target.value))
    handlePageIndex(1)
  }

  useEffect(() => {
    handlePageSize(pageSize)
  }, [handlePageSize, pageSize])

  useEffect(() => {
    handlePageIndex(pageNumber)
  }, [handlePageIndex, pageNumber])

  if (totalCount <= rowsPerPage[0]) {
    return null
  }

  return (
    <div className={styles.paginationBar}>
      <Typography>{translate('rows-per-page')}</Typography>
      <Select className={styles.rowsSelector} onChange={handleRowsChange} value={pageSize} variant="standard">
        {rowsPerPage.map((row) => (
          <MenuItem key={row} value={row}>
            {row}
          </MenuItem>
        ))}
      </Select>
      <Pagination color="primary" count={totalPages} onChange={handlePageChange} page={pageNumber} shape="rounded" />
    </div>
  )
}
