import { BaseQueryMeta } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { createApi } from '@reduxjs/toolkit/dist/query/react'

import {
  IBusStopGetResponse,
  IBusStopListGetResponse,
  IBusStopListWithPagination,
  IBusStopRequest,
} from '~models/bus-stop.model'
import { IBusStopTemplatesListGetResponse, IBusStopTemplatesListWithPagination } from '~models/bus-stop-templates.model'
import { IPaginationRequest } from '~models/pagination.model'
import { staggeredBaseQueryWithBailOut } from '~stores/helpers/staggered-base-query-with-bail-out'

export const busStopsApi = createApi({
  reducerPath: 'busStopsApi',
  baseQuery: staggeredBaseQueryWithBailOut('BusStop'),
  tagTypes: ['BusStop'],
  endpoints: (build) => ({
    getBusStopList: build.query<IBusStopListWithPagination, IBusStopRequest>({
      query: (queryArg) => ({
        url: '',
        params: {
          ...queryArg,
        },
      }),
      transformResponse: (response: IBusStopListGetResponse, meta: BaseQueryMeta<any>) => {
        const pagination = JSON.parse(meta.response.headers.get('x-pagination'))

        return { ...response, pagination }
      },
      providesTags: ['BusStop'],
    }),
    getBusStop: build.query<IBusStopGetResponse, { busStopId: number }>({
      query: ({ busStopId }) => ({
        url: `/${busStopId}`,
      }),
      providesTags: ['BusStop'],
    }),
    getBusStopTemplatesList: build.query<IBusStopTemplatesListWithPagination, IPaginationRequest>({
      query: ({ pageSize, pageNumber, search, isSortAscending, sortBy }) => ({
        url: '/templates',
        params: {
          pageSize,
          pageNumber,
          search,
          sortBy,
          isSortAscending,
        },
      }),
      transformResponse: (response: IBusStopTemplatesListGetResponse, meta: BaseQueryMeta<any>) => {
        const pagination = JSON.parse(meta.response.headers.get('x-pagination'))

        return { ...response, pagination }
      },
      providesTags: ['BusStop'],
    }),
  }),
})

export const { useGetBusStopListQuery, useGetBusStopQuery, useGetBusStopTemplatesListQuery } = busStopsApi
