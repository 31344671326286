import React, { FC } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import styles from '~components/TemplateBlockComponent/blocks/template-blocks.module.scss'
import { TemplateFrameImage } from '~components/TemplateBlockComponent/frames/template-frame-image'
import { ITemplateBlockProperties } from '~models/template-block.model'

export interface TemplateStaticImageBlockProps extends ITemplateBlockProperties {
  image?: string
  thumbnailMode?: boolean
  firstPage?: boolean
}

export const TemplateStaticImageBlock: FC<TemplateStaticImageBlockProps> = ({
  view,
  image,
  thumbnailMode,
  firstPage,
}) => {
  let blockClass = view === TemplateBlocksView.pure ? styles.templateBlockPure : styles.templateBlock

  blockClass += thumbnailMode ? ` ${styles.thumbnailImageBlock}` : ''

  return (
    <div className={blockClass} style={{ padding: 0 }}>
      <TemplateFrameImage firstPage={firstPage} image={image} thumbnailMode={thumbnailMode} view={view} />
    </div>
  )
}
