import { TemplateStatusesEnum } from '~/enums/templates.enum'

export const isDraftStatus = (status: TemplateStatusesEnum) => status === TemplateStatusesEnum.Draft

export const isArchivedStatus = (status: TemplateStatusesEnum) => status === TemplateStatusesEnum.Archived

export const isDeployedStatus = (status: TemplateStatusesEnum) => status === TemplateStatusesEnum.Deployed

export const isInDeploymentStatus = (status: TemplateStatusesEnum) => status === TemplateStatusesEnum.InDeployment

export const isPublishingStatus = (status: TemplateStatusesEnum) =>
  status === TemplateStatusesEnum.InDeployment || status === TemplateStatusesEnum.Deployed
