import { ArrowBack, PlayArrow, Refresh } from '@mui/icons-material'
import { Button, IconButton, Typography } from '@mui/material'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { PageUrls } from '~/enums/page-urls.enum'
import { TemplateTypeKeys } from '~/enums/template-type.enum'
import { getTemplateType } from '~helpers/get-template-type'
import { IBusStopModel } from '~models/bus-stop.model'
import { ITemplate } from '~models/template.model'
import styles from '~pages/TransitTemplate/components/TemplateTopPanel/template-top-panel.module.scss'

interface PreviewTemplateTopPanelProps {
  template: ITemplate
  templateType: TemplateTypeKeys
  busStop?: IBusStopModel
  handlePlay: (val: boolean) => void
  handleRefresh: () => void
}

export const PreviewTemplateTopPanel: FC<PreviewTemplateTopPanelProps> = ({
  template,
  templateType,
  busStop,
  handlePlay,
  handleRefresh,
}) => {
  const { t: translate } = useTranslation()

  const { isEmergencyTemplate, isDefaultTemplate, isTransitTemplate } = useMemo(
    () => getTemplateType(templateType),
    [templateType],
  )

  const backLink = useMemo(() => {
    if (isDefaultTemplate) {
      return PageUrls.DefaultTemplate
    }

    if (isEmergencyTemplate) {
      return `${PageUrls.EmergencyTemplate}/${template.templateId}`
    }

    return `${PageUrls.Template}/${template.templateId}`
  }, [isDefaultTemplate, isEmergencyTemplate, template.templateId])

  return (
    <div className={`${styles.templateTopPanel} templateTopPanel-root`}>
      <IconButton className={styles.backButton} component={NavLink} to={backLink}>
        <ArrowBack />
      </IconButton>
      <Typography className={styles.templateName}>
        {isDefaultTemplate ? translate('default-template') : template.name}
      </Typography>
      {busStop && (
        <div className={styles.fakeTextField} style={{ marginLeft: 'auto' }}>
          <label>{translate('Stop')}:</label>
          <strong>
            #{busStop.code}: {busStop.name}
          </strong>
        </div>
      )}
      <div className={styles.fakeTextField} style={{ margin: '0 1rem 0 auto' }}>
        <label>{translate('delay')}:</label>
        <strong>{template.delay}</strong>
        sec
      </div>
      {isTransitTemplate && (
        <Button onClick={handleRefresh} size="medium" startIcon={<Refresh />} variant="outlined">
          {translate('refresh')}
        </Button>
      )}
      <Button onClick={() => handlePlay(true)} size="medium" startIcon={<PlayArrow />} variant="contained">
        {translate('play')}
      </Button>
    </div>
  )
}
