import { WarningAmberRounded } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BusStopStatus } from '~/enums/bus-stop-templates.enum'
import { BusStopDeployed } from '~/enums/template-type.enum'
import { splitByCapitalLetter } from '~helpers/split-by-capital-letter'
import { IBusStopTemplateDeployed } from '~models/bus-stop-templates.model'

interface DeployedTagProps {
  template: IBusStopTemplateDeployed
}

export const DeployedTag: FC<DeployedTagProps> = ({ template }) => {
  const { t: translate } = useTranslation()

  const { label, name, color } = BusStopDeployed[template.type]

  return (
    <Tooltip title={translate(splitByCapitalLetter(name))}>
      <div className="deployed-tag" style={{ borderColor: color }}>
        {translate(label)}
        {template.status === BusStopStatus.Error && <WarningAmberRounded />}
      </div>
    </Tooltip>
  )
}
