import React, { FC, useMemo } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import styles from '~components/TemplateBlockComponent/blocks/template-blocks.module.scss'
import { TemplateFrameIcons } from '~components/TemplateBlockComponent/frames/template-frame-icons'
import { TemplateFrameSimple } from '~components/TemplateBlockComponent/frames/template-frame-simple'
import { ITemplateBlockProperties } from '~models/template-block.model'

export interface TemplateEtaBlockProps extends ITemplateBlockProperties {
  mode:
    | 'eta1'
    | 'eta1-icons'
    | 'eta1-eta2'
    | 'eta1-eta2-icons'
    | 'eta1-eta2-iconsBottom'
    | 'eta1-eta2-icons-vertical'
    | string
  routeIndex?: number
}

export const TemplateEtaBlock: FC<TemplateEtaBlockProps> = ({ mode, view, routeIndex }) => {
  const dataGrid = useMemo(() => {
    if (mode.includes('vertical')) return 'rows'

    if (mode === 'eta1-eta2' || mode === 'eta1-eta2-icons' || mode === 'eta1-eta2-iconsBottom') return 'cols'

    return null
  }, [mode])
  const blockClass = view === TemplateBlocksView.pure ? styles.templateBlockPure : styles.templateBlock

  return (
    <div className={`${blockClass} eta-block-root`} data-grid={dataGrid}>
      {mode === 'eta1' ? (
        <TemplateFrameSimple data-eta="1" data-route={routeIndex} dataType="eta1" text="ETA 1" view={view} />
      ) : mode === 'eta1-icons' ? (
        <TemplateFrameIcons
          data-eta="1"
          data-route={routeIndex}
          dataType="eta1"
          iconBottom="PL"
          iconTop="WS"
          text="ETA 1"
          view={view}
        />
      ) : mode === 'eta1-iconsBottom' ? (
        <TemplateFrameIcons
          data-eta="1"
          data-route={routeIndex}
          dataType="eta1"
          direction="column"
          iconBottom="PL"
          iconTop="WS"
          text="ETA 1"
          view={view}
        />
      ) : mode === 'eta1-eta2' ? (
        <>
          <TemplateFrameSimple data-eta="1" data-route={routeIndex} dataType="eta1" text="ETA 1" view={view} />
          <TemplateFrameSimple data-eta="2" data-route={routeIndex} dataType="eta2" text="ETA 2" view={view} />
        </>
      ) : mode === 'eta1-eta2-vertical' ? (
        <>
          <TemplateFrameSimple data-eta="1" data-route={routeIndex} dataType="eta1" text="ETA 1" view={view} />
          <TemplateFrameSimple data-eta="2" data-route={routeIndex} dataType="eta2" text="ETA 2" view={view} />
        </>
      ) : mode === 'eta1-eta2-icons' ? (
        <>
          <TemplateFrameIcons
            data-eta="1"
            data-route={routeIndex}
            dataType="eta1"
            iconBottom="PL"
            iconTop="WS"
            text="ETA 1"
            view={view}
          />
          <TemplateFrameIcons
            data-eta="2"
            data-route={routeIndex}
            dataType="eta2"
            iconBottom="PL"
            iconTop="WS"
            text="ETA 2"
            view={view}
          />
        </>
      ) : mode === 'eta1-eta2-icons-vertical' ? (
        <>
          <TemplateFrameIcons
            data-eta="1"
            data-route={routeIndex}
            dataType="eta1"
            iconBottom="PL"
            iconTop="WS"
            text="ETA 1"
            view={view}
          />
          <TemplateFrameIcons
            data-eta="2"
            data-route={routeIndex}
            dataType="eta2"
            iconBottom="PL"
            iconTop="WS"
            text="ETA 2"
            view={view}
          />
        </>
      ) : mode === 'eta1-eta2-iconsBottom' ? (
        <>
          <TemplateFrameIcons
            data-eta="1"
            data-route={routeIndex}
            dataType="eta1"
            direction="column"
            iconBottom="PL"
            iconTop="WS"
            text="ETA 1"
            view={view}
          />
          <TemplateFrameIcons
            data-eta="2"
            data-route={routeIndex}
            dataType="eta2"
            direction="column"
            iconBottom="PL"
            iconTop="WS"
            text="ETA 2"
            view={view}
          />
        </>
      ) : null}
    </div>
  )
}
