import React, { CSSProperties, FC } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import { FakeMetroIcon, FakeTrainIcon } from '~components/TemplateBlockComponent/FakeData/fake-data'
import { convertToModeFormat } from '~helpers/convert-to-mode-format'

import styles from './template-frame.module.scss'

export interface TemplateFrameSimpleProps {
  text: string
  align?: 'left' | 'right' | 'center' | 'unset'
  style?: CSSProperties
  view?: TemplateBlocksView
  dataType?: string
}

export const TemplateFrameConnection: FC<TemplateFrameSimpleProps> = ({
  text,
  align = 'center',
  style,
  view,
  dataType,
}) => {
  const frameStyles = {
    textAlign: align,
    justifyContent: align,
    ...style,
  }

  return (
    <>
      {view === TemplateBlocksView.pure ? (
        <div className={styles.simpleFramePure} style={frameStyles}>
          <strong className={styles.simpleFrameTitle}>Connections</strong>
          <div className={styles.simpleConnectContent}>
            <div className={styles.connectBlock}>
              <span>CT</span> Station
            </div>
            <div className={styles.connectBlock}>
              <span>CM</span> Station
            </div>
          </div>
        </div>
      ) : (
        <div className={`${styles.simpleFrame} ${styles.simpleFrameAlt}`}>
          <strong className={styles.simpleFrameTitle}>Connections</strong>
          {view === TemplateBlocksView.fakeData ? (
            <>
              <div className={styles.connectBlock}>
                <FakeTrainIcon />
                <strong className={styles.connectBlockText}>Saint-Rose</strong>
              </div>
              <div className={styles.connectBlock}>
                <FakeMetroIcon />
                <strong className={styles.connectBlockText}>Henri-Bourassa</strong>
              </div>
            </>
          ) : (
            <span className={styles.variableText}>
              {dataType === 'static-text' ? text : `%${convertToModeFormat(text)}%`}
            </span>
          )}
        </div>
      )}
    </>
  )
}
