import { Configuration, PopupRequest } from '@azure/msal-browser'

import { AUTH_CLIENT_ID, AUTH_CLIENT_NAME, AUTH_SCOPES } from '~constants/constants'

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: AUTH_CLIENT_ID,
    authority: `https://${AUTH_CLIENT_NAME}.b2clogin.com/${AUTH_CLIENT_NAME}.onmicrosoft.com/B2C_1_SignIn`,
    knownAuthorities: [`${AUTH_CLIENT_NAME}.b2clogin.com`],
    redirectUri: '/signin-oidc',
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: AUTH_SCOPES,
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v2.0/me',
}
