import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

interface IConfirmationSlice {
  isOpen: boolean
  component: JSX.Element | null
}

const initialState: IConfirmationSlice = {
  isOpen: false,
  component: null,
}

const confirmationSlice = createSlice({
  name: 'ConfirmationSlice',
  initialState,
  reducers: {
    showConfirmationPopup: (state, { payload }: PayloadAction<JSX.Element>) => {
      state.isOpen = true
      state.component = payload
    },
    hideConfirmationPopup: (state) => {
      state.isOpen = false
    },
    clearConfirmationPopupComponent: (state) => {
      state.component = null
    },
  },
})

export const selectIsOpenConfirmationPopup = (state: RootState) => state.confirmation.isOpen
export const selectConfirmationPopup = (state: RootState) => state.confirmation.component

export const useIsOpenConfirmationPopup = () => useAppSelector(selectIsOpenConfirmationPopup)
export const useConfirmationPopup = () => useAppSelector(selectConfirmationPopup)

export const {
  reducer: confirmationReducer,
  actions: { hideConfirmationPopup, showConfirmationPopup, clearConfirmationPopupComponent },
} = confirmationSlice
