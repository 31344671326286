import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateOptions } from 'react-router/lib/hooks'
import { useNavigate } from 'react-router-dom'

import { BusStopsTableCells } from '~/enums/bus-stops.enum'
import { useMount } from '~/hooks/use-mount'
import { BusStopsTable } from '~components/BusStopsTable/bus-stops-table'
import { CloseModal } from '~components/Modal/components/close-modal'
import { ConfirmationPopup } from '~components/Modal/ConfirmationPopup/confirmation-popup'
import { TablePagination } from '~components/TablePagination/table-pagination'
import { TableSearch } from '~components/TableSearch/table-search'
import { ROWS_PER_PAGE as rowsPerPage } from '~constants/constants'
import { IBusStopModel, IBusStopTableCells } from '~models/bus-stop.model'
import { IActionItem } from '~models/menu-actions.model'
import { IPagination } from '~models/pagination.model'
import { useAppDispatch } from '~stores/hooks'
import { useGetBusStopListQuery } from '~stores/services/bus-stops.api'
import { showConfirmationPopup } from '~stores/slices/confirmation.slice'
import { hidePreviewPopup, useIsOpenPreviewPopup } from '~stores/slices/preview.slice'

const headCells: IBusStopTableCells[] = [
  {
    id: BusStopsTableCells.stopCode,
    label: 'stop-code',
    sortable: true,
    width: 200,
  },
  {
    id: BusStopsTableCells.stopName,
    label: 'stop-name',
    sortable: true,
  },
]

interface EmergencyTemplatePublishPopupProps {
  selectedItem: IActionItem
  redirectUrl: string
  navigateOptions?: NavigateOptions
}

export const TemplatePreviewPopup: FC<EmergencyTemplatePublishPopupProps> = ({
  selectedItem,
  redirectUrl,
  navigateOptions,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { t: translate } = useTranslation()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState<number>(rowsPerPage[0])
  const [searchRequest, setSearchRequest] = useState<string>('')
  const [sortBy, setSortBy] = useState<BusStopsTableCells>(BusStopsTableCells.stopCode)
  const [isSortAscending, setIsSortAscending] = useState<boolean>(true)

  const [busStops, setBusStops] = useState<IBusStopModel[]>()
  const [pagination, setPagination] = useState<IPagination>()

  const [selectedBusStops, setSelectedBusStops] = useState<number[]>([])

  const isOpen = useIsOpenPreviewPopup()

  const { mounted } = useMount({ opened: isOpen, previewPopup: true })

  const {
    data: busStopsData,
    isLoading,
    isFetching,
  } = useGetBusStopListQuery({
    PageNumber: pageNumber,
    PageSize: pageSize,
    ...(searchRequest && { Name: searchRequest, NameFo: 'Contains' }),
    [sortBy]: isSortAscending ? 'asc' : 'desc',
  })

  useEffect(() => {
    if (!isLoading && busStopsData) {
      setBusStops([...busStopsData.result])
      setPagination({ ...busStopsData.pagination })
    }
  }, [isLoading, busStopsData])

  const handleSearch = useCallback((value: string) => {
    setSearchRequest(value)
    setPageNumber(1)
  }, [])

  const handleClose = useCallback(() => {
    dispatch(hidePreviewPopup())
  }, [dispatch])

  const handlePreview = useCallback(
    (busStopId: number) => {
      if (selectedItem.hasChanges) {
        dispatch(
          showConfirmationPopup(
            <ConfirmationPopup
              navigateOptions={navigateOptions}
              redirectUrl={`${redirectUrl}/${busStopId}`}
              selectedItem={selectedItem}
            />,
          ),
        )

        return
      }

      navigate(`${redirectUrl}/${busStopId}`, navigateOptions)
      handleClose()
    },
    [selectedItem],
  )

  if (!mounted) {
    return null
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} open={isOpen}>
      <DialogTitle align="center" variant="h5">
        {translate('select-bus-stops')}
        <CloseModal handleClose={handleClose} />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid2 alignItems="center" container spacing={2} sx={{ mb: 1 }}>
          <Grid2 xs>
            <Typography variant="subtitle1">
              {translate('stops')} {pagination?.TotalCount && `(${pagination.TotalCount})`}
            </Typography>
          </Grid2>
          <Grid2>
            <TableSearch handleSearch={handleSearch} placeholder={translate('stop-name')} />
          </Grid2>
        </Grid2>
        <BusStopsTable
          busStops={busStops}
          handleBusStops={setSelectedBusStops}
          handleIsSortAscending={setIsSortAscending}
          handleSortBy={setSortBy}
          headCells={headCells}
          isFetching={isFetching}
          isLoading={isLoading}
          searchRequest={searchRequest}
          singleMode
        />
        <Grid2 alignItems="center" container sx={{ my: 2 }}>
          <Grid2 />
          <Grid2 xs>
            {pagination && (
              <TablePagination
                handlePageIndex={setPageNumber}
                handlePageSize={setPageSize}
                isFetching={isFetching}
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalCount={pagination.TotalCount}
                totalPages={pagination.TotalPages}
              />
            )}
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions sx={{ px: 3, pt: 0, pb: 3 }}>
        <Button color="inherit" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button
          disabled={!selectedBusStops.length}
          onClick={() => handlePreview(selectedBusStops[0])}
          variant="contained"
        >
          {translate('preview')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
