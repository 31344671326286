import { skipToken } from '@reduxjs/toolkit/query'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Canvas } from '~components/Canvas/canvas'
import { PageSpinner } from '~components/PageSpinner/page-spinner'
import { Spinner } from '~components/Spinner/spinner'
import { TemplateAsidePages } from '~components/TemplateAsidePages/template-aside-pages'
import { isDraftStatus } from '~helpers/check-template-status'
import { TemplateNotFound } from '~pages/TemplateNotFound/template-not-found'
import { TemplateAsideBlocks } from '~pages/TransitTemplate/components/TemplateAsideBlocks/template-aside-blocks'
import { TemplateTopPanel } from '~pages/TransitTemplate/components/TemplateTopPanel/template-top-panel'
import { useAppDispatch } from '~stores/hooks'
import { useGetTransitTemplateQuery } from '~stores/services/template.api'
import {
  clearTemplate,
  newTemplate,
  setTemplateActivePage,
  setTemplateStore,
  useTemplate,
  useTemplateIsFetching,
} from '~stores/slices/template.slice'

import styles from './bus-stop.module.scss'

export const BusStop = () => {
  const dispatch = useAppDispatch()
  const { templateId } = useParams()
  const template = useTemplate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const templateIsFetching = useTemplateIsFetching()

  const readOnly = useMemo(() => !isDraftStatus(template.status), [template.status])

  const {
    data: templateData,
    isLoading: getTemplateIsLoading,
    isFetching: getTemplateIsFetching,
  } = useGetTransitTemplateQuery(templateId ? { templateId: Number(templateId) } : skipToken)

  useEffect(() => {
    dispatch(clearTemplate())
  }, [dispatch, location])

  useEffect(() => {
    if (!templateId) {
      dispatch(newTemplate())
    }
  }, [dispatch, templateId])

  useEffect(() => {
    dispatch(setTemplateActivePage(''))
  }, [dispatch])

  useEffect(() => {
    if (!getTemplateIsLoading && templateData?.result) {
      dispatch(setTemplateStore({ ...templateData.result }))
    }
  }, [dispatch, getTemplateIsLoading, templateData?.result])

  if (getTemplateIsLoading) {
    return <Spinner />
  }

  if (!template.templateId && templateId) {
    return <TemplateNotFound />
  }

  return (
    <div className={styles.busStopContainer}>
      <TemplateTopPanel handleLoading={setIsLoading} readOnly={readOnly} template={template} />
      <div className={styles.busStopContent}>
        {!readOnly && <TemplateAsideBlocks />}
        <div className={styles.busStopHolder}>
          <Canvas readOnly={readOnly} template={template} />
        </div>
        <TemplateAsidePages readOnly={readOnly} template={template} />
      </div>
      <PageSpinner show={getTemplateIsFetching || isLoading || templateIsFetching} />
    </div>
  )
}
