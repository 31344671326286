import { TextField } from '@mui/material'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InputGroup } from '~components/InputGroup/input-group'
import { ILayoutBlocksGroup, keysOfLayoutCustomTextBlockType } from '~models/layouts.model'

interface ConfigurationTimeBlockProps {
  blockGroup: ILayoutBlocksGroup
  label?: string
  customField?: keysOfLayoutCustomTextBlockType
  handleBlockGroup: (blockGroup: ILayoutBlocksGroup) => void
  handleFieldError: (value: boolean) => void
}

export const ConfigurationCustomTextBlock: FC<ConfigurationTimeBlockProps> = ({
  blockGroup,
  label = 'custom-text',
  customField = 'customText',
  handleBlockGroup,
  handleFieldError,
}) => {
  const { t: translate } = useTranslation()
  const [customValue, setCustomValue] = useState('')
  const [textError, setTextError] = useState<string | null>(null)

  useEffect(() => {
    setCustomValue(blockGroup[customField] || '')
  }, [blockGroup])

  useEffect(() => {
    handleBlockGroup({
      ...blockGroup,
      [customField]: customValue,
    })
  }, [blockGroup, customValue])

  const onChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    setCustomValue(value)
  }

  useEffect(() => {
    const regex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/

    if (customValue.trim().length < 1) {
      setTextError(translate('this-field-is-required'))
    } else if (customField === 'serviceEmail' && !regex.test(customValue)) {
      setTextError(translate('entered-value-does-not-match-email-format'))
    } else {
      setTextError(null)
    }
  }, [customField, customValue, translate])

  useEffect(() => {
    handleFieldError(Boolean(textError))
  }, [handleFieldError, textError])

  return (
    <InputGroup label={translate(label)}>
      <TextField
        error={Boolean(textError)}
        fullWidth
        helperText={textError}
        onChange={onChangeText}
        value={customValue}
        variant="standard"
      />
    </InputGroup>
  )
}
