import { ArrowBack, InfoOutlined } from '@mui/icons-material'
import { Button, Divider, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ActionCreators } from 'redux-undo'

import { PageUrls } from '~/enums/page-urls.enum'
import { TemplateType } from '~/enums/template-type.enum'
import { TemplateStatusesEnum as statuses } from '~/enums/templates.enum'
import { useNavigateBlocker } from '~/hooks/use-navigate-blocker'
import { DisplayType } from '~components/DisplayType/display-type'
import { ConfirmLeavingPopup } from '~components/Modal/ConfirmLeavingPopup/confirm-leaving-popup'
import { DeploymentDetailsPopup } from '~components/Modal/DeploymentDetaisPopup/deployment-details-popup'
import { NotificationPopup } from '~components/Modal/NotificationPopup/notification-popup'
import { Status } from '~components/Status/status'
import { TemplateActions } from '~components/TemplateActions/template-actions'
import { TemplateDelayInput } from '~components/TemplateDelayInput/template-delay-input'
import { TemplateNameInput } from '~components/TemplateNameInput/template-name-input'
import { TemplateUndo } from '~components/TemplateUndo/template-undo'
import { TemplateStatuses } from '~constants/constants'
import { isPublishingStatus } from '~helpers/check-template-status'
import { getErrorsValues } from '~helpers/get-errors-values'
import { templatesNotEqual } from '~helpers/templates-not-equal'
import { IErrorRequest, IErrorRequestData } from '~models/error-request.model'
import { IConfirmPopupText, ITemplate } from '~models/template.model'
import { useAppDispatch } from '~stores/hooks'
import {
  usePostTransitTemplateMutation,
  usePostTransitTemplatePublishMutation,
  usePutTransitTemplateMutation,
} from '~stores/services/template.api'
import { useAgencyId } from '~stores/slices/auth.slice'
import { showConfirmationPopup } from '~stores/slices/confirmation.slice'
import {
  saveAndPostTemplate,
  saveAndPreviewTemplate,
  setConfirmNavigation,
  setTemplateErrors,
  useConfirmNavigationTrigger,
  useSaveAndPostTemplateTrigger,
  useSaveAndRedirectToPreview,
} from '~stores/slices/template.slice'

import styles from './template-top-panel.module.scss'

interface LocationState {
  showPreviewPopup?: boolean
}

interface TemplateTopPanelProps {
  template: ITemplate
  readOnly?: boolean
  handleLoading: (val: boolean) => void
}

export const TemplateTopPanel: FC<TemplateTopPanelProps> = ({ template, readOnly, handleLoading }) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { templateId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const agencyId = useAgencyId()

  const [deploymentDetailsPopupOpen, setDeploymentDetailsPopupOpen] = useState(false)

  const [pageLoading, setPageLoading] = useState(false)
  const [errorsData, setErrorsData] = useState<IErrorRequestData | null>(null)
  const [hasNameError, setHasNameError] = useState(false)
  const [hasDelayError, setHasDelayError] = useState(false)
  const [hasEmptyPage, setHasEmptyPage] = useState(false)

  const [hasChanges, setHasChanges] = useState(false)

  const [isNavigateBlocked, setIsNavigateBlocked] = useState(false)
  const [showConfirmPopup, confirmNavigation, cancelNavigation] = useNavigateBlocker(isNavigateBlocked)

  const [initialTemplate, setInitialTemplate] = useState<ITemplate | null>(null)
  const [templateName, setTemplateName] = useState(template.name)
  const [templateDelay, setTemplateDelay] = useState(5)

  const saveAndPostTemplateTrigger = useSaveAndPostTemplateTrigger()
  const saveAndRedirectToPreview = useSaveAndRedirectToPreview()
  const confirmNavigationTrigger = useConfirmNavigationTrigger()

  const [confirmPopupText, setConfirmPopupText] = useState<IConfirmPopupText>({
    title: 'template-creation-is-not-completed',
    message: 'do-you-want-to-create-this-template',
    notSaveText: 'discard',
    saveText: 'create',
  })

  const [publishTemplate] = usePostTransitTemplatePublishMutation()
  const [updateTemplate, { isLoading: updateTemplateIsLoading }] = usePutTransitTemplateMutation()
  const [createTemplate, { isLoading: createTemplateIsLoading }] = usePostTransitTemplateMutation()

  useEffect(() => {
    setTemplateName(template.name)
  }, [template.name])

  useEffect(() => {
    setTemplateDelay(template.delay)
  }, [template.delay])

  useEffect(() => {
    setInitialTemplate(template)
  }, [template.status])

  useEffect(() => {
    if (hasChanges && template.status === statuses.Draft) {
      setIsNavigateBlocked(true)

      return
    }

    setIsNavigateBlocked(false)
  }, [hasChanges, template.status])

  useEffect(() => {
    if (templateId) {
      setConfirmPopupText({
        title: 'changes-not-saved',
        message: 'do-you-want-to-save-changes-before-leaving',
        notSaveText: 'dont-save',
        saveText: 'save',
      })
    }
  }, [templateId])

  const getEnqueueSnackbarErrors = useCallback(
    (errorData: IErrorRequestData) => {
      getErrorsValues(errorData).forEach((error) => {
        enqueueSnackbar(<div style={{ maxWidth: 300 }}>{error}</div>, {
          variant: 'error',
        })
      })
    },
    [enqueueSnackbar],
  )

  const handleTemplate = useCallback(async () => {
    if (hasEmptyPage) {
      enqueueSnackbar(translate('each-template-page-must-contain-at-least-one-content-block'), { variant: 'error' })

      return
    }

    if (templateId) {
      try {
        await updateTemplate({
          ...template,
          name: templateName.trim(),
          delay: templateDelay,
          agencyId,
        }).unwrap()

        enqueueSnackbar(translate('changes-saved'))

        if (saveAndRedirectToPreview) {
          navigate(saveAndRedirectToPreview)
          confirmNavigation()
          dispatch(saveAndPreviewTemplate(null))
        }

        if (saveAndPostTemplateTrigger) {
          setPageLoading(true)
          try {
            await publishTemplate({ templateId: template.templateId }).unwrap()

            enqueueSnackbar(translate('template-published'))
            dispatch(ActionCreators.clearHistory())
          } catch (err) {
            const error = err as IErrorRequest

            console.log(error)

            dispatch(
              showConfirmationPopup(
                <NotificationPopup
                  message={getErrorsValues(error.data).join(' ')}
                  title={translate('failed-to-publish')}
                />,
              ),
            )
          } finally {
            setPageLoading(false)
            dispatch(saveAndPostTemplate(false))
          }
        }

        setErrorsData(null)
        dispatch(setTemplateErrors(null))

        setInitialTemplate({ ...template, name: templateName, delay: templateDelay })

        if (showConfirmPopup) {
          confirmNavigation()
        }
      } catch (err) {
        const error = err as IErrorRequest

        console.error(error)

        setErrorsData(error.data)
        dispatch(setTemplateErrors(error.data))
        dispatch(saveAndPreviewTemplate(null))

        getEnqueueSnackbarErrors(error.data)

        cancelNavigation()
      }

      return
    }

    try {
      const newTemplate = await createTemplate({
        ...template,
        name: templateName.trim(),
        delay: templateDelay,
        agencyId,
      }).unwrap()

      confirmNavigation()

      setErrorsData(null)
      dispatch(setTemplateErrors(null))

      enqueueSnackbar(translate('template-created'))
      if (!showConfirmPopup) {
        navigate(`${PageUrls.Template}/${newTemplate.result.payload.templateId}`, { replace: true })
      }
    } catch (err) {
      const error = err as IErrorRequest

      console.error(error)
      setErrorsData(error.data)
      dispatch(setTemplateErrors(error.data))

      getEnqueueSnackbarErrors(error.data)

      cancelNavigation()
    }
  }, [
    templateId,
    createTemplate,
    template,
    templateName,
    templateDelay,
    updateTemplate,
    saveAndPostTemplateTrigger,
    saveAndRedirectToPreview,
    enqueueSnackbar,
    translate,
    dispatch,
    showConfirmPopup,
    publishTemplate,
    confirmNavigation,
    getEnqueueSnackbarErrors,
    cancelNavigation,
    navigate,
    agencyId,
    hasEmptyPage,
  ])

  const confirmNavigationAndSave = useCallback(() => {
    if (hasNameError || hasDelayError) {
      cancelNavigation()
      if (templateId) {
        enqueueSnackbar(translate('template-was-not-saved'), { variant: 'error' })
      } else {
        enqueueSnackbar(translate('template-was-not-created'), { variant: 'error' })
      }

      return
    }

    handleTemplate()
  }, [cancelNavigation, handleTemplate, hasDelayError, hasNameError, templateId])

  useEffect(() => {
    if (confirmNavigationTrigger) {
      confirmNavigation()
      dispatch(setConfirmNavigation(false))
    }
  }, [confirmNavigationTrigger])

  useEffect(() => {
    if (saveAndPostTemplateTrigger || saveAndRedirectToPreview) {
      if (hasNameError || hasDelayError) {
        cancelNavigation()
        enqueueSnackbar(translate('template-was-not-saved'), { variant: 'error' })
        dispatch(saveAndPreviewTemplate(null))

        return
      }

      handleTemplate()
    }
  }, [saveAndPostTemplateTrigger, saveAndRedirectToPreview, hasNameError, hasDelayError])

  useEffect(() => {
    handleLoading(updateTemplateIsLoading || createTemplateIsLoading || pageLoading)
  }, [handleLoading, updateTemplateIsLoading, createTemplateIsLoading, pageLoading])

  useEffect(() => {
    if (template.pages[0].id && !initialTemplate) {
      setInitialTemplate(template)
    }

    if (templatesNotEqual({ initialTemplate, template, templateName, templateDelay })) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
  }, [template, initialTemplate, templateName, templateDelay])

  useEffect(() => {
    if (template.pages.some((page) => !page.blocks.length)) {
      setHasEmptyPage(true)

      return
    }

    setHasEmptyPage(false)
  }, [template])

  return (
    <>
      <div className={`${styles.templateTopPanel} templateTopPanel-root`}>
        <IconButton className={styles.backButton} component={NavLink} to={PageUrls.Templates}>
          <ArrowBack />
        </IconButton>
        {readOnly ? (
          <>
            <Typography className={styles.templateName}>{templateName}</Typography>
            <div style={{ marginLeft: 'auto' }} />
            <DisplayType display={template.display} />
            <Status
              button={
                isPublishingStatus(template.status) && (
                  <IconButton onClick={() => setDeploymentDetailsPopupOpen(true)}>
                    <InfoOutlined />
                  </IconButton>
                )
              }
              secondary={
                isPublishingStatus(template.status) &&
                template.publishDetails && (
                  <Typography variant="body2">
                    {translate('x-y-units', {
                      x: template.publishDetails.updatedBusStopsCount,
                      y: template.publishDetails.totalBusStopsCount,
                    })}
                  </Typography>
                )
              }
              status={TemplateStatuses[template.status]}
            />
            <div className={styles.fakeTextField}>
              <label>{translate('delay')}:</label>
              <strong>{templateDelay}</strong> sec
            </div>
          </>
        ) : (
          <>
            <TemplateNameInput
              autoFocus={!templateId}
              errorsData={errorsData}
              getTemplateName={setTemplateName}
              hasErrorCallBack={setHasNameError}
              label={translate('template-name')}
              showErrorsOnBlur
              sx={{ width: '330px' }}
              value={templateName}
            />
            <Divider flexItem orientation="vertical" />
            <TemplateUndo />
            <div style={{ marginLeft: 'auto' }} />
            <DisplayType display={template.display} />
            <Status status={TemplateStatuses[template.status]} />
            <TemplateDelayInput
              errorsData={errorsData}
              handleDelay={setTemplateDelay}
              hasErrorCallBack={setHasDelayError}
              templateDelay={templateDelay}
            />
            <Button
              disabled={hasNameError || hasDelayError || !hasChanges}
              onClick={handleTemplate}
              size="medium"
              sx={{ ml: 2 }}
              variant="contained"
            >
              {templateId ? translate('save') : translate('create')}
            </Button>
          </>
        )}
        {templateId && (
          <TemplateActions
            builderView
            hasChanges={hasChanges}
            name={templateName}
            status={template.status}
            templateId={template.templateId}
            templateType={TemplateType.Transit}
          />
        )}
      </div>
      <ConfirmLeavingPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        confirmNavigationAndSave={confirmNavigationAndSave}
        confirmPopupText={confirmPopupText}
        showDialog={showConfirmPopup}
      />
      {template.publishDetails && (
        <DeploymentDetailsPopup
          handleClose={() => setDeploymentDetailsPopupOpen(false)}
          isOpen={deploymentDetailsPopupOpen}
          publishDetails={template.publishDetails}
        />
      )}
    </>
  )
}
