import { Button } from '@mui/material'
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LayoutBlockNames } from '~/enums/layout-block-names.enum'
import { ILayoutBlocksGroup } from '~models/layouts.model'
import { ConfigurationCustomTextBlock } from '~pages/Configurator/components/configuration-custom-text-block'
import { ConfigurationTimeBlock } from '~pages/Configurator/components/configuration-time-block'
import { ConfiguratorPopup } from '~pages/Configurator/components/configurator-popup'
import styles from '~pages/Configurator/configurator.module.scss'
import { useAppDispatch } from '~stores/hooks'
import {
  removeLayoutFieldErrors,
  setLayoutActiveBlockSettings,
  setLayoutBlockSettings,
  useLayoutActiveBlockSettings,
} from '~stores/slices/layout.slice'

interface ConfiguratorBlockSettingsProps {}

export const ConfiguratorBlockSettings: FC<ConfiguratorBlockSettingsProps> = () => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('')

  const blockGroup = useLayoutActiveBlockSettings()
  const [currentBlockGroup, setCurrentBlockGroup] = useState<ILayoutBlocksGroup>()
  const [component, setComponent] = useState<ReactNode | null>(null)
  const [fieldError, setFieldError] = useState(false)

  const handleOpen = useCallback(() => {
    dispatch(setLayoutActiveBlockSettings(null))
  }, [])

  const handleBlockGroup = useCallback((blockGroup: ILayoutBlocksGroup) => {
    setCurrentBlockGroup(blockGroup)
  }, [])

  const handleSaveSettings = useCallback(() => {
    if (currentBlockGroup) {
      dispatch(setLayoutBlockSettings(currentBlockGroup))

      dispatch(removeLayoutFieldErrors(currentBlockGroup.block.componentName))
    }

    handleOpen()
  }, [currentBlockGroup])

  useEffect(() => {
    if (!blockGroup) return

    if (blockGroup.block.componentName === LayoutBlockNames.time) {
      setTitle('time-settings')
      setComponent(<ConfigurationTimeBlock blockGroup={blockGroup} handleBlockGroup={handleBlockGroup} />)
    }

    if (blockGroup.block.componentName === LayoutBlockNames.serviceEmail) {
      setTitle('service-email-settings')
      setComponent(
        <ConfigurationCustomTextBlock
          blockGroup={blockGroup}
          customField="serviceEmail"
          handleBlockGroup={handleBlockGroup}
          handleFieldError={setFieldError}
          label="email"
        />,
      )
    }

    if (blockGroup.block.componentName === LayoutBlockNames.servicePhone) {
      setTitle('service-phone-settings')
      setComponent(
        <ConfigurationCustomTextBlock
          blockGroup={blockGroup}
          customField="servicePhone"
          handleBlockGroup={handleBlockGroup}
          handleFieldError={setFieldError}
          label="phone-number"
        />,
      )
    }

    if (blockGroup.block.componentName === LayoutBlockNames.customText) {
      setTitle('custom-text-settings')
      setComponent(
        <ConfigurationCustomTextBlock
          blockGroup={blockGroup}
          handleBlockGroup={handleBlockGroup}
          handleFieldError={setFieldError}
        />,
      )
    }
  }, [blockGroup, handleOpen])

  if (!blockGroup) {
    return null
  }

  return (
    <ConfiguratorPopup handleOpen={handleOpen} open title={translate(title)}>
      {component}
      <div className={styles.configuratorPopupFooter}>
        <Button onClick={handleOpen}>{translate('cancel')}</Button>
        <Button disabled={fieldError} onClick={handleSaveSettings}>
          {translate('save')}
        </Button>
      </div>
    </ConfiguratorPopup>
  )
}
