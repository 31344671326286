import { Close } from '@mui/icons-material'
import { IconButton, Popover, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BusStopStatuses } from '~/enums/bus-stop-templates.enum'
import { BusStopDeployed } from '~/enums/template-type.enum'
import { Status } from '~components/Status/status'
import { showByFormat } from '~helpers/date-helper'
import { sortByFields } from '~helpers/sort-by-fields'
import { IBusStopTemplateDeployed } from '~models/bus-stop-templates.model'

import styles from '../bus-stop.module.scss'

interface BusStopTemplatesTablePopoverProps {
  busStopName: string
  templates: IBusStopTemplateDeployed[]
  anchorElement: HTMLElement | null
  onClose: () => void
}

export const BusStopTemplatesTablePopover: FC<BusStopTemplatesTablePopoverProps> = ({
  busStopName,
  templates,
  anchorElement,
  onClose,
}) => {
  const { t: translate } = useTranslation()

  const open = Boolean(anchorElement)

  return (
    <Popover
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className={styles.popover}
      onClose={onClose}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Grid2 container spacing={2} sx={{ mb: 0 }}>
        <Grid2 xs>
          <Typography variant="h6">
            {translate('templates')}
            <Typography color={grey[500]} variant="subtitle2">
              ({busStopName})
            </Typography>
          </Typography>
        </Grid2>
        <Grid2>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Grid2>
      </Grid2>
      <Table>
        <TableBody>
          {sortByFields([...templates], false, 'type').map(({ id, name, lastModifiedAt, status, type }) => {
            const { name: typeName, color: typeColor } = BusStopDeployed[type]

            return (
              <TableRow key={id}>
                <TableCell>{name}</TableCell>
                <TableCell width={132}>
                  <div className="deployed-tag" style={{ borderColor: typeColor }}>
                    {typeName}
                  </div>
                </TableCell>
                <TableCell width={190}>
                  <Status
                    secondary={
                      lastModifiedAt && (
                        <Typography sx={{ letterSpacing: 0 }} variant="caption">
                          {showByFormat(lastModifiedAt, 'MMM D, YYYY')} at {showByFormat(lastModifiedAt, 'h:mm A')}
                        </Typography>
                      )
                    }
                    status={BusStopStatuses[status]}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Popover>
  )
}
