import { Close } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'

import styles from '~pages/Configurator/configurator.module.scss'

interface ConfiguratorPopupProps {
  title: string
  open: boolean
  children: React.ReactNode
  handleOpen: (val: boolean) => void
}

export const ConfiguratorPopup: FC<ConfiguratorPopupProps> = ({ title, open, handleOpen, children }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(open)
    }, 100)
  }, [open])

  return (
    <div className={`${styles.configuratorPopup} ${show ? styles.active : ''}`}>
      <div className={styles.configuratorPopupHeader}>
        <Typography sx={{ textTransform: 'capitalize' }}>{title}</Typography>
        <IconButton onClick={() => handleOpen(false)} size="small">
          <Close />
        </IconButton>
      </div>
      {children}
    </div>
  )
}
