import React, { FC } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import styles from '~components/TemplateBlockComponent/blocks/template-blocks.module.scss'
import { TemplateFrameSimple } from '~components/TemplateBlockComponent/frames/template-frame-simple'
import { IDefaultTemplateCssStyles } from '~models/template.model'
import { ITemplateBlockProperties } from '~models/template-block.model'

export interface TemplateStaticTextBlockProps extends ITemplateBlockProperties {
  cssStyles?: IDefaultTemplateCssStyles
  text?: string
  id?: string
  thumbnailMode?: boolean
  readOnly?: boolean
}

export const TemplateStaticTextBlock: FC<TemplateStaticTextBlockProps> = ({
  view,
  cssStyles,
  text = 'Custom text',
  id,
  thumbnailMode,
  readOnly,
}) => {
  const blockClass = view === TemplateBlocksView.pure ? styles.templateBlockPure : styles.templateBlock

  return (
    <div
      className={blockClass}
      data-font-size={cssStyles?.fontSize}
      style={{
        color: cssStyles?.color,
        backgroundColor: cssStyles?.backgroundColor,
      }}
    >
      <TemplateFrameSimple
        alignItems={cssStyles?.alignItems}
        backgroundColor={cssStyles?.backgroundColor}
        dataType="static-text"
        fontSize={cssStyles?.fontSize}
        id={id}
        readOnly={readOnly}
        text={text}
        textAlign={cssStyles?.textAlign}
        thumbnailMode={thumbnailMode}
        view={view}
      />
    </div>
  )
}
