import { skipToken } from '@reduxjs/toolkit/query'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { DisplaySizeKeys } from '~/enums/display-size'
import { TemplateType } from '~/enums/template-type.enum'
import { Canvas } from '~components/Canvas/canvas'
import { PageSpinner } from '~components/PageSpinner/page-spinner'
import { Spinner } from '~components/Spinner/spinner'
import { TemplateAsidePages } from '~components/TemplateAsidePages/template-aside-pages'
import { isInDeploymentStatus } from '~helpers/check-template-status'
import { DefaultTemplateAsideBlocks } from '~pages/DefaultTemplate/components/default-template-aside-blocks'
import { DefaultTemplateTopPanel } from '~pages/DefaultTemplate/components/default-template-top-panel'
import { useAppDispatch } from '~stores/hooks'
import { useGetDefaultTemplateQuery } from '~stores/services/template.api'
import {
  clearTemplate,
  setTemplateActivePage,
  setTemplateStore,
  useTemplate,
  useTemplateIsFetching,
} from '~stores/slices/template.slice'

import styles from './default-template.module.scss'

export const DefaultTemplate = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const template = useTemplate()
  const templateIsFetching = useTemplateIsFetching()

  const [isLoading, setIsLoading] = useState(false)
  const [activeDisplay, setActiveDisplay] = useState<DisplaySizeKeys>()

  const readOnly = useMemo(() => isInDeploymentStatus(template.status), [template.status])

  const {
    data: defaultTemplateData,
    isLoading: defaultTemplateIsLoading,
    isFetching: defaultTemplateIsFetching,
  } = useGetDefaultTemplateQuery(activeDisplay ? { display: activeDisplay } : skipToken)

  useEffect(() => {
    dispatch(clearTemplate())
  }, [dispatch, location])

  useEffect(() => {
    dispatch(setTemplateActivePage(''))
  }, [dispatch])

  useEffect(() => {
    if (!defaultTemplateIsLoading && defaultTemplateData?.result) {
      dispatch(setTemplateStore({ ...defaultTemplateData.result }))
    }
  }, [defaultTemplateIsLoading, defaultTemplateData?.result, dispatch])

  if (defaultTemplateIsLoading) {
    return <Spinner />
  }

  if (!template) {
    return null
  }

  return (
    <div className={styles.templateContainer}>
      <DefaultTemplateTopPanel
        handleLoading={setIsLoading}
        onChangeDisplaySize={setActiveDisplay}
        readOnly={readOnly}
        template={template}
      />
      <div className={styles.templateContent}>
        {!readOnly && <DefaultTemplateAsideBlocks />}
        <div className={styles.templateHolder}>
          <Canvas
            displaySize={template.display}
            readOnly={readOnly}
            template={template}
            templateType={TemplateType.Default}
          />
        </div>
        <TemplateAsidePages readOnly={readOnly} template={template} />
      </div>
      <PageSpinner show={defaultTemplateIsFetching || isLoading || templateIsFetching} />
    </div>
  )
}
