import uuid from 'react-native-uuid'

import { TemplateStatusesEnum as statuses } from '~/enums/templates.enum'
import { ITemplate } from '~models/template.model'

export const prepareTemplateForCopy = (template: ITemplate) => {
  const copyTemplate: ITemplate = {
    ...template,
    status: statuses.Draft,
    pages: template.pages.map((page) => {
      const templateBlocks = page.blocks.map((block) => ({
        ...block,
        id: uuid.v4().toString(),
      }))

      return {
        ...page,
        id: uuid.v4().toString(),
        blocks: templateBlocks,
      }
    }),
  }

  return copyTemplate
}
