import '~localization/config'

import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser'
import { Grow, ThemeProvider } from '@mui/material'
import { ConfirmProvider } from 'material-ui-confirm'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { msalConfig } from '~/authConfig'
import { SnackbarCloseButton } from '~components/SnackbarCloseButton/snackbar-close-button'
import { customTheme } from '~helpers/custom-theme'
import { persistor, store } from '~stores/store'

import App from './App'
import reportWebVitals from './reportWebVitals'

export const msalInstance = new PublicClientApplication(msalConfig)

const accounts = msalInstance.getAllAccounts()

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const { account } = payload

    msalInstance.setActiveAccount(account)
  }
})

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={customTheme}>
          <SnackbarProvider
            TransitionComponent={Grow}
            action={(snackbarId) => <SnackbarCloseButton snackbarId={snackbarId} />}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            autoHideDuration={4000}
            maxSnack={4}
            preventDuplicate
          >
            <ConfirmProvider
              defaultOptions={{
                dialogProps: { maxWidth: 'xs', fullWidth: true },
                confirmationButtonProps: { variant: 'text' },
                cancellationButtonProps: { color: 'inherit' },
              }}
            >
              <App pca={msalInstance} />
            </ConfirmProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
