import React, { useCallback, useEffect, useState } from 'react'
import uuid from 'react-native-uuid'

import { LayoutBlockNames } from '~/enums/layout-block-names.enum'
import { LayoutPanels } from '~/enums/layout-panels.enum'
import { useCanvasProperties } from '~/hooks/use-canvas-properties'
import { useWindowSize } from '~/hooks/use-window-size'
import { PageSpinner } from '~components/PageSpinner/page-spinner'
import { Spinner } from '~components/Spinner/spinner'
import { deleteKeysFormArrayOfObjects } from '~helpers/delete-keys-form-array-of-objects'
import { updateBlocksColsNumbers } from '~helpers/update-blocks-cols-numbers'
import { ILayout, ILayoutBlocksGroup, ILayoutBlockType, ILayoutCssStyles } from '~models/layouts.model'
import { ConfiguratorBar } from '~pages/Configurator/components/configurator-bar'
import { ConfiguratorBlockList } from '~pages/Configurator/components/configurator-block-list'
import { ConfiguratorBlockSettings } from '~pages/Configurator/components/configurator-block-settings'
import { ConfiguratorFooter } from '~pages/Configurator/components/configurator-footer'
import { ConfiguratorHeader } from '~pages/Configurator/components/configurator-header'
import { ConfiguratorTools } from '~pages/Configurator/components/configurator-tools'
import { useAppDispatch } from '~stores/hooks'
import { useGetLayoutBlocksQuery, useGetLayoutsQuery } from '~stores/services/layouts.api'
import {
  setLayoutFooterBlocks,
  setLayoutHeaderBlocks,
  setLayoutStore,
  useLayoutActiveToolsPanel,
  useLayoutFooterBlocks,
  useLayoutFooterCssStyles,
  useLayoutFooterIsActive,
  useLayoutHeaderBlocks,
  useLayoutHeaderCssStyles,
  useLayoutHeaderIsActive,
} from '~stores/slices/layout.slice'
import { setLayoutBlocks, useLayoutBlocks } from '~stores/slices/layout-blocks.slice'

import styles from './configurator.module.scss'

interface ITemporaryCssStyles {
  [LayoutPanels.header]: ILayoutCssStyles
  [LayoutPanels.footer]: ILayoutCssStyles
}

export const Configurator = () => {
  const dispatch = useAppDispatch()
  const [windowWidth, windowHeight] = useWindowSize()

  useCanvasProperties({ windowWidth, windowHeight, offsetY: 170 })

  const headerBlocks = useLayoutHeaderBlocks()
  const footerBlocks = useLayoutFooterBlocks()
  const headerIsActive = useLayoutHeaderIsActive()
  const footerIsActive = useLayoutFooterIsActive()
  const layoutBlocks = useLayoutBlocks()

  const headerCssStyles = useLayoutHeaderCssStyles()
  const footerCssStyles = useLayoutFooterCssStyles()
  const layoutActiveLayoutToolsPanel = useLayoutActiveToolsPanel()

  const [initialLayout, setInitialLayout] = useState<ILayout>()
  const [isLoading, setIsLoading] = useState(false)

  const [temporaryCssStyles, setTemporaryCssStyles] = useState<ITemporaryCssStyles>({
    [LayoutPanels.header]: headerCssStyles,
    [LayoutPanels.footer]: footerCssStyles,
  })

  const { data: layoutBlocksData, isLoading: layoutBlocksDataIsLoading } = useGetLayoutBlocksQuery()
  const { data: layoutData, isLoading: layoutsDataIsLoading } = useGetLayoutsQuery()

  useEffect(() => {
    setTemporaryCssStyles({
      [LayoutPanels.header]: headerCssStyles,
      [LayoutPanels.footer]: footerCssStyles,
    })
  }, [headerCssStyles, footerCssStyles, layoutActiveLayoutToolsPanel])

  const handleTemporaryCssStyles = useCallback(
    (cssStyles: ILayoutCssStyles, panel: LayoutPanels | null) => {
      if (panel) {
        setTemporaryCssStyles({
          ...temporaryCssStyles,
          [panel]: cssStyles,
        })
      }
    },
    [temporaryCssStyles],
  )

  useEffect(() => {
    if (layoutData?.result && !layoutsDataIsLoading && layoutBlocksData && !layoutBlocksDataIsLoading) {
      const layout = { ...layoutData.result }

      dispatch(setLayoutStore(layout))

      const headerBlocksCopy = [...layout.header.blocks]
      const footerBlocksCopy = [...layout.footer.blocks]
      const layoutBlocksCopy = [...layoutBlocksData.result]

      headerBlocksCopy.map((headerBlock) => {
        const arrIndex = layoutBlocksCopy.findIndex((layoutBlock) => layoutBlock.id === headerBlock.layoutBlockId)

        if (arrIndex !== -1) {
          layoutBlocksCopy[arrIndex] = {
            ...layoutBlocksCopy[arrIndex],
            panel: LayoutPanels.header,
          }
        }
      })
      footerBlocksCopy.map((headerBlock) => {
        const arrIndex = layoutBlocksCopy.findIndex((layoutBlock) => layoutBlock.id === headerBlock.layoutBlockId)

        if (arrIndex !== -1) {
          layoutBlocksCopy[arrIndex] = {
            ...layoutBlocksCopy[arrIndex],
            panel: LayoutPanels.footer,
          }
        }
      })

      dispatch(setLayoutBlocks([...layoutBlocksCopy]))
      dispatch(setLayoutHeaderBlocks([...headerBlocksCopy]))
      dispatch(setLayoutFooterBlocks([...footerBlocksCopy]))

      setInitialLayout({
        backgroundColor: '#d8d8d8',
        [LayoutPanels.header]: {
          ...layout.header,
          blocks: [...headerBlocksCopy],
        },
        [LayoutPanels.footer]: {
          ...layout.footer,
          blocks: [...footerBlocksCopy],
        },
      })
    }
  }, [layoutData?.result, layoutBlocksData?.result, layoutBlocksDataIsLoading, layoutsDataIsLoading])

  const handleBlock = useCallback(
    (id: number, panel: LayoutPanels | null) => {
      if (layoutBlocks) {
        const toggleBlock = layoutBlocks.find((item) => item.id === id)

        if (!toggleBlock) return
        if (toggleBlock.panel) {
          if (panel === LayoutPanels.header) {
            dispatch(setLayoutHeaderBlocks([...headerBlocks.filter((item) => item.layoutBlockId !== id)]))
          }

          if (panel === LayoutPanels.footer) {
            dispatch(setLayoutFooterBlocks([...footerBlocks.filter((item) => item.layoutBlockId !== id)]))
          }
        } else {
          let blockTypeProps: ILayoutBlockType = {}

          switch (toggleBlock.componentName) {
            case LayoutBlockNames.time:
              blockTypeProps = {
                timeFormat: '12h',
                showDayOfTheWeek: false,
              }
              break

            case LayoutBlockNames.date:
              blockTypeProps = {
                dateFormat: 'YYYY-MM-DD',
              }
              break

            case LayoutBlockNames.customText:
              blockTypeProps = {
                customText: toggleBlock.name,
              }
              break

            case LayoutBlockNames.serviceEmail:
              blockTypeProps = {
                serviceEmail: '',
              }
              break

            case LayoutBlockNames.servicePhone:
              blockTypeProps = {
                servicePhone: '',
              }
              break

            default:
              break
          }

          const toggleBlockWithoutPanel = { ...toggleBlock }

          if (toggleBlockWithoutPanel.hasOwnProperty('panel')) {
            delete toggleBlockWithoutPanel.panel
          }

          const newBlock: ILayoutBlocksGroup = {
            id: uuid.v4().toString(),
            layoutBlockId: toggleBlock.id,
            position: {
              col: 1,
            },
            block: toggleBlockWithoutPanel,
            ...blockTypeProps,
          }

          if (panel === LayoutPanels.header) {
            dispatch(setLayoutHeaderBlocks([...headerBlocks, newBlock]))
          }

          if (panel === LayoutPanels.footer) {
            if (footerBlocks[0]?.position.col === 2) {
              dispatch(setLayoutFooterBlocks(updateBlocksColsNumbers([newBlock, ...footerBlocks])))
            } else {
              dispatch(setLayoutFooterBlocks(updateBlocksColsNumbers([...footerBlocks, newBlock])))
            }
          }
        }

        const index = layoutBlocks.findIndex((block) => block.id === id)
        const layoutBlocksCopy = [...layoutBlocks]

        if (index !== -1) {
          layoutBlocksCopy[index] = {
            ...layoutBlocksCopy[index],
            panel: layoutBlocksCopy[index].panel ? null : panel,
          }
        }

        dispatch(setLayoutBlocks([...layoutBlocksCopy]))
      }
    },
    [dispatch, headerBlocks, footerBlocks, layoutBlocks],
  )

  const onSortBlocks = useCallback(
    (newState: ILayoutBlocksGroup[], panel: LayoutPanels) => {
      const sortedBlocks = deleteKeysFormArrayOfObjects(newState, ['chosen', 'selected'])

      if (panel === LayoutPanels.header && JSON.stringify(headerBlocks) !== JSON.stringify(sortedBlocks)) {
        dispatch(setLayoutHeaderBlocks([...sortedBlocks]))
      }

      if (panel === LayoutPanels.footer && JSON.stringify(headerBlocks) !== JSON.stringify(sortedBlocks)) {
        dispatch(setLayoutFooterBlocks([...sortedBlocks]))
      }
    },
    [headerBlocks],
  )

  if (layoutBlocksDataIsLoading || layoutsDataIsLoading) {
    return <Spinner />
  }

  if (!layoutBlocks || !headerBlocks || !layoutData || !initialLayout) {
    return null
  }

  return (
    <div className={styles.configuratorContainer}>
      <ConfiguratorBar handleLoading={setIsLoading} initialLayout={initialLayout} />
      <div className={styles.configuratorHolder}>
        <div className={styles.container}>
          {headerIsActive && (
            <ConfiguratorHeader
              handleBlock={handleBlock}
              headerBlocks={headerBlocks}
              onSortBlocks={onSortBlocks}
              temporaryCssStyles={temporaryCssStyles[LayoutPanels.header]}
            />
          )}
          <div className={styles.content}>
            <ConfiguratorBlockList handleBlock={handleBlock} layoutBlocks={layoutBlocks} />
            {layoutActiveLayoutToolsPanel && (
              <ConfiguratorTools
                handleTemporaryCssStyles={handleTemporaryCssStyles}
                layoutActiveLayoutToolsPanel={layoutActiveLayoutToolsPanel}
                temporaryCssStyles={temporaryCssStyles[layoutActiveLayoutToolsPanel]}
              />
            )}
            <ConfiguratorBlockSettings />
          </div>
          {footerIsActive && (
            <ConfiguratorFooter
              footerBlocks={footerBlocks}
              handleBlock={handleBlock}
              temporaryCssStyles={temporaryCssStyles[LayoutPanels.footer]}
            />
          )}
        </div>
      </div>
      <PageSpinner show={isLoading} />
    </div>
  )
}
