import { ComponentType, createElement, CSSProperties, FC } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import { ITemplateBlockProperties } from '~models/template-block.model'
import { TemplateConnectionBlock, TemplateConnectionBlockProps } from '~templateBlocks/template-connection-block'
import { TemplateEtaBlock, TemplateEtaBlockProps } from '~templateBlocks/template-eta-block'
import { TemplateMessageBlock, TemplateMessageBlockProps } from '~templateBlocks/template-message-block'
import { TemplateRouteBlock, TemplateRouteBlockProps } from '~templateBlocks/template-route-block'
import { TemplateStaticImageBlock } from '~templateBlocks/template-static-image-block'
import { TemplateStaticLabelBlock, TemplateStaticLabelBlockProps } from '~templateBlocks/template-static-label-block'
import { TemplateStaticTextBlock } from '~templateBlocks/template-static-text-block'

interface IComponents {
  TemplateRouteBlock: ComponentType<TemplateRouteBlockProps>
  TemplateEtaBlock: ComponentType<TemplateEtaBlockProps>
  TemplateMessageBlock: ComponentType<TemplateMessageBlockProps>
  TemplateStaticLabelBlock: ComponentType<TemplateStaticLabelBlockProps>
  TemplateConnectionBlock: ComponentType<TemplateConnectionBlockProps>
  TemplateStaticTextBlock: ComponentType<TemplateStaticLabelBlockProps>
  TemplateStaticImageBlock: ComponentType<TemplateStaticLabelBlockProps>
}

const components: IComponents = {
  TemplateRouteBlock,
  TemplateEtaBlock,
  TemplateMessageBlock,
  TemplateStaticLabelBlock,
  TemplateConnectionBlock,
  TemplateStaticTextBlock,
  TemplateStaticImageBlock,
}

interface TemplateComponentProps {
  componentName: string
  view: TemplateBlocksView
  properties: ITemplateBlockProperties
  cssStyles?: CSSProperties
  text?: string
  image?: string
  thumbnailMode?: boolean
  firstPage?: boolean
  routeIndex?: number
  id?: string
  readOnly?: boolean
}

export const TemplateBlockComponent: FC<TemplateComponentProps> = ({
  componentName,
  properties,
  view,
  cssStyles,
  text,
  image,
  thumbnailMode,
  firstPage,
  routeIndex,
  id,
  readOnly,
}) => {
  if (components.hasOwnProperty(componentName)) {
    return createElement((components as any)[componentName], {
      ...properties,
      view,
      cssStyles,
      text,
      image,
      thumbnailMode,
      firstPage,
      routeIndex,
      id,
      readOnly,
    })
  }

  return null
}
