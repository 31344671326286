import React, { FC, useMemo } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import { TemplatePageItem } from '~components/TemplateAsidePages/components/TemplatePageItem/template-page-item'
import styles from '~components/TemplateAsidePages/template-aside-pages.module.scss'
import { sortPageBlocksByRouteIndex } from '~helpers/sort-page-blocks-by-route-index'
import { ITemplate } from '~models/template.model'

interface TemplatePagesAsideProps {
  template: ITemplate
  view?: TemplateBlocksView
  readOnly?: boolean
}

export const PreviewTemplateAsidePages: FC<TemplatePagesAsideProps> = ({
  template,
  view = TemplateBlocksView.fakeData,
  readOnly,
}) => {
  const sortedPages = useMemo(
    () =>
      template.pages.map((page) => ({
        ...page,
        blocks: sortPageBlocksByRouteIndex(page.blocks),
      })),
    [template.pages],
  )

  return (
    <aside className={styles.templateAside}>
      <div className={`blocks-scroll-bar ${styles.scrollBlock}`}>
        {sortedPages.map((page, index) => (
          <TemplatePageItem
            displaySize={template.display}
            index={index}
            key={page.id}
            onlyOnePage={sortedPages.length > 1}
            page={page}
            readOnly={readOnly}
            view={view}
          />
        ))}
      </div>
    </aside>
  )
}
