import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import React, { FC } from 'react'

interface CloseModalProps {
  handleClose: () => void
}

export const CloseModal: FC<CloseModalProps> = ({ handleClose }) => (
  <IconButton
    aria-label="close"
    onClick={handleClose}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
    }}
  >
    <CloseIcon />
  </IconButton>
)
