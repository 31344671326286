import React, { FC } from 'react'

import { CanvasLayoutBlocks } from '~components/Canvas/components/canvas-layout-blocks'
import { ILayoutDynamicBlocks } from '~models/layout-blocks.model'
import { ILayoutBlocksGroup, ILayoutCssStyles } from '~models/layouts.model'

import styles from '../canvas.module.scss'

interface CanvasFooterProps {
  headerBlocks: ILayoutBlocksGroup[]
  headerCssStyles: ILayoutCssStyles
  layoutDynamicBlocks?: ILayoutDynamicBlocks
  preview?: boolean
}

export const CanvasHeader: FC<CanvasFooterProps> = ({
  headerBlocks,
  headerCssStyles,
  layoutDynamicBlocks,
  preview,
}) => (
  <header className={styles.panelHeader} style={headerCssStyles}>
    {headerBlocks.map((headerBlock) => (
      <div
        className={`${styles.panelBlock} ${styles[headerBlock.block.componentName]} ${styles.panelBlockPreview}`}
        key={headerBlock.block.componentName}
      >
        <CanvasLayoutBlocks layoutBlock={headerBlock} layoutDynamicBlocks={layoutDynamicBlocks} preview={preview} />
      </div>
    ))}
  </header>
)
