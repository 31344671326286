export enum TemplateStatusesEnum {
  InDeployment = 'InDeployment',
  Draft = 'Draft',
  Deployed = 'Deployed',
  Archived = 'Archived',
}

export enum TemplateAction {
  preview = 'preview',
  copy = 'copy',
  publish = 'publish',
  delete = 'delete',
  setDraft = 'set-draft',
}

export enum TemplateTableCells {
  id = 'id',
  name = 'name',
  createdAt = 'createdAt',
  lastModifiedAt = 'lastModifiedAt',
  createdByUsername = 'createdByUsername',
  lastModifiedByUsername = 'lastModifiedByUsername',
  pages = 'templatePagesCount',
  status = 'status',
  agencies = 'targetingAgenciesNames',
  actions = 'actions',
}

export type TemplateStatusesKeys = keyof typeof TemplateStatusesEnum
export type TemplateActionsKeys = keyof typeof TemplateAction
