import { Button } from '@mui/material'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSortable } from 'react-sortablejs'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import { TemplatePageItem } from '~components/TemplateAsidePages/components/TemplatePageItem/template-page-item'
import { deleteKeysFormArrayOfObjects } from '~helpers/delete-keys-form-array-of-objects'
import { updateOrderNumbers } from '~helpers/update-order-numbers'
import { ITemplate, ITemplatePage } from '~models/template.model'
import { useAppDispatch } from '~stores/hooks'
import { addTemplatePage, setTemplatePages } from '~stores/slices/template.slice'

import styles from './template-aside-pages.module.scss'

interface TemplatePagesAsideProps {
  template: ITemplate
  view?: TemplateBlocksView
  readOnly?: boolean
}

export const TemplateAsidePages: FC<TemplatePagesAsideProps> = ({
  template,
  view = TemplateBlocksView.fakeData,
  readOnly,
}) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const [pages, setPages] = useState<ITemplatePage[]>([])
  const scrollRef = useRef<HTMLDivElement>(null)
  const [onChoose, setOnChoose] = useState(false)

  useEffect(() => {
    setPages([...template.pages])
  }, [template])

  const onSortPages = useCallback(
    (newState: ITemplatePage[]) => {
      const sortedPages = updateOrderNumbers(deleteKeysFormArrayOfObjects(newState, ['chosen']))

      if (JSON.stringify(pages) !== JSON.stringify(sortedPages)) {
        setPages([...sortedPages])
        dispatch(setTemplatePages([...sortedPages]))
      }
    },
    [pages],
  )

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    })
  }

  const handleAddNewPage = useCallback(() => {
    dispatch(addTemplatePage())
    scrollToBottom()
  }, [dispatch])

  return (
    <aside className={styles.templateAside}>
      {!readOnly && (
        <div className={styles.topActions}>
          <Button onClick={handleAddNewPage} variant="outlined">
            {translate('add-page')}
          </Button>
        </div>
      )}
      <div className={`blocks-scroll-bar ${styles.scrollBlock}`}>
        {pages && (
          <ReactSortable
            animation={300}
            className={onChoose ? 'on-choose' : ''}
            delay={0}
            handle=".handle"
            list={pages.map((block) => ({ ...block, chosen: true }))}
            onChoose={() => setOnChoose(true)}
            onUnchoose={() => setOnChoose(false)}
            setList={(newState) => onSortPages(newState)}
          >
            {pages.map((page, index) => (
              <TemplatePageItem
                displaySize={template.display}
                index={index}
                key={page.id}
                onlyOnePage={pages.length > 1}
                page={page}
                readOnly={readOnly}
                view={view}
              />
            ))}
          </ReactSortable>
        )}
        <div ref={scrollRef} />
      </div>
    </aside>
  )
}
