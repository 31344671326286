import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uuid from 'react-native-uuid'

import { AlignItems } from '~/enums/align-settings.enum'
import { DisplaySizeKeys } from '~/enums/display-size'
import { TemplateStatusesEnum } from '~/enums/templates.enum'
import { updateOrderNumbers } from '~helpers/update-order-numbers'
import { IErrorRequestData } from '~models/error-request.model'
import { IDuplicateTemplatePage, ISetTemplateBlocks, ITemplate, ITemplatePage } from '~models/template.model'
import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

interface ITemplateSlice {
  data: ITemplate
  activePageId: string
  saveAndPostTemplateTrigger: boolean
  saveAndRedirectToPreview: string | null
  confirmNavigationTrigger: boolean
  isFetching: boolean
  templateErrors: IErrorRequestData | null
}

const initialState: ITemplateSlice = {
  data: {
    templateId: 0,
    agencyId: 0,
    objectId: '',
    name: '',
    status: TemplateStatusesEnum.Draft,
    delay: 5,
    display: 'ThirteenInchesEInk',
    pages: [
      {
        id: '',
        pageName: '',
        orderNumber: 0,
        blocks: [],
      },
    ],
    publishDetails: null,
  },
  activePageId: '',
  saveAndPostTemplateTrigger: false,
  saveAndRedirectToPreview: null,
  confirmNavigationTrigger: false,
  isFetching: false,
  templateErrors: null,
}

const templateSlice = createSlice({
  name: 'templateSlice',
  initialState,
  reducers: {
    setTemplateStore: (state, { payload }: PayloadAction<ITemplate>) => {
      state.data = payload
    },
    setTemplateBlocks: (state, { payload }: PayloadAction<ISetTemplateBlocks>) => {
      const { pages } = state.data
      const index = pages.findIndex((page) => page.id === payload.activePageId)

      if (index !== -1) {
        pages[index].blocks = payload.blocks
      }
    },
    setTemplateBlock: (
      state,
      { payload }: PayloadAction<{ id: string; text?: string; alignItems?: AlignItems; backgroundColor?: string }>,
    ) => {
      const { pages } = state.data

      pages.forEach((page) => {
        page.blocks.map((block) => {
          if (block.id === payload.id) {
            if (payload.text) {
              block.text = payload.text
            }

            if (block.cssStyles && payload.alignItems) {
              block.cssStyles.alignItems = payload.alignItems
            }

            if (block.cssStyles && payload.backgroundColor) {
              block.cssStyles.backgroundColor = payload.backgroundColor
            }
          }

          return block
        })
      })
    },
    duplicateTemplatePage: (state, { payload }: PayloadAction<IDuplicateTemplatePage>) => {
      const { pages } = state.data
      const index = pages.findIndex((page) => page.id === payload.duplicatedPageId)

      if (index !== -1) {
        pages.splice(index + 1, 0, payload.page)

        state.activePageId = pages[index + 1].id

        state.data.pages = updateOrderNumbers(pages)
      }
    },
    deleteTemplatePage: (state, { payload }: PayloadAction<string>) => {
      const { pages } = state.data
      const index = pages.findIndex((page) => page.id === payload)

      const { length } = pages

      if (index !== -1) {
        const isActivePage = state.activePageId === pages[index].id

        pages.splice(index, 1)

        state.data.pages = updateOrderNumbers(pages)

        if (!isActivePage) return

        if (length - 1 === index) {
          state.activePageId = pages[pages.length - 1].id

          return
        }

        state.activePageId = pages[index].id
      }
    },
    setTemplatePages: (state, { payload }: PayloadAction<ITemplatePage[]>) => {
      state.data.pages = payload
    },
    addTemplatePage: (state) => {
      const { pages } = state.data

      const id = uuid.v4().toString()

      pages.push({
        id,
        orderNumber: state.data.pages.length,
        pageName: `Page ${state.data.pages.length + 1}`,
        blocks: [],
      })

      state.activePageId = id
    },

    newTemplate: (state, { payload }: PayloadAction<DisplaySizeKeys | undefined>) => {
      const id = uuid.v4().toString()

      state.data.pages = [
        {
          id,
          orderNumber: 0,
          pageName: 'Page 1',
          blocks: [],
        },
      ]

      state.activePageId = id

      if (payload) {
        state.data.display = payload
      }
    },

    setTemplateActivePage: (state, { payload }: PayloadAction<string>) => {
      state.activePageId = payload
    },

    saveAndPostTemplate: (state, { payload }: PayloadAction<boolean>) => {
      state.saveAndPostTemplateTrigger = payload
    },

    saveAndPreviewTemplate: (state, { payload }: PayloadAction<string | null>) => {
      state.saveAndRedirectToPreview = payload
    },

    setTemplateIsFetching: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetching = payload
    },

    clearTemplate: (state) => {
      state.data = initialState.data
      state.activePageId = initialState.activePageId
    },

    setTemplateErrors: (state, { payload }: PayloadAction<IErrorRequestData | null>) => {
      state.templateErrors = payload
    },

    setConfirmNavigation: (state, { payload }: PayloadAction<boolean>) => {
      state.confirmNavigationTrigger = payload
    },
  },
})

export const getTemplate = (state: RootState) => state.template.present.data
export const getTemplateActivePage = (state: RootState) => state.template.present.activePageId
export const getTemplateHistoryPast = (state: RootState) => state.template.past
export const getTemplateHistoryFuture = (state: RootState) => state.template.future
export const getConfirmNavigationTrigger = (state: RootState) => state.template.present.confirmNavigationTrigger
export const getSaveAndPostTemplateTrigger = (state: RootState) => state.template.present.saveAndPostTemplateTrigger
export const getSaveAndRedirectToPreview = (state: RootState) => state.template.present.saveAndRedirectToPreview
export const getTemplateIsFetching = (state: RootState) => state.template.present.isFetching
export const getTemplateErrors = (state: RootState) => state.template.present.templateErrors

export const useTemplate = () => useAppSelector(getTemplate)
export const useTemplateActivePage = () => useAppSelector(getTemplateActivePage)
export const useTemplateHistoryPast = () => useAppSelector(getTemplateHistoryPast)
export const useTemplateHistoryFuture = () => useAppSelector(getTemplateHistoryFuture)
export const useConfirmNavigationTrigger = () => useAppSelector(getConfirmNavigationTrigger)
export const useSaveAndPostTemplateTrigger = () => useAppSelector(getSaveAndPostTemplateTrigger)
export const useSaveAndRedirectToPreview = () => useAppSelector(getSaveAndRedirectToPreview)
export const useTemplateIsFetching = () => useAppSelector(getTemplateIsFetching)
export const useTemplateErrors = () => useAppSelector(getTemplateErrors)

export const {
  reducer: templateReducer,
  actions: {
    setTemplateStore,
    setTemplateBlocks,
    setTemplateBlock,
    setTemplatePages,
    addTemplatePage,
    setTemplateActivePage,
    clearTemplate,
    newTemplate,
    duplicateTemplatePage,
    deleteTemplatePage,
    setTemplateIsFetching,
    saveAndPostTemplate,
    saveAndPreviewTemplate,
    setTemplateErrors,
    setConfirmNavigation,
  },
} = templateSlice
