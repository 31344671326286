import { CircularProgress, Fade } from '@mui/material'
import React, { FC } from 'react'

import styles from './table-spinner.module.scss'

interface TableSpinnerProps {
  show: boolean
}

export const TableSpinner: FC<TableSpinnerProps> = ({ show }) => (
  <Fade
    className={styles.tableSpinner}
    in={show}
    style={{
      transitionDelay: show ? '100ms' : '0ms',
    }}
    unmountOnExit
  >
    <CircularProgress size={32} />
  </Fade>
)
