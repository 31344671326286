import React, { FC } from 'react'

import { CanvasLayoutBlocks } from '~components/Canvas/components/canvas-layout-blocks'
import { CanvasPagination } from '~components/Canvas/components/canvas-pagination'
import { ILayoutDynamicBlocks } from '~models/layout-blocks.model'
import { ILayoutBlocksGroup, ILayoutCssStyles } from '~models/layouts.model'
import { ITemplate } from '~models/template.model'

import styles from '../canvas.module.scss'

interface CanvasFooterProps {
  template?: ITemplate
  footerBlocks: ILayoutBlocksGroup[]
  footerCssStyles: ILayoutCssStyles
  layoutDynamicBlocks?: ILayoutDynamicBlocks
  preview?: boolean
  paginationHidden?: boolean
  activePageIndex?: number
}

export const CanvasFooter: FC<CanvasFooterProps> = ({
  template,
  footerBlocks,
  footerCssStyles,
  layoutDynamicBlocks,
  preview,
  paginationHidden,
  activePageIndex,
}) => (
  <footer className={styles.panelFooter} style={footerCssStyles}>
    <div className={styles.panelFooterHolder}>
      {footerBlocks.map((footerBlock) => {
        const { position, block } = footerBlock
        const rightPositionClass = position.col === 2 ? 'footer-right' : ''

        return (
          <div
            className={`${styles.panelBlock} ${rightPositionClass} ${styles[block.componentName]} ${
              preview && styles.panelBlockPreview
            }`}
            key={block.componentName}
          >
            <CanvasLayoutBlocks layoutBlock={footerBlock} layoutDynamicBlocks={layoutDynamicBlocks} preview={preview} />
          </div>
        )
      })}
      <CanvasPagination activePageIndex={activePageIndex} pages={template?.pages} paginationHidden={paginationHidden} />
    </div>
  </footer>
)
