import { Button, IconButton, MenuItem, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { MuiColorInput } from 'mui-color-input'
import React, { ChangeEvent, createElement, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { JustifyContent } from '~/enums/align-settings.enum'
import { LayoutPanels } from '~/enums/layout-panels.enum'
import { ColorPicker } from '~components/ColorPicker/color-picker'
import { InputGroup } from '~components/InputGroup/input-group'
import { ALIGN_ITEMS, JUSTIFY_CONTENT } from '~constants/constants'
import { getObjectKeys } from '~helpers/get-object-keys'
import { ILayoutCssStyles, keysOfCssStyles } from '~models/layouts.model'
import { ConfiguratorPopup } from '~pages/Configurator/components/configurator-popup'
import styles from '~pages/Configurator/configurator.module.scss'
import { useAppDispatch } from '~stores/hooks'
import {
  headerDisplaySettingsDefault,
  setLayoutActiveToolsPanel,
  setLayoutFooterCssStyles,
  setLayoutHeaderCssStyles,
  useLayoutFooterCssStyles,
  useLayoutHeaderCssStyles,
} from '~stores/slices/layout.slice'

interface ConfiguratorToolsProps {
  handleTemporaryCssStyles: (cssStyles: ILayoutCssStyles, panel: LayoutPanels | null) => void
  temporaryCssStyles: ILayoutCssStyles
  layoutActiveLayoutToolsPanel: LayoutPanels
}

export const ConfiguratorTools: FC<ConfiguratorToolsProps> = ({
  handleTemporaryCssStyles,
  temporaryCssStyles,
  layoutActiveLayoutToolsPanel,
}) => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()

  const headerCssStyles = useLayoutHeaderCssStyles()
  const footerCssStyles = useLayoutFooterCssStyles()
  const [cssStyles, setCssStyles] = useState<ILayoutCssStyles>()

  useEffect(() => {
    if (layoutActiveLayoutToolsPanel === LayoutPanels.header) {
      setCssStyles(headerCssStyles)
    }

    if (layoutActiveLayoutToolsPanel === LayoutPanels.footer) {
      setCssStyles(footerCssStyles)
    }
  }, [footerCssStyles, headerCssStyles, layoutActiveLayoutToolsPanel])

  const handleChangeSettings = useCallback(
    (newValue: string, fieldName: string) => {
      handleTemporaryCssStyles(
        {
          ...temporaryCssStyles,
          [fieldName]: newValue,
        },
        layoutActiveLayoutToolsPanel,
      )
    },
    [temporaryCssStyles],
  )

  const handleResetSettings = useCallback(
    (fieldName: keysOfCssStyles) => {
      handleTemporaryCssStyles(
        {
          ...temporaryCssStyles,
          [fieldName]: headerDisplaySettingsDefault[fieldName],
        },
        layoutActiveLayoutToolsPanel,
      )
    },
    [temporaryCssStyles],
  )

  const handleOpen = useCallback(
    (val: boolean) => {
      if (!val) {
        if (cssStyles) {
          handleTemporaryCssStyles(cssStyles, layoutActiveLayoutToolsPanel)
        }

        dispatch(setLayoutActiveToolsPanel(null))
      }
    },
    [cssStyles, dispatch, handleTemporaryCssStyles, layoutActiveLayoutToolsPanel],
  )

  const handleSaveSettings = useCallback(() => {
    if (layoutActiveLayoutToolsPanel === LayoutPanels.header) {
      dispatch(setLayoutHeaderCssStyles(temporaryCssStyles))
    }

    if (layoutActiveLayoutToolsPanel === LayoutPanels.footer) {
      dispatch(setLayoutFooterCssStyles(temporaryCssStyles))
    }

    handleOpen(false)
  }, [temporaryCssStyles])

  if (!cssStyles) {
    return null
  }

  return (
    <ConfiguratorPopup handleOpen={handleOpen} open title={translate(`${layoutActiveLayoutToolsPanel}-settings`)}>
      <InputGroup label={translate('font-color')}>
        <ColorPicker
          defaultChecked={temporaryCssStyles.color}
          name="color"
          onChange={(e) => handleChangeSettings(e.target.value, 'color')}
        />
        {/* <MuiColorInput */}
        {/*   InputProps={{ */}
        {/*     endAdornment: ( */}
        {/*       <IconButton */}
        {/*         onClick={() => handleResetSettings('color')} */}
        {/*         size="small" */}
        {/*         sx={{ */}
        {/*           display: temporaryCssStyles.color === headerDisplaySettingsDefault.color ? 'none' : '', */}
        {/*         }} */}
        {/*       > */}
        {/*         <Clear fontSize="small" /> */}
        {/*       </IconButton> */}
        {/*     ), */}
        {/*   }} */}
        {/*   format="hex" */}
        {/*   fullWidth */}
        {/*   isAlphaHidden */}
        {/*   onChange={(e) => handleChangeSettings(e, 'color')} */}
        {/*   value={temporaryCssStyles.color} */}
        {/*   variant="standard" */}
        {/* /> */}
      </InputGroup>
      <InputGroup label={translate('background-color')}>
        <ColorPicker
          defaultChecked={temporaryCssStyles.backgroundColor}
          name="backgroundColor"
          onChange={(e) => handleChangeSettings(e.target.value, 'backgroundColor')}
        />
        {/* <MuiColorInput */}
        {/*   InputProps={{ */}
        {/*     endAdornment: ( */}
        {/*       <IconButton */}
        {/*         onClick={() => handleResetSettings('backgroundColor')} */}
        {/*         size="small" */}
        {/*         sx={{ */}
        {/*           display: */}
        {/*             temporaryCssStyles.backgroundColor
         === headerDisplaySettingsDefault.backgroundColor ? 'none' : '', */}
        {/*         }} */}
        {/*       > */}
        {/*         <Clear fontSize="small" /> */}
        {/*       </IconButton> */}
        {/*     ), */}
        {/*   }} */}
        {/*   format="hex8" */}
        {/*   fullWidth */}
        {/*   onChange={(e) => handleChangeSettings(e, 'backgroundColor')} */}
        {/*   value={temporaryCssStyles.backgroundColor} */}
        {/*   variant="standard" */}
        {/* /> */}
      </InputGroup>
      {cssStyles.justifyContent && (
        <InputGroup label={translate('alignment')}>
          {/* <Select */}
          {/*   MenuProps={{ className: 'text-capitalize' }} */}
          {/*   className="text-capitalize" */}
          {/*   fullWidth */}
          {/*   onChange={(e) => handleChangeSettings(e, 'justifyContent')} */}
          {/*   value={temporaryCssStyles.justifyContent} */}
          {/*   variant="standard" */}
          {/* > */}
          {/*   {getObjectKeys(JustifyContent).map((key) => ( */}
          {/*     <MenuItem key={key} value={JustifyContent[key]}> */}
          {/*       {JUSTIFY_CONTENT[key]} */}
          {/*     </MenuItem> */}
          {/*   ))} */}
          {/* </Select> */}
          <ToggleButtonGroup
            aria-label="text alignment"
            exclusive
            onChange={(e, value) => handleChangeSettings(value, 'justifyContent')}
            size="small"
            value={temporaryCssStyles.justifyContent}
          >
            {getObjectKeys(JustifyContent).map((key) => (
              <ToggleButton key={key} title={JUSTIFY_CONTENT[key].title} value={JustifyContent[key]}>
                {createElement(JUSTIFY_CONTENT[key].icon)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </InputGroup>
      )}
      <div className={styles.configuratorPopupFooter}>
        <Button onClick={() => handleOpen(false)}>{translate('cancel')}</Button>
        <Button onClick={handleSaveSettings}>{translate('save')}</Button>
      </div>
    </ConfiguratorPopup>
  )
}
