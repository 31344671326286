import { Box, Button, MenuItem } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import React, { FC, MouseEvent, MouseEventHandler, useMemo } from 'react'
import { NavigateOptions } from 'react-router/lib/hooks'

import { TemplatePreviewPopup } from '~components/Modal/TemplatePreviewPopup/template-preview-popup'
import { PREVIEW_URLS } from '~constants/constants'
import { IActionItem } from '~models/menu-actions.model'
import { useAppDispatch } from '~stores/hooks'
import { showPreviewPopup } from '~stores/slices/preview.slice'

interface PreviewButtonProps {
  selectedItem: IActionItem
  text?: string
  tag?: typeof Button | typeof MenuItem
  handleClick?: (event: MouseEvent<HTMLElement>) => void
  onClick?: MouseEventHandler | undefined
  variant?: 'text' | 'outlined' | 'contained'
  size?: 'small' | 'medium' | 'large'
  sx?: SxProps<Theme>
  navigateOptions?: NavigateOptions
}

export const PreviewButton: FC<PreviewButtonProps> = ({
  selectedItem,
  text = 'Preview',
  tag = MenuItem,
  handleClick,
  navigateOptions,
  ...other
}) => {
  const dispatch = useAppDispatch()
  const pageUrl = useMemo(() => PREVIEW_URLS[selectedItem.templateType], [selectedItem.templateType])
  const templateIdUrl = useMemo(
    () => (selectedItem.templateId ? `/${selectedItem.templateId}` : ''),
    [selectedItem.templateId],
  )

  const handleOpenPopup = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    dispatch(
      showPreviewPopup(
        <TemplatePreviewPopup
          navigateOptions={navigateOptions}
          redirectUrl={`${pageUrl}${templateIdUrl}`}
          selectedItem={selectedItem}
        />,
      ),
    )

    if (handleClick) {
      handleClick(event)
    }
  }

  return (
    <Box component={tag} onClick={(event: MouseEvent<HTMLElement>) => handleOpenPopup(event)} {...other}>
      {text}
    </Box>
  )
}
