import React, { useEffect, useState } from 'react'

import { TemplateBlocksView as view, TemplateComponentName } from '~/enums/template-blocks.enum'
import { Spinner } from '~components/Spinner/spinner'
import { TemplateAddBlock } from '~components/TemplateAddBlock/template-add-block'
import { TemplateBlockComponent } from '~components/TemplateBlockComponent/template-block-component'
import { ITemplateBlockModel } from '~models/template-block.model'
import { useGetTransitTemplateBlocksQuery } from '~stores/services/template.api'

import styles from './template-aside-blocks.module.scss'

const disabledBlocks: TemplateComponentName[] = [TemplateComponentName.connection, TemplateComponentName.message]

export const TemplateAsideBlocks = () => {
  const [templateBlocks, setTemplateBlocks] = useState<ITemplateBlockModel[]>([])
  const { data: templateBlocksData, isLoading } = useGetTransitTemplateBlocksQuery()

  useEffect(() => {
    if (!isLoading && templateBlocksData?.result) {
      setTemplateBlocks(
        [...templateBlocksData.result]
          .filter(({ componentName }) => !disabledBlocks.includes(componentName))
          .sort((a, b) => (a.componentName > b.componentName ? -1 : a.componentName < b.componentName ? 1 : 0)),
      )
    }
  }, [isLoading, templateBlocksData?.result])

  return (
    <div className={styles.templateAside}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={`blocks-scroll-bar ${styles.scrollBlock}`}>
            {templateBlocks.map((block) => (
              <TemplateAddBlock block={block} key={block.id}>
                <TemplateBlockComponent
                  componentName={block.componentName}
                  properties={block.properties}
                  view={view.pure}
                />
              </TemplateAddBlock>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
