import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { PageUrls } from '~/enums/page-urls.enum'
import { AuthWrapper } from '~/wrappers/AuthWrapper'
import { Layout } from '~components/Layout/layout'
import { BusStop } from '~pages/BusStop/bus-stop'
import { BusStopList } from '~pages/BusStop/bus-stop-list'
import { Configurator } from '~pages/Configurator/configurator'
import { DefaultTemplate } from '~pages/DefaultTemplate/default-template'
import { EmergencyTemplate } from '~pages/EmergencyTemplate/emergency-template'
import { EmergencyTemplateList } from '~pages/EmergencyTemplate/emergency-template-list'
import { NotFound } from '~pages/NotFound/not-found'
import { PreviewPage } from '~pages/PreviewPage/preview-page'
import { PreviewTemplate } from '~pages/PreviewTemplate/preview-template'
import { Settings } from '~pages/Settings/settings'
import { TransitTemplate } from '~pages/TransitTemplate/transit-template'
import { TransitTemplateList } from '~pages/TransitTemplate/transit-template-list'

export const AppRouter = () => (
  <Routes>
    <Route element={<AuthWrapper />}>
      <Route element={<Layout />} path="/">
        <Route element={<Configurator />} path={PageUrls.BannerConfigurator} />
        <Route element={<TransitTemplateList />} path={PageUrls.Templates} />
        <Route element={<EmergencyTemplateList />} path={PageUrls.EmergencyTemplates} />
        <Route element={<BusStopList />} path={PageUrls.BusStops} />
        <Route element={<Settings />} path={PageUrls.Settings} />
        <Route element={<NotFound />} path="*" />
      </Route>
      <Route element={<TransitTemplateList />} path="signin-oidc" />
      <Route element={<EmergencyTemplate />} path={PageUrls.EmergencyTemplateCreate} />
      <Route element={<EmergencyTemplate />} path={`${PageUrls.EmergencyTemplate}/:templateId`} />
      <Route element={<TransitTemplate />} path={PageUrls.TemplateCreate} />
      <Route element={<TransitTemplate />} path={`${PageUrls.Template}/:templateId`} />
      <Route element={<BusStop />} path={`${PageUrls.BusStop}/:stopId`} />
      <Route element={<DefaultTemplate />} path={`${PageUrls.DefaultTemplate}`} />
      <Route
        element={<PreviewTemplate templateType="Transit" />}
        path={`${PageUrls.PreviewTemplate}/:templateId/:busStopId`}
      />
      <Route
        element={<PreviewTemplate templateType="Emergency" />}
        path={`${PageUrls.PreviewEmergencyTemplate}/:templateId/:busStopId`}
      />
      <Route
        element={<PreviewTemplate templateType="Default" />}
        path={`${PageUrls.PreviewDefaultTemplate}/:busStopId`}
      />
    </Route>
    <Route element={<PreviewPage />} path={`${PageUrls.PreviewPage}/:templateId`} />
  </Routes>
)
