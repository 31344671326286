import { isNaN } from 'lodash'

import { ROWS_PER_PAGE as rowsPerPage } from '~constants/constants'

export const getLocalStoragePageSize = () => {
  const pageSize = localStorage.getItem('pageSize')

  if (pageSize !== null) {
    const number = parseInt(pageSize, 10)

    return !isNaN(number) ? number : rowsPerPage[0]
  }

  return rowsPerPage[0]
}

export const setLocalStoragePageSize = (pageSize: number) => localStorage.setItem('pageSize', pageSize.toString())
