import { Colors } from '~/enums/colors.enum'

export enum BusStopTemplateTableCells {
  id = 'id',
  agencyId = 'agencyId',
  code = 'code',
  name = 'name',
  pages = 'busStopPagesCount',
  templates = 'templates',
}

export enum BusStopStatus {
  Deployed = 'Deployed',
  InDeployment = 'InDeployment',
  Error = 'Error',
}

export type BusStopStatusKeys = keyof typeof BusStopStatus

export interface IBusStopStatusesModel {
  color: string
  name: BusStopStatus
}

export type IBusStopStatuses = {
  [key in BusStopStatusKeys]: IBusStopStatusesModel
}

export const BusStopStatuses: IBusStopStatuses = {
  InDeployment: {
    color: Colors.info,
    name: BusStopStatus.InDeployment,
  },
  Deployed: {
    color: Colors.success,
    name: BusStopStatus.Deployed,
  },
  Error: {
    color: Colors.danger,
    name: BusStopStatus.Error,
  },
}
