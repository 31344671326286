import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useMount } from '~/hooks/use-mount'
import { CloseModal } from '~components/Modal/components/close-modal'
import { useAppDispatch } from '~stores/hooks'
import { hideConfirmationPopup, useIsOpenConfirmationPopup } from '~stores/slices/confirmation.slice'

interface NotificationPopupProps {
  title: string
  message: string
}

export const NotificationPopup: FC<NotificationPopupProps> = ({ title, message }) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const isOpen = useIsOpenConfirmationPopup()

  const { mounted } = useMount({ opened: isOpen, confirmationPopup: true })

  const handleClose = useCallback(() => {
    dispatch(hideConfirmationPopup())
  }, [dispatch])

  if (!mounted) {
    return null
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={isOpen}>
      <DialogTitle>
        {title}
        <CloseModal handleClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          {translate('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
