import { skipToken } from '@reduxjs/toolkit/query'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useCanvasProperties } from '~/hooks/use-canvas-properties'
import { CanvasFooter } from '~components/Canvas/components/canvas-footer'
import { CanvasHeader } from '~components/Canvas/components/canvas-header'
import styles from '~components/PreviewCanvas/preview-canvas.module.scss'
import { PreviewCanvasPage } from '~components/PreviewCanvas/preview-canvas-page'
import { Spinner } from '~components/Spinner/spinner'
import { orderRouteIndexes } from '~helpers/order-route-indexes'
import { ILayout } from '~models/layouts.model'
import { ITemplate } from '~models/template.model'
import { useGetLayoutsQuery } from '~stores/services/layouts.api'
import { useGetTransitTemplateQuery } from '~stores/services/template.api'

export const PreviewPage = () => {
  const [layout, setLayout] = useState<ILayout>()
  const [template, setTemplate] = useState<ITemplate>()

  const params = useParams()
  const templateId = Number(params.templateId)

  const { data: templateData, isLoading: templateDataIsLoading } = useGetTransitTemplateQuery(
    templateId ? { templateId } : skipToken,
  )

  const { data: layoutData, isLoading: layoutsDataIsLoading } = useGetLayoutsQuery()

  useEffect(() => {
    if (layoutData?.result && !layoutsDataIsLoading) {
      setLayout({ ...layoutData.result })
    }
  }, [layoutData?.result, layoutsDataIsLoading])

  useEffect(() => {
    if (templateData?.result && !templateDataIsLoading) {
      const preparedPages = orderRouteIndexes(templateData.result)

      setTemplate({ ...templateData.result, pages: [...preparedPages] })
    }
  }, [templateData?.result, templateDataIsLoading])

  const { canvasRowHeight, canvasColWidth } = useCanvasProperties({
    containerWidth: 1600,
    headerPanelDisabled: !layout?.header.isActive,
    footerPanelDisabled: !layout?.footer.isActive,
    offsetY: 0,
  })

  if (layoutsDataIsLoading || templateDataIsLoading) {
    return <Spinner />
  }

  if (!layout || !template) {
    return null
  }

  return (
    <div className={styles.container}>
      {layout.header.isActive && (
        <CanvasHeader headerBlocks={layout.header.blocks} headerCssStyles={layout.header.cssStyles} preview />
      )}
      {template.pages.map((page) => (
        <PreviewCanvasPage
          canvasColWidth={canvasColWidth}
          canvasRowHeight={canvasRowHeight}
          key={page.id}
          page={page}
          renderView
        />
      ))}
      {layout.footer.isActive ? (
        <CanvasFooter
          footerBlocks={layout.footer.blocks}
          footerCssStyles={layout.footer.cssStyles}
          paginationHidden
          preview
          template={template}
        />
      ) : (
        <div className="canvas_panelFooter" style={{ display: 'none' }} />
      )}
    </div>
  )
}
