import { useMsal } from '@azure/msal-react'
import { Logout, Settings } from '@mui/icons-material'
import { Avatar, Button, Divider, Link, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { PageUrls } from '~/enums/page-urls.enum'
import { DropdownMenu } from '~components/DropdownMenu/dropdown-menu'
import { LanguageSwitcher } from '~components/LanguageSwitcher/language-switcher'
import logo from '~images/logo.png'
import { ISidebarNavItem } from '~models/sidebar-nav-item.model'
import { useAppDispatch } from '~stores/hooks'
import { clearPersist, useUser } from '~stores/slices/auth.slice'

import styles from './header.module.scss'

const isInitials = false

export const Header = () => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { instance } = useMsal()
  const user = useUser()

  const [dropClose, setDropClose] = useState(false)

  const mainNav: ISidebarNavItem[] = [
    {
      label: translate('banner-configuration'),
      to: PageUrls.BannerConfigurator,
    },
    {
      label: translate('transit-templates'),
      to: PageUrls.Templates,
    },
    {
      label: translate('emergency-templates'),
      to: PageUrls.EmergencyTemplates,
    },
    {
      label: translate('default-template'),
      to: PageUrls.DefaultTemplate,
    },
    {
      label: translate('bus-stops'),
      to: PageUrls.BusStops,
    },
  ]

  const handleSignOut = async () => {
    await dispatch(clearPersist())
    instance.logoutRedirect().then()
  }

  const handleDrop = useCallback((val: boolean) => {
    setDropClose(val)
  }, [])

  const getInitials = (name: string) => name.split(' ')[0][0] + name.split(' ')[1][0]

  return (
    <header className={styles.header}>
      <Link className={styles.logo} component={NavLink} to="/">
        <img alt="BusPas" src={logo} />
      </Link>
      <nav className={styles.nav}>
        {mainNav.map(({ label, to, disabled }) => (
          <NavLink data-disabled={disabled} key={`link-${label}`} to={to}>
            {label}
          </NavLink>
        ))}
      </nav>
      <div>
        <LanguageSwitcher />
      </div>
      <DropdownMenu
        button={
          <Avatar sx={{ bgcolor: green[500], width: 40, height: 40, ml: 2, cursor: 'pointer' }}>
            {isInitials ? getInitials(user.name) : null}
          </Avatar>
        }
        dropClose={dropClose}
        handleDrop={handleDrop}
        menuStyles={{
          '& .MuiPaper-root': {
            width: 250,
          },
        }}
      >
        <div className={styles.profileDrop}>
          <Avatar sx={{ bgcolor: green[500], width: 56, height: 56, m: '0 auto 0.75rem' }} />
          <Typography noWrap variant="subtitle1">
            {user.name}
          </Typography>
          <Typography color="text.secondary" sx={{ textTransform: 'capitalize' }} variant="body2">
            {user.role}
          </Typography>
          <Button color="inherit" disabled variant="outlined">
            {translate('Manage your account')}
          </Button>
        </div>
        <Divider sx={{ mb: 1 }} />
        <MenuItem component={NavLink} disabled onClick={() => handleDrop(true)} to="settings">
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </DropdownMenu>
    </header>
  )
}
