import { Close, ContentCopy, DragIndicator } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'react-native-uuid'

import { DisplaySizeKeys } from '~/enums/display-size'
import { TemplateBlocksView, TemplateComponentName } from '~/enums/template-blocks.enum'
import { useCanvasProperties } from '~/hooks/use-canvas-properties'
import { TemplateBlockComponent } from '~components/TemplateBlockComponent/template-block-component'
import { ITemplatePage } from '~models/template.model'
import { useAppDispatch } from '~stores/hooks'
import {
  deleteTemplatePage,
  duplicateTemplatePage,
  setTemplateActivePage,
  useTemplateActivePage,
} from '~stores/slices/template.slice'

import styles from './template-page-item.module.scss'

interface TemplatePageBlockProps {
  page: ITemplatePage
  onlyOnePage: boolean
  index: number
  view: TemplateBlocksView
  readOnly?: boolean
  error?: boolean
  displaySize?: DisplaySizeKeys
}

export const TemplatePageItem: FC<TemplatePageBlockProps> = ({
  page,
  onlyOnePage,
  index,
  view,
  readOnly,
  error,
  displaySize,
}) => {
  const templateActivePageId = useTemplateActivePage()
  const dispatch = useAppDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const { t: translate } = useTranslation()

  const handleChangePage = useCallback(() => {
    dispatch(setTemplateActivePage(page.id))
  }, [page.id])

  const handleDelete = useCallback(() => {
    dispatch(deleteTemplatePage(page.id))
  }, [page.id])

  const handleDuplicate = useCallback(() => {
    const duplicateBlocks = page.blocks.map((block) => ({
      ...block,
      id: uuid.v4().toString(),
    }))
    const copyPage = {
      ...page,
      id: uuid.v4().toString(),
      blocks: duplicateBlocks,
    }

    dispatch(duplicateTemplatePage({ duplicatedPageId: page.id, page: copyPage }))

    setTimeout(() => {
      ref.current?.nextElementSibling?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }, 100)
  }, [page])

  const { canvasRowHeight, canvasColWidth, canvasHeight, canvasPadding, canvasFontSize, canvasGutter } =
    useCanvasProperties({
      containerWidth: ref.current?.offsetWidth || 210,
      skipCssVariables: true,
      displaySize,
    })

  useEffect(() => {
    const element = document.documentElement.style

    element.setProperty('--thumbnail-padding', `${canvasPadding}px`)
    element.setProperty('--thumbnail-height', `${canvasHeight}px`)
    element.setProperty('--thumbnail-font-size', `${canvasFontSize}px`)
    element.setProperty('--thumbnail-gutter', `${canvasGutter}px`)
  }, [canvasFontSize, canvasGutter, canvasHeight, canvasPadding])

  return (
    <div className={styles.pageBlock} ref={ref}>
      <div className={`${styles.pageHolder} ${page.id === templateActivePageId ? styles.activeBlock : ''}`}>
        {!readOnly && (
          <div className={styles.pageHolderActions}>
            {onlyOnePage && (
              <IconButton className="handle" color="inherit" size="small" title={translate('sort')}>
                <DragIndicator />
              </IconButton>
            )}
            <IconButton
              color="inherit"
              onClick={handleDuplicate}
              size="small"
              sx={{ ml: 'auto' }}
              title={translate('duplicate')}
            >
              <ContentCopy />
            </IconButton>
            {onlyOnePage && (
              <IconButton color="inherit" onClick={handleDelete} size="small" title={translate('delete')}>
                <Close />
              </IconButton>
            )}
          </div>
        )}
        <div className={styles.thumbnailCanvas} onClick={handleChangePage}>
          {page.blocks.map(
            ({
              id,
              position,
              size,
              cssStyles,
              text,
              image,
              templateBlock: { componentName, properties },
              routeIndex,
            }) => (
              <div
                className={`${styles.thumbnailBlock} row-${properties.rows / 2} col-${properties.cols} ${
                  componentName === TemplateComponentName.image ? styles.thumbnailImageBlock : ''
                }`}
                key={id}
                style={{
                  top: canvasRowHeight * position.row,
                  left: canvasColWidth * position.col,
                  height: size?.rows ? size.rows * canvasRowHeight : canvasRowHeight * properties.rows,
                  width: size?.cols ? size.cols * canvasColWidth : canvasColWidth * properties.cols,
                }}
              >
                <TemplateBlockComponent
                  componentName={componentName}
                  cssStyles={cssStyles}
                  firstPage={index === 0}
                  image={image}
                  properties={properties}
                  routeIndex={routeIndex}
                  text={text}
                  thumbnailMode
                  view={view}
                />
              </div>
            ),
          )}
        </div>
      </div>
      <Typography align="center" variant="body2">
        Page {index + 1}
      </Typography>
    </div>
  )
}
