import React, { CSSProperties, FC } from 'react'

import { AlignItems, TextAlign } from '~/enums/align-settings.enum'
import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import { FakeEta1, FakeEta2, FakeMessage, FakeRoute } from '~components/TemplateBlockComponent/FakeData/fake-data'
import { WysiwygEditor } from '~components/WysiwygEditor/wysiwyg-editor'
import { IDefaultTemplateCssStyles } from '~models/template.model'

import styles from './template-frame.module.scss'

export interface TemplateFrameSimpleProps {
  text: string
  align?: 'left' | 'right' | 'center' | 'unset'
  style?: CSSProperties
  title?: string
  view?: TemplateBlocksView
  dataType?: string
  fontSize?: string | number
  alignItems?: AlignItems
  justifyContent?: string
  textAlign?: TextAlign
  id?: string
  thumbnailMode?: boolean
  backgroundColor?: string
  readOnly?: boolean
}

export const TemplateFrameSimple: FC<TemplateFrameSimpleProps> = ({
  text,
  align = 'center',
  style,
  title,
  view,
  dataType,
  fontSize,
  alignItems,
  justifyContent,
  textAlign,
  id,
  thumbnailMode,
  backgroundColor,
  readOnly,
  ...other
}) => {
  const frameStyles = {
    textAlign: align,
    justifyContent: align,
    ...style,
  }

  return (
    <>
      {view === TemplateBlocksView.pure ? (
        <div className={styles.simpleFramePure} style={frameStyles}>
          {title && <strong className={styles.simpleFrameTitle}>{title}</strong>}
          <div className={styles.simpleFrameContent}>{text}</div>
        </div>
      ) : (
        <div
          className={`${styles.simpleFrame} ${dataType === 'static-text' && styles.staticTextFrame}`}
          style={{ alignItems }}
        >
          {title && (
            <strong className={styles.simpleFrameTitle} data-title="destination">
              {view === TemplateBlocksView.fakeData ? title : null}
            </strong>
          )}
          <div className={styles.simpleFrameContent}>
            {view === TemplateBlocksView.fakeData ? (
              <>
                {dataType === 'route' ? (
                  <FakeRoute />
                ) : dataType === 'eta1' ? (
                  <FakeEta1 />
                ) : dataType === 'eta2' ? (
                  <FakeEta2 />
                ) : dataType === 'message' ? (
                  <FakeMessage />
                ) : dataType === 'static-text' ? (
                  <>
                    {thumbnailMode || readOnly ? (
                      // eslint-disable-next-line react/no-danger
                      <div className={styles.staticText} dangerouslySetInnerHTML={{ __html: text }} />
                    ) : (
                      <WysiwygEditor
                        alignItems={alignItems}
                        backgroundColor={backgroundColor}
                        blockId={id}
                        text={text}
                      />
                    )}
                  </>
                ) : (
                  <strong className={styles.staticText}>{text}</strong>
                )}
              </>
            ) : (
              <>
                {dataType === 'route' ? (
                  <strong className="route-text" data-name="route" {...other} />
                ) : dataType === 'eta1' ? (
                  <strong className="time-text" data-name="eta" {...other} />
                ) : dataType === 'eta2' ? (
                  <strong className="time-text" data-name="eta" {...other} />
                ) : dataType === 'message' ? (
                  <FakeMessage />
                ) : dataType === 'static-text' ? (
                  // eslint-disable-next-line react/no-danger
                  <div className={styles.staticText} dangerouslySetInnerHTML={{ __html: text }} />
                ) : (
                  <strong className={styles.staticText}>{text}</strong>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}
