import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMount } from '~/hooks/use-mount'
import { CloseModal } from '~components/Modal/components/close-modal'
import { PageSpinner } from '~components/PageSpinner/page-spinner'
import { TemplateNameInput } from '~components/TemplateNameInput/template-name-input'
import { prepareTemplateForCopy } from '~helpers/prepare-template-for-copy'
import { IErrorRequest, IErrorRequestData } from '~models/error-request.model'
import { useAppDispatch } from '~stores/hooks'
import { useLazyGetTransitTemplateQuery, usePostTransitTemplateMutation } from '~stores/services/template.api'
import { useAgencyId } from '~stores/slices/auth.slice'
import { hideConfirmationPopup, useIsOpenConfirmationPopup } from '~stores/slices/confirmation.slice'

interface NotificationPopupProps {
  templateId: number
  name: string
}

export const CopyTemplatePopup: FC<NotificationPopupProps> = ({ templateId, name }) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const agencyId = useAgencyId()

  const [templateName, setTemplateName] = useState(`Copy of ${name}`)
  const isOpen = useIsOpenConfirmationPopup()
  const [errorsData, setErrorsData] = useState<IErrorRequestData | null>(null)
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { mounted } = useMount({ opened: isOpen, confirmationPopup: true })

  const [lazyGetTemplate] = useLazyGetTransitTemplateQuery()
  const [createTemplate] = usePostTransitTemplateMutation()

  const onChangeName = useCallback((value: string) => {
    setTemplateName(value)
  }, [])

  const handleClose = useCallback(() => {
    dispatch(hideConfirmationPopup())
  }, [dispatch])

  const handleCopyTemplate = useCallback(async () => {
    setIsLoading(true)
    const { data: copyTemplate } = await lazyGetTemplate({ templateId })

    if (copyTemplate) {
      try {
        await createTemplate({
          ...prepareTemplateForCopy(copyTemplate.result),
          name: templateName.trim(),
          agencyId,
        }).unwrap()

        handleClose()
        enqueueSnackbar(translate('template-copied'))
      } catch (err) {
        const error = err as IErrorRequest

        console.error(error)
        setErrorsData(error.data)
      } finally {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }, [templateId, templateName])

  const hasErrorCallBack = useCallback((value: boolean) => {
    setHasError(value)
  }, [])

  if (!mounted) {
    return null
  }

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={isOpen}
    >
      <DialogTitle id="alert-dialog-title">
        {translate('copy-template')}
        <CloseModal handleClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <TemplateNameInput
          autoFocus
          errorsData={errorsData}
          fullWidth
          getTemplateName={onChangeName}
          hasErrorCallBack={hasErrorCallBack}
          label={translate('template-name')}
          name="name"
          value={templateName}
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button disabled={hasError} onClick={handleCopyTemplate}>
          {translate('copy')}
        </Button>
      </DialogActions>
      <PageSpinner position="absolute" show={isLoading} />
    </Dialog>
  )
}
