import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { useAccount, useMsal } from '@azure/msal-react'
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { loginRequest } from '~/authConfig'
import { Spinner } from '~components/Spinner/spinner'
import { useAppDispatch } from '~stores/hooks'
import { setToken, setUser, useAccessToken } from '~stores/slices/auth.slice'

export const AuthWrapper = () => {
  const dispatch = useAppDispatch()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const accessToken = useAccessToken()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (account && !accessToken) {
      const getAccountData = async () => {
        try {
          setIsLoading(true)
          const tokenResponse = await instance.acquireTokenSilent(loginRequest)

          dispatch(setToken(tokenResponse.accessToken))

          const { username, name = '', tenantId, idTokenClaims } = account

          const {
            city = '',
            country = '',
            extension_SystemRole = '',
            extension_OrganizationId = '',
            emails,
          }: any = idTokenClaims

          dispatch(
            setUser({
              agencyId: Number(extension_OrganizationId),
              name,
              userName: username,
              tenantId,
              email: emails[0],
              city,
              country,
              role: extension_SystemRole,
            }),
          )
        } catch (error) {
          console.error(error)
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(loginRequest)
          }
        } finally {
          setIsLoading(false)
        }
      }

      getAccountData().then()
    }
  }, [accessToken, account, dispatch, instance])

  if (inProgress !== InteractionStatus.None || isLoading) {
    return <Spinner />
  }

  if (inProgress === InteractionStatus.None && !account) {
    instance.loginRedirect(loginRequest).then()

    return null
  }

  return (
    <>
      <Outlet />
    </>
  )
}
