import { ChevronLeft, ChevronRight, Close, ControlPoint, Settings } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LayoutBlockNames } from '~/enums/layout-block-names.enum'
import { LayoutPanels } from '~/enums/layout-panels.enum'
import { CanvasPagination } from '~components/Canvas/components/canvas-pagination'
import { TemplateLogo } from '~components/TemplateLogo/template-logo'
import { updateFooterBlocksOrder } from '~helpers/update-footer-blocks-order'
import { ILayoutBlocksGroup, ILayoutCssStyles } from '~models/layouts.model'
import { useAppDispatch } from '~stores/hooks'
import {
  setLayoutActiveBlockSettings,
  setLayoutActiveBlocksPanel,
  setLayoutActiveToolsPanel,
  setLayoutFooterBlocks,
  useLayoutActiveBlockListPanel,
  useLayoutActiveToolsPanel,
  useLayoutFieldErrors,
  useLayoutFooterCssStyles,
} from '~stores/slices/layout.slice'

import styles from '../configurator.module.scss'

interface ConfiguratorFooterProps {
  footerBlocks: ILayoutBlocksGroup[]
  handleBlock: (id: number, panel: LayoutPanels | null) => void
  temporaryCssStyles: ILayoutCssStyles
}

export const ConfiguratorFooter: FC<ConfiguratorFooterProps> = ({ footerBlocks, handleBlock, temporaryCssStyles }) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()

  const layoutFieldErrors = useLayoutFieldErrors()
  const footerCssStyles = useLayoutFooterCssStyles()
  const layoutActiveLayoutToolsPanel = useLayoutActiveToolsPanel()
  const layoutActiveBlockListPanel = useLayoutActiveBlockListPanel()
  const [modalOpen, setModalOpen] = useState(false)

  const handleSort = useCallback(
    (id: string, direction: string) => {
      dispatch(setLayoutFooterBlocks(updateFooterBlocksOrder(footerBlocks, id, direction)))
    },
    [footerBlocks],
  )

  const handleConfigToolsPopup = useCallback((panel: LayoutPanels) => {
    dispatch(setLayoutActiveToolsPanel(panel))
  }, [])

  const handleConfigBlockListPopup = useCallback((panel: LayoutPanels) => {
    dispatch(setLayoutActiveBlocksPanel(panel))
  }, [])

  const handleBlockSettings = useCallback((blockGroup: ILayoutBlocksGroup) => {
    dispatch(setLayoutActiveBlockSettings(blockGroup))
  }, [])

  const handleModal = useCallback((value: boolean) => {
    setModalOpen(value)
  }, [])

  const getBlockError = useCallback(
    (componentName: LayoutBlockNames) => {
      if (layoutFieldErrors) {
        return layoutFieldErrors.some((error) => componentName.includes(error))
      }
    },
    [layoutFieldErrors],
  )

  return (
    <footer
      className={styles.panelFooter}
      style={layoutActiveLayoutToolsPanel === LayoutPanels.footer ? temporaryCssStyles : footerCssStyles}
    >
      <div className={styles.panelFooterHolder}>
        {footerBlocks.map((blockGroup, index) => {
          const {
            id,
            layoutBlockId,
            block,
            position,
            timeFormat,
            showDayOfTheWeek,
            customText,
            serviceEmail,
            servicePhone,
          } = blockGroup
          const rightDirection = index === 0 && position.col === 1
          const hasCustomSettings = timeFormat ?? showDayOfTheWeek ?? customText ?? serviceEmail ?? servicePhone
          const isLogo = block.componentName === LayoutBlockNames.logo

          return (
            <div
              className={`${styles.panelBlock}  ${isLogo ? styles.LayoutLogoBlock : ''} ${
                position.col === 2 ? 'footer-right' : ''
              }`}
              key={id}
            >
              <div className={styles.panelBlockActions}>
                <IconButton
                  color="inherit"
                  onClick={() => handleSort(id, rightDirection ? 'right' : 'left')}
                  size="small"
                  title={translate('sort')}
                >
                  {rightDirection ? <ChevronRight /> : <ChevronLeft />}
                </IconButton>
                {hasCustomSettings !== undefined && (
                  <IconButton
                    color="inherit"
                    onClick={() => handleBlockSettings(blockGroup)}
                    size="small"
                    title={translate('settings')}
                  >
                    <Settings />
                  </IconButton>
                )}
                {isLogo && (
                  <IconButton
                    color="inherit"
                    onClick={() => setModalOpen(true)}
                    size="small"
                    title={translate('settings')}
                  >
                    <Settings />
                  </IconButton>
                )}
                <IconButton
                  color="inherit"
                  onClick={() => handleBlock(layoutBlockId, LayoutPanels.footer)}
                  size="small"
                  title={translate('remove')}
                >
                  <Close />
                </IconButton>
              </div>
              {getBlockError(block.componentName) && <span className={styles.errorMark} />}
              {isLogo ? <TemplateLogo handleModalOpen={handleModal} modalOpen={modalOpen} /> : <>{block.name}</>}
            </div>
          )
        })}
        <CanvasPagination />
      </div>
      <div
        className={`${styles.panelActions} ${
          layoutActiveLayoutToolsPanel || layoutActiveBlockListPanel ? styles.hide : ''
        }`}
      >
        <IconButton color="inherit" onClick={() => handleConfigBlockListPopup(LayoutPanels.footer)}>
          <ControlPoint />
        </IconButton>
        <IconButton color="inherit" onClick={() => handleConfigToolsPopup(LayoutPanels.footer)}>
          <Settings />
        </IconButton>
      </div>
    </footer>
  )
}
