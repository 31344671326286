import { Redo, Undo } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { ActionCreators } from 'redux-undo'

import { useAppDispatch } from '~stores/hooks'
import { useTemplateHistoryFuture, useTemplateHistoryPast } from '~stores/slices/template.slice'

export const TemplateUndo = () => {
  const dispatch = useAppDispatch()
  const historyPast = useTemplateHistoryPast()
  const historyFuture = useTemplateHistoryFuture()

  return (
    <>
      <IconButton disabled={!historyPast.length} onClick={() => dispatch(ActionCreators.undo())}>
        <Undo />
      </IconButton>
      <IconButton disabled={!historyFuture.length} onClick={() => dispatch(ActionCreators.redo())}>
        <Redo />
      </IconButton>
    </>
  )
}
