import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { JustifyContent } from '~/enums/align-settings.enum'
import { LayoutPanels } from '~/enums/layout-panels.enum'
import { IErrorRequestData } from '~models/error-request.model'
import { ILayout, ILayoutBlocksGroup, ILayoutCssStyles } from '~models/layouts.model'
import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

export const headerDisplaySettingsDefault: ILayoutCssStyles = {
  color: '#ffffff',
  backgroundColor: '#000000',
  justifyContent: JustifyContent.flexStart,
}

interface ILayoutSlice {
  data: ILayout
  activeLayoutBlockListPanel: LayoutPanels | null
  activeLayoutToolsPanel: LayoutPanels | null
  activeLayoutBlockSettings: ILayoutBlocksGroup | null
  layoutFieldErrors: string[] | null
}

const initialState: ILayoutSlice = {
  data: {
    backgroundColor: '#d8d8d8',
    [LayoutPanels.header]: {
      maxBlocks: 4,
      isActive: true,
      cssStyles: {
        color: '#ffffff',
        backgroundColor: '#000000',
        justifyContent: JustifyContent.flexStart,
      },
      blocks: [],
    },
    [LayoutPanels.footer]: {
      maxBlocks: 3,
      isActive: true,
      cssStyles: {
        color: '#ffffff',
        backgroundColor: '#000000',
      },
      blocks: [],
    },
  },
  activeLayoutBlockListPanel: null,
  activeLayoutToolsPanel: null,
  activeLayoutBlockSettings: null,
  layoutFieldErrors: null,
}

const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    setLayoutStore: (state, { payload }: PayloadAction<ILayout>) => {
      state.data = payload
    },
    setLayoutHeaderBlocks: (state, { payload }: PayloadAction<ILayoutBlocksGroup[]>) => {
      state.data.header.blocks = payload
    },
    setLayoutFooterBlocks: (state, { payload }: PayloadAction<ILayoutBlocksGroup[]>) => {
      state.data.footer.blocks = payload
    },
    setLayoutHeaderIsActive: (state, { payload }: PayloadAction<boolean>) => {
      state.data.header.isActive = payload
    },
    setLayoutFooterIsActive: (state, { payload }: PayloadAction<boolean>) => {
      state.data.footer.isActive = payload
    },
    setLayoutHeaderCssStyles: (state, { payload }: PayloadAction<ILayoutCssStyles>) => {
      state.data.header.cssStyles = payload
    },
    setLayoutFooterCssStyles: (state, { payload }: PayloadAction<ILayoutCssStyles>) => {
      state.data.footer.cssStyles = payload
    },
    setLayoutActiveBlocksPanel: (state, { payload }: PayloadAction<LayoutPanels | null>) => {
      state.activeLayoutBlockSettings = null
      state.activeLayoutToolsPanel = null
      state.activeLayoutBlockListPanel = payload
    },
    setLayoutActiveToolsPanel: (state, { payload }: PayloadAction<LayoutPanels | null>) => {
      state.activeLayoutBlockSettings = null
      state.activeLayoutBlockListPanel = null
      state.activeLayoutToolsPanel = payload
    },
    setLayoutActiveBlockSettings: (state, { payload }: PayloadAction<ILayoutBlocksGroup | null>) => {
      state.activeLayoutBlockListPanel = null
      state.activeLayoutToolsPanel = null
      state.activeLayoutBlockSettings = payload
    },
    setLayoutBlockSettings: (state, { payload }: PayloadAction<ILayoutBlocksGroup>) => {
      const headerBlockIndex = state.data.header.blocks.findIndex((block) => block.id === payload.id)

      if (headerBlockIndex !== -1) {
        state.data.header.blocks[headerBlockIndex] = payload

        return
      }

      const footerBlockIndex = state.data.footer.blocks.findIndex((block) => block.id === payload.id)

      if (footerBlockIndex !== -1) {
        state.data.footer.blocks[footerBlockIndex] = payload
      }
    },
    setLayoutFieldErrors: (state, { payload }: PayloadAction<IErrorRequestData | null>) => {
      if (payload?.errors) {
        state.layoutFieldErrors = Object.keys(payload.errors).map((error) => error.slice(error.lastIndexOf('.') + 1))

        return
      }

      state.layoutFieldErrors = null
    },
    removeLayoutFieldErrors: (state, { payload }: PayloadAction<string>) => {
      if (state.layoutFieldErrors) {
        state.layoutFieldErrors = state.layoutFieldErrors.filter((error) => !payload.includes(error))
      }
    },
  },
})

export const getLayout = (state: RootState) => state.layout.data
export const getLayoutHeaderIsActive = (state: RootState) => state.layout.data.header.isActive
export const getLayoutFooterIsActive = (state: RootState) => state.layout.data.footer.isActive
export const getLayoutHeaderBlocks = (state: RootState) => state.layout.data.header.blocks
export const getLayoutFooterBlocks = (state: RootState) => state.layout.data.footer.blocks
export const getLayoutHeaderCssStyles = (state: RootState) => state.layout.data.header.cssStyles
export const getLayoutFooterCssStyles = (state: RootState) => state.layout.data.footer.cssStyles
export const getLayoutActiveBlockListPanel = (state: RootState) => state.layout.activeLayoutBlockListPanel
export const getLayoutActiveToolsPanel = (state: RootState) => state.layout.activeLayoutToolsPanel
export const getLayoutActiveBlockSettings = (state: RootState) => state.layout.activeLayoutBlockSettings
export const getLayoutFieldErrors = (state: RootState) => state.layout.layoutFieldErrors

export const useLayout = () => useAppSelector(getLayout)
export const useLayoutHeaderIsActive = () => useAppSelector(getLayoutHeaderIsActive)
export const useLayoutFooterIsActive = () => useAppSelector(getLayoutFooterIsActive)
export const useLayoutHeaderBlocks = () => useAppSelector(getLayoutHeaderBlocks)
export const useLayoutFooterBlocks = () => useAppSelector(getLayoutFooterBlocks)
export const useLayoutHeaderCssStyles = () => useAppSelector(getLayoutHeaderCssStyles)
export const useLayoutFooterCssStyles = () => useAppSelector(getLayoutFooterCssStyles)
export const useLayoutActiveBlockListPanel = () => useAppSelector(getLayoutActiveBlockListPanel)
export const useLayoutActiveToolsPanel = () => useAppSelector(getLayoutActiveToolsPanel)
export const useLayoutActiveBlockSettings = () => useAppSelector(getLayoutActiveBlockSettings)
export const useLayoutFieldErrors = () => useAppSelector(getLayoutFieldErrors)

export const {
  reducer: layoutReducer,
  actions: {
    setLayoutStore,
    setLayoutHeaderBlocks,
    setLayoutFooterBlocks,
    setLayoutHeaderIsActive,
    setLayoutFooterIsActive,
    setLayoutHeaderCssStyles,
    setLayoutFooterCssStyles,
    setLayoutActiveBlocksPanel,
    setLayoutActiveToolsPanel,
    setLayoutBlockSettings,
    setLayoutActiveBlockSettings,
    setLayoutFieldErrors,
    removeLayoutFieldErrors,
  },
} = layoutSlice
