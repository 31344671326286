import { FormControlLabel, MenuItem, Select, SelectChangeEvent, Switch } from '@mui/material'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InputGroup } from '~components/InputGroup/input-group'
import { TimeFormats } from '~constants/constants'
import { ILayoutBlocksGroup, keysOfTimeFormats } from '~models/layouts.model'

interface ConfigurationTimeBlockProps {
  blockGroup: ILayoutBlocksGroup
  handleBlockGroup: (blockGroup: ILayoutBlocksGroup) => void
}

const TimeFormatKeys = Object.keys(TimeFormats) as keysOfTimeFormats[]

export const ConfigurationTimeBlock: FC<ConfigurationTimeBlockProps> = ({ blockGroup, handleBlockGroup }) => {
  const { t: translate } = useTranslation()
  const [timeFormat, setTimeFormat] = useState<keysOfTimeFormats>(blockGroup.timeFormat || '12h')
  const [showDayOfTheWeek, setShowDayOfTheWeek] = useState<boolean>(Boolean(blockGroup.showDayOfTheWeek))

  useEffect(() => {
    setTimeFormat(blockGroup.timeFormat || '12h')
    setShowDayOfTheWeek(Boolean(blockGroup.showDayOfTheWeek))
  }, [blockGroup])

  useEffect(() => {
    handleBlockGroup({
      ...blockGroup,
      timeFormat,
      showDayOfTheWeek,
    })
  }, [blockGroup, timeFormat, showDayOfTheWeek])

  const handleTimeFormat = (event: SelectChangeEvent) => {
    setTimeFormat(event.target.value as keysOfTimeFormats)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowDayOfTheWeek(event.target.checked)
  }

  return (
    <>
      <InputGroup label={translate('time-format')}>
        <Select
          MenuProps={{ className: 'text-capitalize' }}
          className="text-capitalize"
          fullWidth
          onChange={(e) => handleTimeFormat(e)}
          value={timeFormat}
          variant="standard"
        >
          {TimeFormatKeys.map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </InputGroup>
      <FormControlLabel
        control={<Switch checked={showDayOfTheWeek} onChange={handleChange} />}
        label={translate('show-day-of-the-week')}
      />
    </>
  )
}
