import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BusStopStatus, BusStopStatuses, BusStopStatusKeys } from '~/enums/bus-stop-templates.enum'
import { DeploymentTemplate } from '~/enums/deployment-template.enum'
import { useMount } from '~/hooks/use-mount'
import { EmptyTableRow } from '~components/EmptyTableRow/empty-table-row'
import { CloseModal } from '~components/Modal/components/close-modal'
import { Status } from '~components/Status/status'
import { TablePagination } from '~components/TablePagination/table-pagination'
import { TableSearch } from '~components/TableSearch/table-search'
import { ROWS_PER_PAGE as rowsPerPage } from '~constants/constants'
import { showByFormat } from '~helpers/date-helper'
import { getObjectKeys } from '~helpers/get-object-keys'
import { sortByFields } from '~helpers/sort-by-fields'
import { splitByCapitalLetter } from '~helpers/split-by-capital-letter'
import { IBusTemplateStopsModel, ITemplateBusStopsTableCells, ITemplatePublishDetails } from '~models/template.model'

const headCells: ITemplateBusStopsTableCells[] = [
  {
    id: DeploymentTemplate.stopCode,
    label: 'stop-code',
    sortable: true,
    width: 200,
  },
  {
    id: DeploymentTemplate.stopName,
    label: 'stop-name',
    sortable: true,
  },
  {
    id: DeploymentTemplate.status,
    label: 'template-status',
    sortable: true,
    width: 220,
  },
]

interface IDeploymentBusStops {
  busStops: IBusTemplateStopsModel[][]
  totalCount: number
  totalPages: number
}

interface EmergencyTemplatePublishPopupProps {
  isOpen: boolean
  handleClose: () => void
  publishDetails: ITemplatePublishDetails
}

export const DeploymentDetailsPopup: FC<EmergencyTemplatePublishPopupProps> = ({
  isOpen,
  handleClose,
  publishDetails: { busStops, updatedBusStopsCount, totalBusStopsCount },
}) => {
  const { t: translate } = useTranslation()

  const [order, setOrder] = useState<'asc' | 'desc' | undefined>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState<number>(rowsPerPage[0])
  const [searchRequest, setSearchRequest] = useState<string>('')
  const [sortBy, setSortBy] = useState<DeploymentTemplate>(DeploymentTemplate.stopCode)
  const [isSortAscending, setIsSortAscending] = useState<boolean>(true)

  const [busStopsWithPagination, setBusStopsWithPagination] = useState<IDeploymentBusStops>()

  const { mounted } = useMount({ opened: isOpen, previewPopup: true })

  const resetPageNumber = () => setPageNumber(1)

  useEffect(() => {
    if (isSortAscending) {
      setOrder('asc')
    } else {
      setOrder('desc')
    }

    resetPageNumber()
  }, [isSortAscending])

  const handleSearch = useCallback((value: string) => {
    setSearchRequest(value)
    resetPageNumber()
  }, [])

  const [activeTab, setActiveTab] = useState<BusStopStatusKeys[]>([])

  const handleChangeTab = (event: SyntheticEvent, value: BusStopStatusKeys[]) => {
    setActiveTab(value)
    resetPageNumber()
  }

  useEffect(() => {
    if (!busStops) {
      return
    }

    const sortedBusStops = sortByFields([...busStops], isSortAscending, sortBy)

    const sortedAndFilteredBusStops = sortedBusStops
      .filter(
        ({ name, code }) =>
          name.toLowerCase().includes(searchRequest.toLowerCase()) ||
          code.toLowerCase().includes(searchRequest.toLowerCase()),
      )
      .filter(({ status }) => (activeTab.length ? activeTab.includes(status) : true))

    const groupedPerPageBusStops: IBusTemplateStopsModel[][] = []

    for (let i = 0; i < sortedAndFilteredBusStops.length; i += pageSize) {
      groupedPerPageBusStops.push(sortedAndFilteredBusStops.slice(i, i + pageSize))
    }

    setBusStopsWithPagination({
      busStops: [...groupedPerPageBusStops],
      totalPages: Math.ceil(sortedAndFilteredBusStops.length / pageSize),
      totalCount: sortedAndFilteredBusStops.length,
    })
  }, [busStops, searchRequest, activeTab, pageSize, isSortAscending, sortBy])

  const handleSort = useCallback(
    (id: DeploymentTemplate) => {
      if (id === sortBy) {
        setIsSortAscending(!isSortAscending)
      } else {
        setIsSortAscending(true)
      }

      setSortBy(id)
    },
    [isSortAscending, sortBy],
  )

  if (!mounted || !busStopsWithPagination) {
    return null
  }

  return (
    <Dialog className="modal-top" fullWidth maxWidth="md" onClose={handleClose} open={isOpen}>
      <DialogTitle variant="h5">
        {translate('deployment-progress')} ({updatedBusStopsCount} {translate('of')} {totalBusStopsCount})
        <CloseModal handleClose={handleClose} />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid2 alignItems="center" container spacing={2} sx={{ mb: 1 }}>
          <Grid2 xs>
            <TableSearch handleSearch={handleSearch} placeholder={translate('stop-name-or-code')} />
          </Grid2>
          <Grid2>
            <ToggleButtonGroup
              className="tabs"
              color="primary"
              onChange={handleChangeTab}
              size="small"
              value={activeTab}
            >
              {getObjectKeys(BusStopStatus).map((key) => (
                <ToggleButton key={key} value={key}>
                  {translate(splitByCapitalLetter(BusStopStatus[key]))}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid2>
        </Grid2>
        <TableContainer>
          <Table className="table-md" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {headCells.map(({ id, align, width, label, sortable, style }) => (
                  <TableCell align={align} key={id} sx={style} width={width}>
                    <TableSortLabel
                      active={Boolean(busStopsWithPagination) && sortable && sortBy === id}
                      direction={sortBy === id ? order : 'asc'}
                      disabled={!busStopsWithPagination || !sortable}
                      hideSortIcon={!sortable}
                      onClick={() => handleSort(id)}
                    >
                      {translate(label)}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {busStopsWithPagination.totalCount ? (
              <TableBody>
                {busStopsWithPagination.busStops[pageNumber - 1].map((busStopItem) => {
                  const { id, code, name, status, lastModified } = busStopItem

                  return (
                    <TableRow key={id}>
                      <TableCell>{code}</TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        <Status
                          secondary={
                            lastModified && (
                              <Typography sx={{ letterSpacing: 0 }} variant="caption">
                                {showByFormat(lastModified, 'MMM D, YYYY')} at {showByFormat(lastModified, 'h:mm A')}
                              </Typography>
                            )
                          }
                          status={BusStopStatuses[status]}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            ) : null}
          </Table>
          {!busStopsWithPagination.totalCount && (
            <EmptyTableRow>
              {searchRequest ? translate('no-results-matching') : translate('stops-not-found')}
            </EmptyTableRow>
          )}
        </TableContainer>
        <TablePagination
          handlePageIndex={setPageNumber}
          handlePageSize={setPageSize}
          isFetching={false}
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalCount={busStopsWithPagination.totalCount}
          totalPages={busStopsWithPagination.totalPages}
        />
      </DialogContent>
    </Dialog>
  )
}
