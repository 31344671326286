import { ITemplatePageBlocks } from '~models/template.model'

export const sortPageBlocksByRouteIndex = (blocks: ITemplatePageBlocks[]) =>
  [...blocks].sort((a, b) => {
    if (a.routeIndex && b.routeIndex) {
      return a.routeIndex > b.routeIndex ? 1 : -1
    }

    return 0
  })
