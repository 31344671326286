export enum LayoutBlockNames {
  stopName = 'LayoutStopNameBlock',
  stopCode = 'LayoutStopCodeBlock',
  website = 'LayoutWebsiteBlock',
  customText = 'LayoutCustomTextBlock',
  logo = 'LayoutLogoBlock',
  time = 'LayoutTimeBlock',
  date = 'LayoutDateBlock',
  serviceEmail = 'LayoutServiceEmailBlock',
  servicePhone = 'LayoutServicePhoneBlock',
}
