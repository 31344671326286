import { Event } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BusStopsTableCells } from '~/enums/bus-stops.enum'
import { BusStopsTable } from '~components/BusStopsTable/bus-stops-table'
import { CloseModal } from '~components/Modal/components/close-modal'
import { ConfirmationPopup } from '~components/Modal/ConfirmationPopup/confirmation-popup'
import { NotificationPopup } from '~components/Modal/NotificationPopup/notification-popup'
import { PageSpinner } from '~components/PageSpinner/page-spinner'
import { TablePagination } from '~components/TablePagination/table-pagination'
import { TableSearch } from '~components/TableSearch/table-search'
import { ROWS_PER_PAGE as rowsPerPage } from '~constants/constants'
import { getErrorsValues } from '~helpers/get-errors-values'
import { IBusStopModel, IBusStopTableCells } from '~models/bus-stop.model'
import { IErrorRequest } from '~models/error-request.model'
import { IActionItem } from '~models/menu-actions.model'
import { IPagination } from '~models/pagination.model'
import { IPublishDetailsRequest } from '~models/template.model'
import { useAppDispatch } from '~stores/hooks'
import { useGetBusStopListQuery } from '~stores/services/bus-stops.api'
import { usePostEmergencyTemplatePublishMutation } from '~stores/services/template.api'
import { showConfirmationPopup } from '~stores/slices/confirmation.slice'

const headCells: IBusStopTableCells[] = [
  {
    id: BusStopsTableCells.stopCode,
    label: 'stop-code',
    sortable: true,
    width: 200,
  },
  {
    id: BusStopsTableCells.stopName,
    label: 'stop-name',
    sortable: true,
  },
]

interface EmergencyTemplatePublishPopupProps {
  templateId: number
  selectedItem: IActionItem
  isOpen: boolean
  handleClose: () => void
  handlePublishDetails: (publishDetails: IPublishDetailsRequest) => void
}

export const EmergencyTemplatePublishPopup: FC<EmergencyTemplatePublishPopupProps> = ({
  templateId,
  selectedItem,
  isOpen,
  handleClose,
  handlePublishDetails,
}) => {
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState<number>(rowsPerPage[0])
  const [searchRequest, setSearchRequest] = useState<string>('')
  const [sortBy, setSortBy] = useState<string>(BusStopsTableCells.stopCode)
  const [isSortAscending, setIsSortAscending] = useState<boolean>(true)

  const [busStops, setBusStops] = useState<IBusStopModel[]>()
  const [pagination, setPagination] = useState<IPagination>()

  const [dateError, setDateError] = useState(false)

  const [selectedBusStops, setSelectedBusStops] = useState<number[]>([])

  const [activeUntil, setActiveUntil] = useState<Dayjs | null>(dayjs().add(1, 'hour'))
  const [activeUntilEnable, setActiveUntilEnable] = useState<boolean>(false)

  const {
    data: busStopsData,
    isLoading,
    isFetching,
  } = useGetBusStopListQuery({
    PageNumber: pageNumber,
    PageSize: pageSize,
    ...(searchRequest && { Name: searchRequest, NameFo: 'Contains' }),
    [sortBy]: isSortAscending ? 'asc' : 'desc',
  })

  const [publishEmergencyTemplate, { isLoading: publishEmergencyTemplateIsLoading }] =
    usePostEmergencyTemplatePublishMutation()

  const handlePublish = async () => {
    if (selectedItem.hasChanges) {
      const publishDetails = {
        ...(activeUntilEnable && { activeUntil }),
        busStops: selectedBusStops,
      }

      dispatch(showConfirmationPopup(<ConfirmationPopup publishDetails={publishDetails} selectedItem={selectedItem} />))

      handlePublishDetails(publishDetails)

      return
    }

    try {
      await publishEmergencyTemplate({
        templateId,
        publishDetails: { ...(activeUntilEnable && { activeUntil }), busStops: selectedBusStops },
      }).unwrap()

      enqueueSnackbar(translate('template-published'))
      handleClose()
    } catch (err) {
      const error = err as IErrorRequest

      console.error(error)

      dispatch(
        showConfirmationPopup(
          <NotificationPopup message={getErrorsValues(error.data).join(' ')} title={translate('failed-to-publish')} />,
        ),
      )
    }
  }

  const handleActiveUntilEnable = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveUntilEnable(event.target.checked)
  }

  useEffect(() => {
    if (!isLoading && busStopsData) {
      setBusStops([...busStopsData.result])
      setPagination({ ...busStopsData.pagination })
    }
  }, [isLoading, busStopsData])

  const handleSearch = useCallback((value: string) => {
    setSearchRequest(value)
    setPageNumber(1)
  }, [])

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} open={isOpen}>
      <DialogTitle align="center" variant="h5">
        {translate('select-bus-stops')}
        <CloseModal handleClose={handleClose} />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid2 alignItems="center" container spacing={2} sx={{ mb: 1 }}>
          <Grid2 xs>
            <Typography variant="subtitle1">
              {translate('stops')} {pagination?.TotalCount && `(${pagination.TotalCount})`}
            </Typography>
          </Grid2>
          <Grid2>
            <TableSearch handleSearch={handleSearch} placeholder={translate('stop-name')} />
          </Grid2>
        </Grid2>
        <BusStopsTable
          busStops={busStops}
          handleBusStops={setSelectedBusStops}
          handleIsSortAscending={setIsSortAscending}
          handleSortBy={setSortBy}
          headCells={headCells}
          isFetching={isFetching}
          isLoading={isLoading}
          searchRequest={searchRequest}
        />
        <Grid2 alignItems="center" container sx={{ my: 2 }}>
          <Grid2>
            <Typography variant="body2">
              <>
                {selectedBusStops.length} {translate('selected')}
              </>
            </Typography>
          </Grid2>
          <Grid2 xs>
            {pagination && (
              <TablePagination
                handlePageIndex={setPageNumber}
                handlePageSize={setPageSize}
                isFetching={isFetching}
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalCount={pagination.TotalCount}
                totalPages={pagination.TotalPages}
              />
            )}
          </Grid2>
        </Grid2>
        <div className="timer-control-group">
          <FormControlLabel
            control={
              <Switch checked={activeUntilEnable} name="header" onChange={handleActiveUntilEnable} size="small" />
            }
            label={translate('deactivate-this-template-on')}
            sx={{ ml: '-7px' }}
          />
          <div className="timer-control-holder">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                disabled={!activeUntilEnable}
                inputFormat="YYYY-MM-DD hh:mm A"
                minDateTime={dayjs().add(5, 'minute')}
                onChange={(newValue) => {
                  setActiveUntil(newValue)

                  if (newValue && newValue.subtract(4, 'minute').unix() < dayjs().unix()) {
                    setDateError(true)
                  } else {
                    setDateError(false)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      endAdornment: <Event />,
                    }}
                    error={dateError && activeUntilEnable}
                    helperText={translate('timer-control-help-text')}
                    variant="standard"
                  />
                )}
                value={activeUntil}
              />
            </LocalizationProvider>
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ px: 3, pt: 0, pb: 3 }}>
        <Button color="inherit" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button
          disabled={(dateError && activeUntilEnable) || !selectedBusStops.length}
          onClick={handlePublish}
          variant="contained"
        >
          {translate('publish')}
        </Button>
      </DialogActions>
      <PageSpinner show={publishEmergencyTemplateIsLoading} />
    </Dialog>
  )
}
