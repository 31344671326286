import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AlignItems, TextAlign } from '~/enums/align-settings.enum'
import { ColorPicker } from '~components/ColorPicker/color-picker'
import { InputGroup } from '~components/InputGroup/input-group'
import { ALIGN_ITEMS } from '~constants/constants'
import { getObjectKeys } from '~helpers/get-object-keys'
import { splitByCapitalLetter } from '~helpers/split-by-capital-letter'
import { cssStylesKeys, ISetTemplateStaticTextBlockSettings } from '~models/template.model'

interface TextBlockSettingsPopupProps {
  open: boolean
  handleClose: () => void
  handleSave: (blockSettings: ISetTemplateStaticTextBlockSettings) => void
  textBlockSettings: ISetTemplateStaticTextBlockSettings
}

export const TextBlockSettingsPopup: FC<TextBlockSettingsPopupProps> = ({
  open,
  handleClose,
  handleSave,
  textBlockSettings,
}) => {
  const { t: translate } = useTranslation()
  const [newBlockSettings, setNewBlockSettings] = useState<ISetTemplateStaticTextBlockSettings>(textBlockSettings)
  const [textError, setTextError] = useState<string | null>(null)

  const getFontSizeValues = (): string[] => {
    const fontSizeValues: string[] = []

    for (let i = 3; i <= 10; i += 0.5) {
      fontSizeValues.push(`${i}em`)
    }

    return fontSizeValues
  }

  const handleChangeSettings = (
    event: SelectChangeEvent | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: cssStylesKeys,
  ) => {
    setNewBlockSettings({
      ...newBlockSettings,
      cssStyles: {
        ...newBlockSettings.cssStyles,
        [field]: event.target.value,
      },
    })
  }

  const handleText = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value?.trim().length < 1 || event.target.value?.trim().length > 320) {
      setTextError(translate('custom-text-input-error-message'))
    } else {
      setTextError(null)
    }

    setNewBlockSettings((prevState) => {
      prevState.text = event.target.value

      return { ...prevState }
    })
  }

  const handleSaveSettings = useCallback(() => {
    handleSave({
      ...newBlockSettings,
      cssStyles: { ...newBlockSettings.cssStyles },
      text: newBlockSettings.text.trim(),
    })
    handleClose()
  }, [newBlockSettings])

  return (
    <Dialog fullWidth maxWidth="xs" open={open} scroll="body">
      <DialogTitle>Block Settings</DialogTitle>
      <DialogContent>
        {/* <InputGroup label={translate('font-size')}> */}
        {/*   <Select */}
        {/*     MenuProps={{ className: 'text-capitalize' }} */}
        {/*     className="text-capitalize" */}
        {/*     fullWidth */}
        {/*     onChange={(e) => handleChangeSettings(e, 'fontSize')} */}
        {/*     value={newBlockSettings.cssStyles.fontSize} */}
        {/*     variant="standard" */}
        {/*   > */}
        {/*     {getFontSizeValues().map((value) => ( */}
        {/*       <MenuItem key={value} value={value}> */}
        {/*         {splitByCapitalLetter(value, ' ')} */}
        {/*       </MenuItem> */}
        {/*     ))} */}
        {/*   </Select> */}
        {/* </InputGroup> */}
        {/* <InputGroup label={translate('font-color')}> */}
        {/*   <ColorPicker */}
        {/*     defaultChecked={newBlockSettings.cssStyles.color} */}
        {/*     name="color" */}
        {/*     onChange={(e) => handleChangeSettings(e, 'color')} */}
        {/*   /> */}
        {/* </InputGroup> */}
        <InputGroup label={translate('background-color')}>
          <ColorPicker
            defaultChecked={newBlockSettings.cssStyles.backgroundColor}
            name="backgroundColor"
            onChange={(e) => handleChangeSettings(e, 'backgroundColor')}
          />
        </InputGroup>
        <InputGroup label={translate('vertical-align')}>
          <Select
            MenuProps={{ className: 'text-capitalize' }}
            className="text-capitalize"
            fullWidth
            onChange={(e) => handleChangeSettings(e, 'alignItems')}
            value={newBlockSettings.cssStyles.alignItems}
            variant="standard"
          >
            {getObjectKeys(AlignItems).map((key) => (
              <MenuItem key={key} value={AlignItems[key]}>
                {ALIGN_ITEMS[key].title}
              </MenuItem>
            ))}
          </Select>
        </InputGroup>
        {/* <InputGroup label={translate('horizontal-align')}> */}
        {/*   <Select */}
        {/*     MenuProps={{ className: 'text-capitalize' }} */}
        {/*     className="text-capitalize" */}
        {/*     fullWidth */}
        {/*     onChange={(e) => handleChangeSettings(e, 'textAlign')} */}
        {/*     value={newBlockSettings.cssStyles.textAlign} */}
        {/*     variant="standard" */}
        {/*   > */}
        {/*     {getObjectKeys(TextAlign).map((key) => ( */}
        {/*       <MenuItem key={key} value={TextAlign[key]}> */}
        {/*         {TextAlign[key]} */}
        {/*       </MenuItem> */}
        {/*     ))} */}
        {/*   </Select> */}
        {/* </InputGroup> */}
        {/* <InputGroup label={translate('text')}> */}
        {/*   <TextField */}
        {/*     error={Boolean(textError)} */}
        {/*     fullWidth */}
        {/*     helperText={textError} */}
        {/*     multiline */}
        {/*     onChange={(e) => handleText(e)} */}
        {/*     rows={3} */}
        {/*     value={newBlockSettings.text} */}
        {/*     variant="standard" */}
        {/*   /> */}
        {/* </InputGroup> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={Boolean(textError)} onClick={handleSaveSettings}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
