import React, { FC, useEffect, useState } from 'react'

import { useCanvasProperties } from '~/hooks/use-canvas-properties'
import { useWindowSize } from '~/hooks/use-window-size'
import { CanvasFooter } from '~components/Canvas/components/canvas-footer'
import { CanvasHeader } from '~components/Canvas/components/canvas-header'
import { PreviewCanvasPage } from '~components/PreviewCanvas/preview-canvas-page'
import { Spinner } from '~components/Spinner/spinner'
import { ILayout } from '~models/layouts.model'
import { ITemplate } from '~models/template.model'
import { useAppDispatch } from '~stores/hooks'
import { useGetLayoutsQuery } from '~stores/services/layouts.api'
import { useLayoutDynamicBlocks } from '~stores/slices/layout-dynamic-blocks.slice'
import { setTemplateActivePage, useTemplateActivePage } from '~stores/slices/template.slice'

import styles from './preview-canvas.module.scss'

interface PreviewCanvasProps {
  template: ITemplate
  skipCssVariables?: boolean
}

export const PreviewCanvas: FC<PreviewCanvasProps> = ({ template, skipCssVariables }) => {
  const dispatch = useAppDispatch()
  const activePageId = useTemplateActivePage()
  const layoutDynamicBlocks = useLayoutDynamicBlocks()

  const [windowWidth, windowHeight] = useWindowSize()
  const [layout, setLayout] = useState<ILayout>()

  const { data: layoutData, isLoading: layoutsDataIsLoading } = useGetLayoutsQuery()

  useEffect(() => {
    if (layoutData?.result && !layoutsDataIsLoading) {
      setLayout({ ...layoutData.result })
    }
  }, [layoutData?.result, layoutsDataIsLoading])

  const { canvasRowHeight, canvasColWidth } = useCanvasProperties({
    windowWidth,
    windowHeight,
    headerPanelDisabled: !layout?.header.isActive,
    footerPanelDisabled: !layout?.footer.isActive,
    asideBar: 'one',
    skipCssVariables,
    displaySize: template.display,
  })

  useEffect(() => {
    dispatch(setTemplateActivePage(template.pages[0].id))
  }, [dispatch, template.pages])

  if (layoutsDataIsLoading) {
    return <Spinner />
  }

  if (!layout) {
    return null
  }

  return (
    <>
      <div className={styles.container}>
        {layout.header.isActive && (
          <CanvasHeader
            headerBlocks={layout.header.blocks}
            headerCssStyles={layout.header.cssStyles}
            layoutDynamicBlocks={layoutDynamicBlocks}
          />
        )}
        {template.pages.map((page) => (
          <PreviewCanvasPage
            activePageId={activePageId}
            canvasColWidth={canvasColWidth}
            canvasRowHeight={canvasRowHeight}
            key={page.id}
            page={page}
          />
        ))}
        {layout.footer.isActive ? (
          <CanvasFooter
            footerBlocks={layout.footer.blocks}
            footerCssStyles={layout.footer.cssStyles}
            layoutDynamicBlocks={layoutDynamicBlocks}
            template={template}
          />
        ) : (
          <div className="canvas_panelFooter" style={{ display: 'none' }} />
        )}
      </div>
    </>
  )
}
