import React, { FC } from 'react'

import metro from '~images/icons/metro.png'
import occupancyFree from '~images/icons/occupancy0.png'
import occupancyOne from '~images/icons/occupancy1.png'
import occupancyTwo from '~images/icons/occupancy2.png'
import occupancyThree from '~images/icons/occupancy3.png'
import train from '~images/icons/train.png'
import wheelchair from '~images/icons/wheelchair.png'

export const FakeRoute = () => <strong className="route-text">000</strong>

export const FakeEta1 = () => (
  <strong className="time-text">
    <span className="time-text__number">00</span>
    <span className="time-text__unit">mins</span>
  </strong>
)

export const FakeEta2 = () => (
  <strong className="time-text">
    <span className="time-text__number">00</span>
    <span className="time-text__unit">h</span>
    <span className="time-text__number">00</span>
  </strong>
)

export const FakeMessage = () => (
  <strong className="message-text">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad doloremque impedit laboriosam porro qui quis!
  </strong>
)

export const FakeWCIcon = () => (
  <strong className="icons-holder" data-icon="wheelchair">
    <img alt="WS" src={wheelchair} />
  </strong>
)

interface FakeOccupancyIconProps {
  busy: 'zero' | 'one' | 'two' | 'three'
}

export const FakeOccupancyFreeIcon: FC<FakeOccupancyIconProps> = ({ busy }) => {
  let src = occupancyFree

  if (busy === 'one') src = occupancyOne
  if (busy === 'two') src = occupancyTwo
  if (busy === 'three') src = occupancyThree

  return (
    <strong className="icons-holder" data-icon="passenger-load">
      <img alt="CT" src={src} />
    </strong>
  )
}

export const FakeTrainIcon = () => (
  <div className="icons-holder" data-icon="train">
    <img alt="CT" src={train} />
  </div>
)

export const FakeMetroIcon = () => (
  <div className="icons-holder" data-icon="metro">
    <img alt="CM" src={metro} />
  </div>
)
