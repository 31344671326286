export enum DisplaySize {
  ThirteenInchesEInk = 'ThirteenInchesEInk',
  TwentyEightInchesEInk = 'TwentyEightInchesEInk',
}

export type DisplaySizeKeys = keyof typeof DisplaySize

export type IDisplayConfig = {
  [key in DisplaySizeKeys]: {
    label: string
  }
}

export const DisplayConfig: IDisplayConfig = {
  ThirteenInchesEInk: {
    label: '13-display',
  },
  TwentyEightInchesEInk: {
    label: '28-display',
  },
}
