import { MenuItem } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { FC, MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TemplateType } from '~/enums/template-type.enum'
import { TemplateAction, TemplateActionsKeys, TemplateStatusesEnum } from '~/enums/templates.enum'
import { DropdownMenu } from '~components/DropdownMenu/dropdown-menu'
import { ConfirmationPopup } from '~components/Modal/ConfirmationPopup/confirmation-popup'
import { CopyTemplatePopup } from '~components/Modal/CopyTemplatePopup/copy-template-popup'
import { PreviewButton } from '~components/PreviewButton/preview-button'
import { getTemplateActionsByStatus } from '~helpers/get-template-actions-by-status'
import { useAppDispatch } from '~stores/hooks'
import { usePostTransitTemplateSetStatusDraftMutation } from '~stores/services/template.api'
import { showConfirmationPopup } from '~stores/slices/confirmation.slice'

interface TemplateActionsProps {
  templateId: number
  name: string
  status: TemplateStatusesEnum
  builderView?: boolean
  hasChanges?: boolean
  templateType: TemplateType
}

export const TemplateActions: FC<TemplateActionsProps> = ({
  templateId,
  name,
  status,
  builderView,
  hasChanges,
  templateType,
}) => {
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [dropClose, setDropClose] = useState(false)

  const [setStatusDraft] = usePostTransitTemplateSetStatusDraftMutation()

  const handleMenuActions = useCallback(
    (event: MouseEvent<HTMLElement>, action: TemplateActionsKeys) => {
      event.stopPropagation()
      setDropClose(true)

      if (action === 'delete' || action === 'publish') {
        dispatch(
          showConfirmationPopup(
            <ConfirmationPopup selectedItem={{ action, templateId, status, hasChanges, templateType }} />,
          ),
        )

        return
      }

      if (action === 'setDraft') {
        dispatch(
          showConfirmationPopup(
            <ConfirmationPopup selectedItem={{ action, templateId, status, hasChanges, templateType }} />,
          ),
        )

        return
      }

      if (action === 'copy') {
        dispatch(showConfirmationPopup(<CopyTemplatePopup name={name} templateId={templateId} />))
      }
    },
    [
      navigate,
      templateId,
      dispatch,
      status,
      hasChanges,
      templateType,
      setStatusDraft,
      enqueueSnackbar,
      translate,
      name,
    ],
  )

  return (
    <>
      <DropdownMenu dropClose={dropClose} handleDrop={setDropClose}>
        {getTemplateActionsByStatus(status).map((action) =>
          builderView && action === 'copy' ? null : action === 'preview' ? (
            <PreviewButton
              handleClick={() => setDropClose(true)}
              key={action}
              selectedItem={{
                hasChanges,
                status,
                templateId,
                templateType: TemplateType.Transit,
                action,
              }}
              tag={MenuItem}
            />
          ) : (
            <MenuItem key={action} onClick={(event) => handleMenuActions(event, action)}>
              {translate(TemplateAction[action])}
            </MenuItem>
          ),
        )}
      </DropdownMenu>
    </>
  )
}
