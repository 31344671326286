import { TemplateStatusesEnum as statuses } from '~/enums/templates.enum'
import {
  ARCHIVE_STATUS_ACTIONS,
  DEPLOYED_STATUS_ACTIONS,
  DRAFT_STATUS_ACTIONS,
  IN_DEPLOYMENT_STATUS_ACTIONS,
} from '~constants/constants'

export const getTemplateActionsByStatus = (status: statuses) => {
  if (status === statuses.Draft) return DRAFT_STATUS_ACTIONS
  if (status === statuses.Archived) return ARCHIVE_STATUS_ACTIONS
  if (status === statuses.Deployed) return DEPLOYED_STATUS_ACTIONS

  return IN_DEPLOYMENT_STATUS_ACTIONS
}
