import './assets/styles/styles.scss'

import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import React, { FC } from 'react'
import { useNavigate } from 'react-router'

import { AppRouter } from '~/app-router'
import { CustomNavigationClient } from '~/utils/NavigationClient'
import { NotificationsHolder } from '~components/Modal/NotificationsHolder/notifications-holder'

interface AppProps {
  pca: IPublicClientApplication
}

const App: FC<AppProps> = ({ pca }) => {
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)

  pca.setNavigationClient(navigationClient)

  return (
    <MsalProvider instance={pca}>
      <AppRouter />
      <NotificationsHolder />
    </MsalProvider>
  )
}

export default App
