import { isEqual } from 'lodash'

import { ITemplate } from '~models/template.model'

interface ITemplatesNotEqual {
  initialTemplate: ITemplate | null
  template: ITemplate
  templateName?: string
  templateDelay?: number
}

export const templatesNotEqual = ({ initialTemplate, template, templateName, templateDelay }: ITemplatesNotEqual) => {
  if (initialTemplate && template) {
    return (
      !isEqual(
        { ...initialTemplate, name: '', delay: '', status: '' },
        { ...template, name: '', delay: '', status: '' },
      ) ||
      (templateName !== undefined && initialTemplate?.name !== templateName) ||
      (templateDelay !== undefined && initialTemplate?.delay !== templateDelay)
    )
  }
}
