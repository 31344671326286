import { Box, Button, Typography } from '@mui/material'
import { skipToken } from '@reduxjs/toolkit/query'
import dayjs from 'dayjs'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'react-native-uuid'
import { NavLink, useLocation, useParams } from 'react-router-dom'

import { PageUrls } from '~/enums/page-urls.enum'
import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import { TemplateTypeKeys } from '~/enums/template-type.enum'
import { PageSpinner } from '~components/PageSpinner/page-spinner'
import { PreviewCanvas } from '~components/PreviewCanvas/preview-canvas'
import { Spinner } from '~components/Spinner/spinner'
import { TemplateCarousel } from '~components/TemplateCarousel/template-carousel'
import { DEFAULT_DISPLAY_SIZE } from '~constants/constants'
import { getTemplateType } from '~helpers/get-template-type'
import { orderRouteIndexes } from '~helpers/order-route-indexes'
import { renderTemplate } from '~helpers/render-template'
import { IBusServiceModel } from '~models/bus-service.model'
import { ILocationStateTemplate, ITemplate, ITemplatePageBlocks } from '~models/template.model'
import styles from '~pages/EmergencyTemplate/emergency-template.module.scss'
import { PreviewTemplateAsidePages } from '~pages/PreviewTemplate/components/preview-template-aside-pages'
import { PreviewTemplateTopPanel } from '~pages/PreviewTemplate/components/preview-template-top-panel'
import { useAppDispatch } from '~stores/hooks'
import { useLazyGetBusServiceQuery } from '~stores/services/bus-service.api'
import { useGetBusStopQuery } from '~stores/services/bus-stops.api'
import {
  useGetDefaultTemplateQuery,
  useGetEmergencyTemplateQuery,
  useGetTransitTemplateQuery,
} from '~stores/services/template.api'
import { setLayoutDynamicBlocks, setLayoutDynamicBlocksTrigger } from '~stores/slices/layout-dynamic-blocks.slice'
import { setTemplateActivePage } from '~stores/slices/template.slice'

const agency = {
  url: 'https://kpidigital.com/',
}

interface PreviewTemplateProps {
  templateType: TemplateTypeKeys
}

export const PreviewTemplate: FC<PreviewTemplateProps> = ({ templateType }) => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()
  const params = useParams()
  const templateId = Number(params.templateId)
  const busStopId = Number(params.busStopId)
  const location = useLocation()

  const [playOn, setPlayOn] = useState(false)
  const [initialTemplate, setInitialTemplate] = useState<ITemplate>()
  const [template, setTemplate] = useState<ITemplate>()
  const [layoutDynamicTrigger, setLayoutDynamicTrigger] = useState(false)

  const [totalBuses, setTotalBuses] = useState<number>(0)
  const [busStopService, setBusStopService] = useState<IBusServiceModel[]>()

  const { isTransitTemplate, isEmergencyTemplate, isDefaultTemplate } = useMemo(
    () => getTemplateType(templateType),
    [templateType],
  )

  const [isTransitTemplateLoading, setIsTransitTemplateLoading] = useState(Boolean(isTransitTemplate))

  const { displaySize } = (location.state as ILocationStateTemplate) || DEFAULT_DISPLAY_SIZE

  const { data: busStopData, isLoading: busStopDataIsLoading } = useGetBusStopQuery(
    busStopId ? { busStopId } : skipToken,
  )

  const [getBusServiceData, { isLoading: busServiceDataIsLoading, isFetching: busServiceDataIsFetching }] =
    useLazyGetBusServiceQuery()

  const { data: templateTransitData, isLoading: templateTransitIsLoading } = useGetTransitTemplateQuery(
    templateId && isTransitTemplate ? { templateId } : skipToken,
  )

  const { data: emergencyTemplateData, isLoading: emergencyTemplateIsLoading } = useGetEmergencyTemplateQuery(
    templateId && isEmergencyTemplate ? { templateId } : skipToken,
  )

  const { data: defaultTemplateData, isLoading: defaultTemplateIsLoading } = useGetDefaultTemplateQuery(
    isDefaultTemplate ? { display: displaySize } : skipToken,
  )

  const handleBusService = useCallback(async () => {
    if (busStopData && isTransitTemplate) {
      const busServiceData = await getBusServiceData({
        BusStopCode: busStopData.result.code,
        BusStopCodeFo: 'Equal',
        ServiceDate: dayjs().format('YYYY-MM-DD'),
        ServiceDateFo: 'Equal',
        StopArrivalTime: dayjs().format('HH:mm:ss'),
        StopArrivalTimeFo: 'GreaterThan',
        OrderByStopArrivalTime: 'asc',
      }).unwrap()

      const busNumbers: string[] = []

      busServiceData.result.forEach(({ routeShortName }) => {
        if (!busNumbers.includes(routeShortName)) {
          busNumbers.push(routeShortName)
        }
      })

      setTotalBuses(busNumbers.length)
      setBusStopService(busServiceData.result)

      setIsTransitTemplateLoading(false)

      setLayoutDynamicTrigger((prevState) => !prevState)

      await renderTemplate(busServiceData.result, busNumbers)
    }
  }, [busStopData, getBusServiceData, isTransitTemplate])

  useEffect(() => {
    dispatch(setLayoutDynamicBlocksTrigger(!layoutDynamicTrigger))
  }, [dispatch, layoutDynamicTrigger])

  useEffect(() => {
    if (busStopData) {
      dispatch(
        setLayoutDynamicBlocks({
          stopCode: busStopData.result.code,
          stopName: busStopData.result.name,
          website: agency.url,
        }),
      )
    }
  }, [busStopData, dispatch])

  useEffect(() => {
    if (templateTransitData?.result && !templateTransitIsLoading) {
      setInitialTemplate(templateTransitData.result)

      return
    }

    if (emergencyTemplateData?.result && !emergencyTemplateIsLoading) {
      setInitialTemplate(emergencyTemplateData.result)

      return
    }

    if (defaultTemplateData?.result && !defaultTemplateIsLoading) {
      setInitialTemplate(defaultTemplateData.result)
    }
  }, [
    defaultTemplateData?.result,
    defaultTemplateIsLoading,
    emergencyTemplateData?.result,
    emergencyTemplateIsLoading,
    templateTransitData?.result,
    templateTransitIsLoading,
  ])

  useEffect(() => {
    if (initialTemplate) {
      setTemplate(initialTemplate)
    }
  }, [initialTemplate])

  useEffect(() => {
    handleBusService().then()
  }, [playOn, handleBusService])

  const handleRefresh = useCallback(() => {
    handleBusService().then()

    if (template) {
      dispatch(setTemplateActivePage(template.pages[0].id))
      const scrollBar = document.querySelector('.blocks-scroll-bar')

      scrollBar?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [handleBusService, template, dispatch])

  const generateNewBlocks = (blocks: ITemplatePageBlocks[], pageIndex: number, totalRoutes: number) => {
    const newBlocks = blocks.map((block) => {
      if (!block.routeIndex) {
        return { ...block, id: uuid.v4().toString() }
      }

      const newRouteIndex = pageIndex * totalRoutes + block.routeIndex

      return {
        ...block,
        id: uuid.v4().toString(),
        routeIndex: newRouteIndex,
      }
    })

    return newBlocks.filter((block) => (!block.routeIndex ? true : block.routeIndex <= totalBuses))
  }

  useEffect(() => {
    if (initialTemplate && template && totalBuses) {
      const [transitPage] = initialTemplate.pages
      const transitBlocks = transitPage.blocks

      const totalRoutes = transitBlocks.filter(
        (block) => block.templateBlock.componentName === 'TemplateRouteBlock',
      ).length
      const transitPages = Math.ceil(totalBuses / totalRoutes)

      const newPages = orderRouteIndexes(initialTemplate)

      for (let i = 1; i < transitPages; i += 1) {
        newPages.splice(i, 0, {
          ...transitPage,
          blocks: generateNewBlocks(newPages[0].blocks, i, totalRoutes),
          id: uuid.v4().toString(),
        })
      }

      setTemplate((prevState) => {
        if (prevState) {
          return { ...prevState, pages: newPages }
        }
      })
    }
  }, [totalBuses])

  if (templateTransitIsLoading || busStopDataIsLoading || busServiceDataIsLoading || isTransitTemplateLoading) {
    return <Spinner />
  }

  if (!template) {
    return null
  }

  return (
    <div className={styles.templateContainer}>
      <PreviewTemplateTopPanel
        busStop={busStopData?.result}
        handlePlay={setPlayOn}
        handleRefresh={handleRefresh}
        template={template}
        templateType={templateType}
      />
      {isTransitTemplate && !busStopService?.length ? (
        <Box
          sx={{
            display: 'flex',
            flex: '1',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <div>
            <Typography fontWeight="bold" marginBottom={2} variant="h2">
              Oops!
            </Typography>
            <Typography marginBottom={4} variant="h4">
              {translate('bus-service-not-found')}
            </Typography>
            <Button
              component={NavLink}
              size="large"
              to={`${PageUrls.Template}/${template.templateId}`}
              variant="contained"
            >
              {translate('back-to-the-template')}
            </Button>
          </div>
        </Box>
      ) : (
        <>
          <div className={styles.templateContent}>
            <div className={styles.templateHolder}>
              {!playOn && <PreviewCanvas skipCssVariables={playOn} template={template} />}
            </div>
            <PreviewTemplateAsidePages readOnly template={template} view={TemplateBlocksView.realData} />
          </div>
          <PageSpinner show={busServiceDataIsFetching} />
          {playOn && <TemplateCarousel handleOpen={setPlayOn} template={template} />}
        </>
      )}
    </div>
  )
}
