import { FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query/react'

import { loginRequest } from '~/authConfig'
import { msalInstance } from '~/index'
import { BASE_API } from '~constants/constants'
import { prepareHeaders } from '~stores/helpers/prepare-headers'
import { setToken } from '~stores/slices/auth.slice'
import { RootState } from '~stores/store'

export const staggeredBaseQueryWithBailOut = (path: string, apiUrl?: string) =>
  retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const state = api.getState() as RootState
      const { agencyId } = state.auth.data.user

      if (typeof args !== 'string' && args?.params?.agencyId !== undefined) {
        args.params.agencyId = agencyId
      }

      const handleFetchBaseQuery = () =>
        fetchBaseQuery({
          prepareHeaders,
          credentials: 'include',
          baseUrl: `${apiUrl || BASE_API}/${path}`,
        })(args, api, extraOptions)

      let result = await handleFetchBaseQuery()

      if (result.error?.status === 401) {
        try {
          const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest)

          api.dispatch(setToken(tokenResponse.accessToken))

          result = await handleFetchBaseQuery()
        } catch (err) {
          console.error(err)
        }
      }

      return result
    },
    {
      maxRetries: 0,
    },
  )
