import React, { FC } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import {
  FakeEta1,
  FakeEta2,
  FakeMetroIcon,
  FakeOccupancyFreeIcon,
  FakeRoute,
  FakeTrainIcon,
  FakeWCIcon,
} from '~components/TemplateBlockComponent/FakeData/fake-data'

import styles from './template-frame.module.scss'

export interface TemplateFrameIconsProps {
  text: string
  iconTop: string
  iconBottom: string
  view?: TemplateBlocksView
  dataType?: string
  direction?: 'row' | 'column'
}

export const TemplateFrameIcons: FC<TemplateFrameIconsProps> = ({
  text,
  iconTop,
  iconBottom,
  view,
  dataType,
  direction = 'row',
  ...other
}) => {
  const directionClass = direction === 'column' ? styles.directionColumn : ''

  return (
    <>
      {view === TemplateBlocksView.pure ? (
        <div className={`${styles.iconsBoxPure} ${directionClass}`}>
          <div className={styles.iconsFrame}>{text}</div>
          <div className={styles.iconsHolder}>
            <div className={styles.iconsFrame}>{iconTop}</div>
            <div className={styles.iconsFrame}>{iconBottom}</div>
          </div>
        </div>
      ) : (
        <div className={`${styles.iconsBox} ${directionClass}`}>
          {view === TemplateBlocksView.fakeData ? (
            <>
              <div className={styles.iconsContent}>
                {dataType === 'route' ? (
                  <FakeRoute />
                ) : dataType === 'eta1' ? (
                  <FakeEta1 />
                ) : dataType === 'eta2' ? (
                  <FakeEta2 />
                ) : (
                  ''
                )}
              </div>
              <div className={styles.iconsHolder}>
                {dataType === 'route' ? (
                  <>
                    <FakeTrainIcon />
                    <FakeMetroIcon />
                  </>
                ) : dataType === 'eta1' ? (
                  <>
                    <FakeWCIcon />
                    <FakeOccupancyFreeIcon busy="one" />
                  </>
                ) : dataType === 'eta2' ? (
                  <>
                    <FakeWCIcon />
                    <FakeOccupancyFreeIcon busy="three" />
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className={styles.iconsContent}>
                {dataType === 'route' ? (
                  <strong className="route-text" data-name="route" {...other} />
                ) : dataType === 'eta1' || dataType === 'eta2' ? (
                  <strong className="time-text" data-name="eta" {...other} />
                ) : null}
              </div>
              <div className={styles.iconsHolder}>
                {dataType === 'route' ? (
                  <>
                    <FakeTrainIcon />
                    <FakeMetroIcon />
                  </>
                ) : dataType === 'eta1' ? (
                  <>
                    <FakeWCIcon />
                    <FakeOccupancyFreeIcon busy="one" />
                  </>
                ) : dataType === 'eta2' ? (
                  <>
                    <FakeWCIcon />
                    <FakeOccupancyFreeIcon busy="three" />
                  </>
                ) : null}
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
