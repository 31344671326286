import { List, ListItem, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TableSearch } from '~components/TableSearch/table-search'
import { IBusTemplateStopsModel } from '~models/template.model'

import styles from '../emergency-template.module.scss'

interface EmergencyTemplateAsideStopsProps {
  busStops: IBusTemplateStopsModel[]
}

export const EmergencyTemplateAsideStops: FC<EmergencyTemplateAsideStopsProps> = ({ busStops }) => {
  const { t: translate } = useTranslation()

  const [searchRequest, setSearchRequest] = useState<string>('')
  const [selectedStops, setSelectedStops] = useState<IBusTemplateStopsModel[]>(busStops)

  useEffect(() => {
    const filteredSelectedStops = busStops.filter(
      (stop) =>
        stop.name.toLowerCase().includes(searchRequest.toLowerCase()) ||
        stop.code.toLowerCase().includes(searchRequest.toLowerCase()),
    )

    setSelectedStops([...filteredSelectedStops])
  }, [searchRequest])

  return (
    <div className={`${styles.templateAside} ${styles.templateAsideStops}`}>
      <Typography sx={{ mb: 1 }} variant="subtitle1">
        Stops selected ({busStops.length})
      </Typography>
      <TableSearch handleSearch={setSearchRequest} placeholder={translate('stop-name-or-code')} width="100%" />
      <div className={styles.scrollBlock}>
        <List>
          {selectedStops.length ? (
            selectedStops.map(({ id, code, name }) => (
              <ListItem disableGutters key={id}>
                <div>
                  <strong>#{code}</strong> <br /> {name}
                </div>
              </ListItem>
            ))
          ) : (
            <Typography align="center" variant="body2">
              {translate('no-stops-found')}
            </Typography>
          )}
        </List>
      </div>
    </div>
  )
}
