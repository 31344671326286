import { skipToken } from '@reduxjs/toolkit/query'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { TemplateType } from '~/enums/template-type.enum'
import { Canvas } from '~components/Canvas/canvas'
import { PageSpinner } from '~components/PageSpinner/page-spinner'
import { Spinner } from '~components/Spinner/spinner'
import { TemplateAsidePages } from '~components/TemplateAsidePages/template-aside-pages'
import { DEFAULT_DISPLAY_SIZE } from '~constants/constants'
import { isDraftStatus } from '~helpers/check-template-status'
import { ILocationStateTemplate } from '~models/template.model'
import { EmergencyTemplateAsideBlocks } from '~pages/EmergencyTemplate/components/emergency-template-aside-blocks'
import { EmergencyTemplateAsideStops } from '~pages/EmergencyTemplate/components/emergency-template-aside-stops'
import { EmergencyTemplateTopPanel } from '~pages/EmergencyTemplate/components/emergency-template-top-panel'
import { TemplateNotFound } from '~pages/TemplateNotFound/template-not-found'
import { useAppDispatch } from '~stores/hooks'
import { useGetEmergencyTemplateQuery } from '~stores/services/template.api'
import { clearImageBlocks } from '~stores/slices/image-blocks.slice'
import {
  clearTemplate,
  newTemplate,
  setTemplateActivePage,
  setTemplateStore,
  useTemplate,
  useTemplateIsFetching,
} from '~stores/slices/template.slice'

import styles from './emergency-template.module.scss'

export const EmergencyTemplate = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const templateId = Number(params.templateId)

  const template = useTemplate()
  const location = useLocation()
  const templateIsFetching = useTemplateIsFetching()
  const [isLoading, setIsLoading] = useState(false)

  const readOnly = useMemo(() => {
    if (template.templateId) return !isDraftStatus(template.status)
  }, [template.templateId, template.status])

  const displaySize = useMemo(() => {
    if (templateId) {
      return template.display || DEFAULT_DISPLAY_SIZE
    }

    const { displaySize } = (location.state as ILocationStateTemplate) || DEFAULT_DISPLAY_SIZE

    return displaySize
  }, [templateId, template, location.state])

  const {
    data: emergencyTemplateData,
    isLoading: emergencyTemplateIsLoading,
    isFetching: emergencyTemplateIsFetching,
  } = useGetEmergencyTemplateQuery(templateId ? { templateId } : skipToken)

  useEffect(() => {
    dispatch(clearTemplate())
  }, [dispatch, location])

  useEffect(() => {
    if (!templateId && displaySize) {
      dispatch(newTemplate(displaySize))
    }
  }, [dispatch, templateId, displaySize])

  useEffect(() => {
    dispatch(setTemplateActivePage(''))

    return () => {
      dispatch(clearImageBlocks())
    }
  }, [dispatch])

  useEffect(() => {
    if (!emergencyTemplateIsLoading && emergencyTemplateData?.result) {
      dispatch(setTemplateStore({ ...emergencyTemplateData.result }))
    }
  }, [emergencyTemplateIsLoading, emergencyTemplateData?.result, dispatch])

  if (emergencyTemplateIsLoading) {
    return <Spinner />
  }

  if (!template || (typeof readOnly === 'undefined' && templateId)) {
    return <TemplateNotFound />
  }

  return (
    <div className={styles.templateContainer}>
      <EmergencyTemplateTopPanel handleLoading={setIsLoading} readOnly={readOnly} template={template} />
      <div className={styles.templateContent}>
        {!readOnly ? (
          <EmergencyTemplateAsideBlocks />
        ) : (
          template.publishDetails?.busStops && (
            <EmergencyTemplateAsideStops busStops={template.publishDetails.busStops} />
          )
        )}
        <div className={styles.templateHolder}>
          <Canvas
            displaySize={displaySize}
            readOnly={readOnly}
            template={template}
            templateType={TemplateType.Emergency}
          />
        </div>
        <TemplateAsidePages readOnly={readOnly} template={template} />
      </div>
      <PageSpinner show={emergencyTemplateIsFetching || isLoading || templateIsFetching} />
    </div>
  )
}
