import { Wallpaper } from '@mui/icons-material'
import axios from 'axios'
import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import { Spinner } from '~components/Spinner/spinner'
import { BASE_API } from '~constants/constants'
import { getBase64 } from '~helpers/get-base64'
import { useAppDispatch } from '~stores/hooks'
import { useAccessToken, useAgencyId } from '~stores/slices/auth.slice'
import {
  removeFetchingImage,
  setFetchingImage,
  setImageBlock,
  useFetchingImages,
  useImageBlocks,
} from '~stores/slices/image-blocks.slice'

import styles from './template-frame.module.scss'

export interface TemplateFrameSimpleProps {
  style?: CSSProperties
  view?: TemplateBlocksView
  image?: string
  thumbnailMode?: boolean
  firstPage?: boolean
}

export const TemplateFrameImage: FC<TemplateFrameSimpleProps> = ({ style, view, image, thumbnailMode, firstPage }) => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()
  const agencyId = useAgencyId()
  const accessToken = useAccessToken()
  const imageBlocks = useImageBlocks()
  const fetchingImages = useFetchingImages()
  const [isLoading, setIsLoading] = useState(false)

  const [loadError, setLoadError] = useState(false)

  const frameStyles = {
    ...style,
  }

  const imageBase64 = useMemo(() => {
    if (image && imageBlocks.hasOwnProperty(image)) {
      return imageBlocks[image]
    }
  }, [image, imageBlocks])

  const isFetching = useMemo(() => image && fetchingImages.includes(image), [image, fetchingImages])

  useEffect(() => {
    if (image && !imageBase64 && (!thumbnailMode || !firstPage) && !isFetching) {
      setIsLoading(true)
      dispatch(setFetchingImage({ image }))

      axios
        .get(`${BASE_API}/Template/${agencyId}/image/${image}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: 'blob',
        })
        .then((response) => {
          getBase64(response.data).then((base64) => {
            if (typeof base64 === 'string') {
              dispatch(setImageBlock({ image, imageBase64: base64 }))
            }
          })
          setLoadError(false)
        })
        .catch((error) => {
          setLoadError(true)
          console.error(error)
        })
        .finally(() => {
          setIsLoading(false)
          dispatch(removeFetchingImage({ image }))
        })
    }
  }, [agencyId, dispatch, firstPage, image, imageBase64, thumbnailMode])

  return (
    <>
      {view === TemplateBlocksView.pure ? (
        <div className={styles.simpleFramePure} style={frameStyles}>
          <div className={styles.simpleFrameContent}>Image</div>
        </div>
      ) : thumbnailMode ? (
        <div className={`${styles.imageFrame} ${styles.imageFrameThumbnail}`}>
          {imageBase64 ? <img alt="" src={imageBase64} /> : <Wallpaper />}
        </div>
      ) : (
        <div className={styles.imageFrame}>
          {isLoading || isFetching ? (
            <Spinner size="small" />
          ) : loadError && !imageBase64 ? (
            translate('image-not-found')
          ) : imageBase64 ? (
            <img alt={image} src={imageBase64} />
          ) : (
            translate('choose-image')
          )}
        </div>
      )}
    </>
  )
}
