import React, { useEffect, useState } from 'react'

import { TemplateBlocksView as view } from '~/enums/template-blocks.enum'
import { Spinner } from '~components/Spinner/spinner'
import { TemplateAddBlock } from '~components/TemplateAddBlock/template-add-block'
import { TemplateBlockComponent } from '~components/TemplateBlockComponent/template-block-component'
import { ITemplateBlockModel } from '~models/template-block.model'
import { useGetDefaultTemplateBlocksQuery } from '~stores/services/template.api'

import styles from '../default-template.module.scss'

export const DefaultTemplateAsideBlocks = () => {
  const [templateBlocks, setTemplateBlocks] = useState<ITemplateBlockModel[]>([])
  const { data: templateBlocksData, isLoading } = useGetDefaultTemplateBlocksQuery()

  useEffect(() => {
    if (!isLoading && templateBlocksData) {
      setTemplateBlocks([...templateBlocksData.result])
    }
  }, [isLoading])

  return (
    <div className={styles.templateAside}>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={`blocks-scroll-bar ${styles.scrollBlock}`}>
          {templateBlocks.map((block) => (
            <TemplateAddBlock block={block} key={block.id}>
              <TemplateBlockComponent
                componentName={block.componentName}
                properties={block.properties}
                view={view.pure}
              />
            </TemplateAddBlock>
          ))}
        </div>
      )}
    </div>
  )
}
