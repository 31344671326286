import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { DisplayConfig, DisplaySize, DisplaySizeKeys } from '~/enums/display-size'
import { TemplateType } from '~/enums/template-type.enum'
import { useMount } from '~/hooks/use-mount'
import { CloseModal } from '~components/Modal/components/close-modal'
import { getObjectKeys } from '~helpers/get-object-keys'
import { getTemplateUrl } from '~helpers/get-template-url'
import { useAppDispatch } from '~stores/hooks'
import { hideConfirmationPopup, useIsOpenConfirmationPopup } from '~stores/slices/confirmation.slice'

interface DisplaySizePopupProps {
  type: TemplateType
}

export const DisplaySizePopup: FC<DisplaySizePopupProps> = ({ type }) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const navigate = useNavigate()

  const isOpen = useIsOpenConfirmationPopup()

  const [activeDisplay, setActiveDisplay] = useState<DisplaySizeKeys>('ThirteenInchesEInk')

  const { mounted } = useMount({ opened: isOpen, confirmationPopup: true })

  const handleClose = useCallback(() => {
    dispatch(hideConfirmationPopup())
  }, [dispatch])

  const handleActiveDisplay = (event: ChangeEvent<HTMLInputElement>) => {
    setActiveDisplay(event.target.value as DisplaySizeKeys)
  }

  const handleSelectTemplate = () => {
    handleClose()

    setTimeout(() => {
      navigate(getTemplateUrl(type, 'createUrl'), { state: { displaySize: activeDisplay } })
    }, 1)
  }

  if (!mounted) {
    return null
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={isOpen}>
      <DialogTitle>
        {translate('select-display-size')}
        <CloseModal handleClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <RadioGroup name="rd-display-size" onChange={handleActiveDisplay} value={activeDisplay}>
          {getObjectKeys(DisplaySize).map((key) => (
            <FormControlLabel
              control={<Radio color="primary" value={key} />}
              key={key}
              label={translate(DisplayConfig[key].label)}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button disabled={!(activeDisplay in DisplaySize)} onClick={handleSelectTemplate}>
          {translate('select')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
