import i18n, { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { LANGUAGES } from '~constants/constants'

const resources: Resource = {}

LANGUAGES.forEach((language) => {
  resources[language] = { translations: require(`./locales/${language}/translations.json`) }
})

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: LANGUAGES[0],
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    resources,
    supportedLngs: LANGUAGES,
    ns: ['translations'],
    defaultNS: 'translations',
  })

export default i18n
