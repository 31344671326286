export const deleteKeysFormArrayOfObjects = (arr: any[], keys: string[]) =>
  arr.map((item) => {
    keys.forEach((key) => {
      if (item.hasOwnProperty(key)) {
        delete item[key]
      }
    })

    return item
  })
