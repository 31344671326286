import { FormatColorFill, FormatColorFillTwoTone } from '@mui/icons-material'
import { Popover } from '@mui/material'
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'

import { PALETTE_64 } from '~constants/constants'

import styles from './color-picker.module.scss'

interface ColorPickerProps {
  defaultChecked: string
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>, fieldName: string) => void
}

export const ColorPicker: FC<ColorPickerProps> = ({ defaultChecked, name, onChange }) => {
  const [pickerOpen, setPickerOpen] = useState(false)
  const dropOpener = useRef<HTMLButtonElement>(null)
  const pickerDrop = useRef<HTMLDivElement>(null)

  const openClass = pickerOpen ? styles.pickerShow : ''

  useEffect(() => {
    if (pickerOpen) {
      const checkIfClickedOutside = (event: Event) => {
        if (
          dropOpener.current &&
          !dropOpener.current.contains(event.target as Node) &&
          !(event.target === pickerDrop.current || event.target === pickerDrop.current?.childNodes[0])
        ) {
          setPickerOpen(false)
        }
      }

      document.addEventListener('mouseup', checkIfClickedOutside)

      return () => {
        document.removeEventListener('mouseup', checkIfClickedOutside)
      }
    }
  }, [pickerOpen])

  const handleDropDown = () => {
    setPickerOpen(!pickerOpen)
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    handleClose()
    onChange(e, name)
  }

  return (
    <div className={`${styles.pickerContainer} ${openClass}`}>
      {/* <button className={styles.pickerOpener} onClick={() => handleDropDown()} ref={dropOpener} type="button"> */}
      {/*   <span className={styles.pickerOpenerIcon} style={{ backgroundColor: defaultChecked }} /> */}
      {/*   /!* {defaultChecked} *!/ */}
      {/* </button> */}
      <button className={styles.pickerOpener} onClick={handleClick} type="button">
        {/* <FormatColorFill color="primary" /> */}
        <span className={styles.pickerOpenerIcon} style={{ backgroundColor: defaultChecked }} />
      </button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <div className={styles.pickerDrop}>
          {PALETTE_64.map((color) => (
            <label
              className={`${styles.pickerColorItem} ${defaultChecked === color ? styles.checked : null}`}
              key={color}
              style={{ backgroundColor: color }}
            >
              <input
                defaultChecked={defaultChecked === color}
                name={name}
                onChange={(e) => handleChange(e, name)}
                type="radio"
                value={color}
              />
              {defaultChecked === color && (
                <svg className={styles.pickerCheckIcon} height="24" width="24">
                  <path
                    d="M18.2 5.4a1 1 0 0 1 1.6 1.2l-8 12a1 1 0 0 1-1.5.1l-5-5a1 1 0 1 1 1.4-1.4l4.1 4.1 7.4-11Z"
                    fillRule="nonzero"
                  />
                </svg>
              )}
            </label>
          ))}
        </div>
      </Popover>
      {/* <div className={styles.pickerDrop} ref={pickerDrop}> */}
      {/*   <div className={styles.pickerGroup}> */}
      {/*     {colorsPalette.map((color) => ( */}
      {/*       <label */}
      {/*         className={`${styles.pickerDropItem} ${defaultChecked === color ? styles.checked : null}`} */}
      {/*         key={color} */}
      {/*         style={{ backgroundColor: color }} */}
      {/*       > */}
      {/*         <input */}
      {/*           defaultChecked={defaultChecked === color} */}
      {/*           name={name} */}
      {/*           onChange={(e) => onChange(e, name)} */}
      {/*           type="radio" */}
      {/*           value={color} */}
      {/*         /> */}
      {/*       </label> */}
      {/*     ))} */}
      {/*   </div> */}
      {/* </div> */}
    </div>
  )
}
