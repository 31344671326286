import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

interface IPreviewSlice {
  isOpen: boolean
  component: JSX.Element | null
}

const initialState: IPreviewSlice = {
  isOpen: false,
  component: null,
}

const previewSlice = createSlice({
  name: 'ConfirmationSlice',
  initialState,
  reducers: {
    showPreviewPopup: (state, { payload }: PayloadAction<JSX.Element>) => {
      state.isOpen = true
      state.component = payload
    },
    hidePreviewPopup: (state) => {
      state.isOpen = false
    },
    clearPreviewPopupComponent: (state) => {
      state.component = null
    },
  },
})

export const selectIsOpenPreviewPopup = (state: RootState) => state.preview.isOpen
export const selectPreviewPopup = (state: RootState) => state.preview.component

export const useIsOpenPreviewPopup = () => useAppSelector(selectIsOpenPreviewPopup)
export const usePreviewPopup = () => useAppSelector(selectPreviewPopup)

export const {
  reducer: previewReducer,
  actions: { hidePreviewPopup, clearPreviewPopupComponent, showPreviewPopup },
} = previewSlice
