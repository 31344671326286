import { useEffect, useState } from 'react'

import { useAppDispatch } from '~stores/hooks'
import { clearConfirmationPopupComponent } from '~stores/slices/confirmation.slice'
import { clearPreviewPopupComponent } from '~stores/slices/preview.slice'

interface useMountProps {
  opened: boolean
  confirmationPopup?: boolean
  previewPopup?: boolean
}

export const useMount = ({ opened, confirmationPopup, previewPopup }: useMountProps) => {
  const dispatch = useAppDispatch()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (opened && !mounted) {
      setMounted(true)

      return
    }

    if (!opened && mounted) {
      setTimeout(() => {
        setMounted(false)

        if (confirmationPopup) {
          dispatch(clearConfirmationPopupComponent())
        }

        if (previewPopup) {
          dispatch(clearPreviewPopupComponent())
        }
      }, 300)
    }
  }, [opened, confirmationPopup, mounted, previewPopup, dispatch])

  return {
    mounted,
  }
}
