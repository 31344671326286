import { BaseQueryMeta } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { createApi } from '@reduxjs/toolkit/dist/query/react'

import { IBusServiceGetResponse, IBusServiceRequest, IBusServiceWithPagination } from '~models/bus-service.model'
import { staggeredBaseQueryWithBailOut } from '~stores/helpers/staggered-base-query-with-bail-out'

export const busServiceApi = createApi({
  reducerPath: 'busServiceApi',
  baseQuery: staggeredBaseQueryWithBailOut('BusService'),
  tagTypes: ['BusService'],
  endpoints: (build) => ({
    getBusService: build.query<IBusServiceWithPagination, IBusServiceRequest>({
      query: (queryArg) => ({
        url: '',
        params: {
          ...queryArg,
        },
      }),
      transformResponse: (response: IBusServiceGetResponse, meta: BaseQueryMeta<any>) => {
        const pagination = JSON.parse(meta.response.headers.get('x-pagination'))

        return { ...response, pagination }
      },
      providesTags: ['BusService'],
    }),
  }),
})

export const { useGetBusServiceQuery, useLazyGetBusServiceQuery } = busServiceApi
