import { createApi } from '@reduxjs/toolkit/dist/query/react'

import { ILayoutBlocks } from '~models/layout-blocks.model'
import { ILayoutForSending, ILayoutGetResponse, ILayoutResponse } from '~models/layouts.model'
import { staggeredBaseQueryWithBailOut } from '~stores/helpers/staggered-base-query-with-bail-out'

export const layoutsApi = createApi({
  reducerPath: 'layoutsApi',
  baseQuery: staggeredBaseQueryWithBailOut('TemplateLayout'),
  tagTypes: ['Layouts'],
  endpoints: (build) => ({
    getLayouts: build.query<ILayoutGetResponse, void>({
      query: () => ({
        url: '',
        params: {
          agencyId: true,
        },
      }),
      providesTags: ['Layouts'],
    }),
    putLayouts: build.mutation<ILayoutResponse, ILayoutForSending>({
      query: (queryArg) => ({
        url: '',
        method: 'PUT',
        body: { ...queryArg },
      }),
      invalidatesTags: ['Layouts'],
    }),
    getLayoutBlocks: build.query<ILayoutBlocks, void>({
      query: () => ({ url: 'block' }),
      providesTags: ['Layouts'],
    }),
  }),
})

export const { useGetLayoutsQuery, usePutLayoutsMutation, useGetLayoutBlocksQuery } = layoutsApi
