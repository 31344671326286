import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

interface IAgencyLogo {
  logo: string | null
  logoTemp: string | null
  logoBlob: Blob | null
}

const initialState: IAgencyLogo = {
  logo: null,
  logoTemp: null,
  logoBlob: null,
}

const agencyLogoSlice = createSlice({
  name: 'logoBuilderSlice',
  initialState,
  reducers: {
    setAgencyLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload
    },
    setAgencyLogoTemp: (state, action: PayloadAction<string>) => {
      state.logoTemp = action.payload
    },
    setAgencyLogoBlob: (state, action: PayloadAction<Blob>) => {
      state.logoBlob = action.payload
    },
    clearAgencyLogoTemp: (state) => {
      state.logoTemp = null
      state.logoBlob = null
    },
  },
})

export const getAgencyLogo = (state: RootState) => state.agencyLogo.logo
export const getAgencyLogoTemp = (state: RootState) => state.agencyLogo.logoTemp
export const getAgencyLogoBlob = (state: RootState) => state.agencyLogo.logoBlob

export const useAgencyLogo = () => useAppSelector(getAgencyLogo)
export const useAgencyLogoTemp = () => useAppSelector(getAgencyLogoTemp)
export const useAgencyLogoBlob = () => useAppSelector(getAgencyLogoBlob)

export const {
  reducer: agencyLogoReducer,
  actions: { setAgencyLogo, setAgencyLogoTemp, setAgencyLogoBlob, clearAgencyLogoTemp },
} = agencyLogoSlice
