import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  SvgIconComponent,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
} from '@mui/icons-material'

import cssConstants from '~/assets/styles/_constants.module.scss'
import { AlignItemsKeys, JustifyContentKeys } from '~/enums/align-settings.enum'
import { Colors } from '~/enums/colors.enum'
import { DisplaySizeKeys } from '~/enums/display-size'
import { PageUrls } from '~/enums/page-urls.enum'
import { TemplateTypeKeys } from '~/enums/template-type.enum'
import { TemplateActionsKeys, TemplateStatusesEnum } from '~/enums/templates.enum'
import { ITimeFormats } from '~models/layouts.model'
import { ITemplateStatuses } from '~models/template.model'

export const BASE_API = process.env.REACT_APP_API_URL

export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID || ''
export const AUTH_CLIENT_NAME = process.env.REACT_APP_AUTH_CLIENT_NAME || ''
export const AUTH_SCOPES: string[] = process.env.REACT_APP_AUTH_SCOPES
  ? JSON.parse(process.env.REACT_APP_AUTH_SCOPES)
  : []

export const DRAFT_STATUS_ACTIONS: TemplateActionsKeys[] = ['preview', 'copy', 'publish', 'delete']
export const DEPLOYED_STATUS_ACTIONS: TemplateActionsKeys[] = ['preview', 'copy']
export const ARCHIVE_STATUS_ACTIONS: TemplateActionsKeys[] = ['preview', 'copy', 'publish', 'setDraft']
export const IN_DEPLOYMENT_STATUS_ACTIONS: TemplateActionsKeys[] = ['preview', 'copy']

export const ROWS_PER_PAGE = [10, 25, 50, 100]

export const LANGUAGES = ['en', 'fr']

export const MIN_DELAY = 5
export const MIN_TEMPLATE_NAME_LENGTH = 3
export const MAX_TEMPLATE_NAME_LENGTH = 50

export const DEFAULT_DISPLAY_SIZE: DisplaySizeKeys = 'ThirteenInchesEInk'

export const TemplateStatuses: ITemplateStatuses = {
  Draft: {
    color: Colors.default,
    name: TemplateStatusesEnum.Draft,
  },
  InDeployment: {
    color: Colors.info,
    name: TemplateStatusesEnum.InDeployment,
  },
  Deployed: {
    color: Colors.success,
    name: TemplateStatusesEnum.Deployed,
  },
  Archived: {
    color: Colors.warning,
    name: TemplateStatusesEnum.Archived,
  },
}

export const TimeFormats: ITimeFormats = {
  '12h': 'hh:mm A',
  '24h': 'HH:mm',
}

type AlignItemsType = {
  [key in AlignItemsKeys]: {
    title: string
    icon: SvgIconComponent
  }
}

type JustifyContentType = {
  [key in JustifyContentKeys]: {
    title: string
    icon: SvgIconComponent
  }
}

type TemplateUrl = {
  [key in TemplateTypeKeys]: string
}

export const JUSTIFY_CONTENT: JustifyContentType = {
  flexStart: { title: 'left', icon: FormatAlignLeft },
  center: { title: 'center', icon: FormatAlignCenter },
  flexEnd: { title: 'right', icon: FormatAlignRight },
  spaceBetween: { title: 'space between', icon: FormatAlignJustify },
}

export const ALIGN_ITEMS: AlignItemsType = {
  flexStart: { title: 'Top', icon: VerticalAlignTop },
  center: { title: 'Middle', icon: VerticalAlignCenter },
  flexEnd: { title: 'Bottom', icon: VerticalAlignBottom },
}

export const PREVIEW_URLS: TemplateUrl = {
  Transit: PageUrls.PreviewTemplate,
  Emergency: PageUrls.PreviewEmergencyTemplate,
  Default: PageUrls.PreviewDefaultTemplate,
}

export const TEXT_DEFAULT_FONT_SIZE = cssConstants.canvasDefaultFontValue

export const PALETTE_64 = [
  '#3d4341',
  '#3e4648',
  '#444b53',
  '#586576',
  '#414a45',
  '#42514c',
  '#4a5a5a',
  '#60727e',
  '#46584a',
  '#4b6155',
  '#556a63',
  '#6b8489',
  '#5f835f',
  '#668b69',
  '#71957b',
  '#81a79a',
  '#454543',
  '#4a4b4d',
  '#525358',
  '#64697d',
  '#4a5046',
  '#545953',
  '#5c6466',
  '#6f7b87',
  '#545f4f',
  '#606a5f',
  '#6b756c',
  '#7d8f93',
  '#6d8865',
  '#769472',
  '#859f86',
  '#94aea1',
  '#534947',
  '#5b5150',
  '#64595f',
  '#797284',
  '#5a584b',
  '#67625c',
  '#706f6b',
  '#888691',
  '#646754',
  '#737665',
  '#7f8279',
  '#979b9a',
  '#82916a',
  '#8f9c7e',
  '#9da991',
  '#aeb8ad',
  '#895c56',
  '#916461',
  '#986d74',
  '#a68492',
  '#90685c',
  '#9b756c',
  '#a58381',
  '#b2949c',
  '#987d62',
  '#a68b76',
  '#b09a8d',
  '#bfaea7',
  '#aaa178',
  '#b5ad88',
  '#bfb7a0',
  '#c9c2b0',
]

export const PALETTE_2 = [
  [
    '#3d4341',
    '#3e4648',
    '#444b53',
    '#586576',
    '#414a45',
    '#42514c',
    '#4a5a5a',
    '#60727e',
    '#46584a',
    '#4b6155',
    '#556a63',
    '#6b8489',
    '#5f835f',
    '#668b69',
    '#71957b',
    '#81a79a',
  ],
  [
    '#454543',
    '#4a4b4d',
    '#525358',
    '#64697d',
    '#4a5046',
    '#545953',
    '#5c6466',
    '#6f7b87',
    '#545f4f',
    '#606a5f',
    '#6b756c',
    '#7d8f93',
    '#6d8865',
    '#769472',
    '#859f86',
    '#94aea1',
  ],
  [
    '#534947',
    '#5b5150',
    '#64595f',
    '#797284',
    '#5a584b',
    '#67625c',
    '#706f6b',
    '#888691',
    '#646754',
    '#737665',
    '#7f8279',
    '#979b9a',
    '#82916a',
    '#8f9c7e',
    '#9da991',
    '#aeb8ad',
  ],
  [
    '#895c56',
    '#916461',
    '#986d74',
    '#a68492',
    '#90685c',
    '#9b756c',
    '#a58381',
    '#b2949c',
    '#987d62',
    '#a68b76',
    '#b09a8d',
    '#bfaea7',
    '#aaa178',
    '#b5ad88',
    '#bfb7a0',
    '#c9c2b0',
  ],
]
