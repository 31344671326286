import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DropZone } from '~components/DropZone/drop-zone'
import { ISetTemplateStaticImageBlockSettings } from '~models/template.model'
import { useAppDispatch } from '~stores/hooks'
import { usePostTemplateImageMutation } from '~stores/services/template.api'
import { useAgencyId } from '~stores/slices/auth.slice'
import { setImageBlock } from '~stores/slices/image-blocks.slice'

interface ImageBlockSettingsPopupProps {
  blockId: string
  isOpen: boolean
  handleClose: () => void
  handleSave: (blockSettings: ISetTemplateStaticImageBlockSettings) => void
  handleFetching: (blockId: string | null) => void
}

export const ImageBlockSettingsPopup: FC<ImageBlockSettingsPopupProps> = ({
  blockId,
  isOpen,
  handleClose,
  handleSave,
  handleFetching,
}) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const agencyId = useAgencyId()

  const [imageBase64, setImageBase64] = useState<string | null>(null)
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [clearImage, setClearImage] = useState(false)

  const [uploadTemplateImage, { isLoading }] = usePostTemplateImageMutation()

  const handleSaveImage = useCallback(async () => {
    if (imageBlob && imageBase64) {
      try {
        handleFetching(blockId)

        const formData = new FormData()

        formData.append('image', imageBlob)

        const {
          result: { payload },
        } = await uploadTemplateImage({ agencyId, image: formData }).unwrap()

        dispatch(setImageBlock({ image: payload, imageBase64 }))

        handleSave({ id: blockId, imageSrc: payload })
        handleClose()

        enqueueSnackbar(translate('image-uploaded'))
      } catch (err) {
        console.error(err)
        enqueueSnackbar(translate('image-not-saved'), { variant: 'error' })
      } finally {
        handleFetching(null)
      }
    }
  }, [
    blockId,
    dispatch,
    enqueueSnackbar,
    handleClose,
    handleFetching,
    handleSave,
    imageBase64,
    imageBlob,
    translate,
    uploadTemplateImage,
  ])

  return (
    <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={isOpen}>
      <DialogTitle>
        {translate('choose-image')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DropZone
        handleImageBase64={setImageBase64}
        handleImageBlob={setImageBlob}
        initClearImage={clearImage}
        isLoading={isLoading}
      />
      <DialogActions sx={{ p: 2 }}>
        {imageBase64 && (
          <Button color="error" disabled={isLoading} onClick={() => setClearImage(!clearImage)} sx={{ mr: 'auto' }}>
            {translate('clear-image')}
          </Button>
        )}
        <Button color="inherit" disabled={isLoading} onClick={handleClose}>
          {translate('cancel')}
        </Button>
        {imageBase64 && (
          <Button disabled={isLoading} onClick={handleSaveImage} variant="outlined">
            {translate('upload')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
