import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ILayoutBlockModel } from '~models/layout-blocks.model'
import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

interface ILayoutBlocksSlice {
  blocks: ILayoutBlockModel[]
}

const initialState: ILayoutBlocksSlice = {
  blocks: [],
}

const layoutBlocksSlice = createSlice({
  name: 'layoutBlocksSlice',
  initialState,
  reducers: {
    setLayoutBlocks: (state, { payload }: PayloadAction<ILayoutBlockModel[]>) => {
      state.blocks = payload
    },
  },
})

export const getLayoutBlocks = (state: RootState) => state.layoutBlocks.blocks
export const useLayoutBlocks = () => useAppSelector(getLayoutBlocks)

export const {
  reducer: layoutBlocksReducer,
  actions: { setLayoutBlocks },
} = layoutBlocksSlice
