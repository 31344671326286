import { createApi } from '@reduxjs/toolkit/dist/query/react'

import { ITemplateImageResponse } from '~models/template.model'
import { staggeredBaseQueryWithBailOut } from '~stores/helpers/staggered-base-query-with-bail-out'

export const agencyApi = createApi({
  reducerPath: 'agencyApi',
  baseQuery: staggeredBaseQueryWithBailOut('Agency'),
  tagTypes: ['Agency'],
  endpoints: (build) => ({
    postAgencyLogo: build.mutation<ITemplateImageResponse, { agencyId: number; image: FormData }>({
      query: ({ agencyId, image }) => ({ url: `${agencyId}/logo`, method: 'POST', body: image }),
      invalidatesTags: ['Agency'],
    }),
    deleteAgencyLogo: build.mutation<null, { agencyId: number }>({
      query: ({ agencyId }) => ({ url: `${agencyId}/logo`, method: 'DELETE' }),
      invalidatesTags: ['Agency'],
    }),
  }),
})

export const { usePostAgencyLogoMutation, useDeleteAgencyLogoMutation } = agencyApi
