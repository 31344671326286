import { IErrorRequestData } from '~models/error-request.model'

export const getErrorsValues = (errorData: IErrorRequestData | null) => {
  if (!errorData) return ['Something went wrong!']

  if (errorData.errors) {
    return Object.values(errorData.errors).flat()
  }

  return [errorData.title]
}
