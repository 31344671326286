import { StandardTextFieldProps, TextField } from '@mui/material'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MAX_TEMPLATE_NAME_LENGTH as maxLength, MIN_TEMPLATE_NAME_LENGTH as minLength } from '~constants/constants'
import { IErrorRequestData } from '~models/error-request.model'

interface TemplateNameInputProps extends StandardTextFieldProps {
  value: string
  errorsData: IErrorRequestData | null
  showErrorsOnBlur?: boolean
  getTemplateName: (value: string) => void
  hasErrorCallBack: (value: boolean) => void
  name?: string
}

export const TemplateNameInput: FC<TemplateNameInputProps> = ({
  value,
  errorsData,
  showErrorsOnBlur,
  getTemplateName,
  hasErrorCallBack,
  name = 'Name',
  ...rest
}) => {
  const { t: translate } = useTranslation()
  const [templateName, setTemplateName] = useState(value)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showInputsError, setShowInputsError] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onChangeName = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    getTemplateName(event.currentTarget.value)
    setTemplateName(event.currentTarget.value)
  }, [])

  useEffect(() => {
    setTemplateName(value)
  }, [value])

  useEffect(() => {
    if (templateName?.trim().length < minLength || templateName?.trim().length > maxLength) {
      setErrorMessage(translate('min-max-length-error-message', { min: minLength, max: maxLength }))

      return
    }

    setErrorMessage(null)
  }, [templateName])

  useEffect(() => {
    if (!errorsData) return

    const { errors, title } = errorsData

    if (!errors) {
      setErrorMessage(title)

      return
    }

    if (errors.hasOwnProperty(name)) {
      setErrorMessage(translate('min-max-length-error-message', { min: minLength, max: maxLength }))
    }
  }, [errorsData, name, translate])

  useEffect(() => (errorMessage ? setHasError(true) : setHasError(false)), [errorMessage])

  useEffect(() => hasErrorCallBack(hasError), [hasError, hasErrorCallBack])

  return (
    <TextField
      size="small"
      variant="standard"
      {...rest}
      InputLabelProps={{
        shrink: true,
      }}
      error={showErrorsOnBlur ? showInputsError && hasError : hasError}
      helperText={showErrorsOnBlur ? showInputsError && errorMessage : errorMessage}
      name={name}
      onBlur={() => (showErrorsOnBlur ? setShowInputsError(true) : null)}
      onChange={onChangeName}
      value={templateName}
    />
  )
}
