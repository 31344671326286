import i18n from 'i18next'
import React, { useCallback } from 'react'

import styles from './language-switcher.module.scss'

const languages = [
  {
    value: 'en',
    title: 'English',
  },
  {
    value: 'fr',
    title: 'French',
  },
]

export const LanguageSwitcher = () => {
  const handleLanguage = useCallback((value: string) => {
    i18n.changeLanguage(value)
  }, [])

  return (
    <div className={styles.languages}>
      {languages.map(({ value }) => (
        <span
          className={`${i18n.language === value ? styles.active : ''}`}
          key={value}
          onClick={() => handleLanguage(value)}
        >
          {value}
        </span>
      ))}
    </div>
  )
}
