import React, { FC, useEffect, useMemo, useState } from 'react'

import { TimeFormats } from '~constants/constants'
import { showByFormat } from '~helpers/date-helper'
import { keysOfTimeFormats } from '~models/layouts.model'
import { useLayoutDynamicBlocksTrigger } from '~stores/slices/layout-dynamic-blocks.slice'

interface TemplateTimeProps {
  format?: keysOfTimeFormats
  showDayOfTheWeek: boolean
  preview?: boolean
  staticMode?: boolean
}

export const TemplateTime: FC<TemplateTimeProps> = ({ format = '12h', showDayOfTheWeek, preview, staticMode }) => {
  const [time, setTime] = useState('')
  const layoutDynamicBlocksTrigger = useLayoutDynamicBlocksTrigger()

  const timeFormat = useMemo(() => {
    const day = showDayOfTheWeek ? 'ddd,' : ''

    return `${day} ${TimeFormats[format]}`
  }, [format, showDayOfTheWeek])

  const setCurrentTime = () => {
    setTime(showByFormat(Date.now(), timeFormat))
  }

  useEffect(() => {
    if (timeFormat) {
      setCurrentTime()

      if (!staticMode) {
        const interval = setInterval(() => setCurrentTime(), 1000)

        return () => clearInterval(interval)
      }
    }
  }, [staticMode, time, timeFormat, layoutDynamicBlocksTrigger])

  if (!timeFormat) {
    return null
  }

  return <>{preview ? <span data-time={timeFormat} /> : time}</>
}
