import { Close, Link, Settings } from '@mui/icons-material'
import { IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { isEqual } from 'lodash'
import { ResizeDirection } from 're-resizable'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DraggableEvent } from 'react-draggable'
import uuid from 'react-native-uuid'
import { DraggableData, Position, Rnd } from 'react-rnd'
import { ActionCreators } from 'redux-undo'

import { AlignItems, TextAlign } from '~/enums/align-settings.enum'
import { DisplaySizeKeys } from '~/enums/display-size'
import { TemplateBlocksView, TemplateComponentName } from '~/enums/template-blocks.enum'
import { TemplateType } from '~/enums/template-type.enum'
import { useCanvasProperties } from '~/hooks/use-canvas-properties'
import { useWindowSize } from '~/hooks/use-window-size'
import { CanvasFooter } from '~components/Canvas/components/canvas-footer'
import { CanvasHeader } from '~components/Canvas/components/canvas-header'
import { ImageBlockSettingsPopup } from '~components/Modal/ImageBlockSettingsPopup/image-block-settings-popup'
import { TextBlockSettingsPopup } from '~components/Modal/TextBlockSettingsPopup/text-block-settings-popup'
import { Spinner } from '~components/Spinner/spinner'
import { TemplateBlockComponent } from '~components/TemplateBlockComponent/template-block-component'
import { TEXT_DEFAULT_FONT_SIZE } from '~constants/constants'
import { isDraftStatus } from '~helpers/check-template-status'
import { isDarkColor } from '~helpers/is-dark-color'
import { IErrorRequestDataErrors } from '~models/error-request.model'
import { ILayout } from '~models/layouts.model'
import {
  ISetTemplateStaticImageBlockSettings,
  ISetTemplateStaticTextBlockSettings,
  ITemplate,
  ITemplatePage,
  ITemplatePageBlocks,
} from '~models/template.model'
import { useAppDispatch } from '~stores/hooks'
import { useGetLayoutsQuery } from '~stores/services/layouts.api'
import { useDeleteTemplateImageMutation } from '~stores/services/template.api'
import { useAgencyId } from '~stores/slices/auth.slice'
import { clearDragBlock, useAddingBlock, useDragBlock, useIsDrag } from '~stores/slices/drag-block.slice'
import {
  setTemplateActivePage,
  setTemplateBlocks,
  setTemplateErrors,
  setTemplateIsFetching,
  useTemplateActivePage,
  useTemplateErrors,
} from '~stores/slices/template.slice'

import styles from './canvas.module.scss'

interface CanvasProps {
  template: ITemplate
  displaySize?: DisplaySizeKeys
  readOnly?: boolean
  templateType?: TemplateType
}

interface IResizingDirection {
  id: string
  direction: ResizeDirection
}

interface IRouteSelect {
  [key: string]: {
    [key: string]: string
  }
}

export const Canvas: FC<CanvasProps> = ({ template, displaySize, readOnly, templateType }) => {
  const dispatch = useAppDispatch()
  const rndRefs = useRef<any>([])
  const [windowWidth, windowHeight] = useWindowSize()
  const agencyId = useAgencyId()

  const { data: layoutData, isLoading: layoutsDataIsLoading } = useGetLayoutsQuery()

  const templateErrors = useTemplateErrors()
  const isDrag = useIsDrag()
  const addingBlock = useAddingBlock()
  const dragElement = useDragBlock()
  const activePageId = useTemplateActivePage()

  const [textBlockSettingsOpen, setTextBlockSettingsOpen] = useState(false)
  const [imageBlockSettingsOpen, setImageBlockSettingsOpen] = useState(false)
  const [editableBlockSettings, setEditableBlockSettings] = useState<ISetTemplateStaticTextBlockSettings | null>(null)
  const [editableImageBlockId, setEditableImageBlockId] = useState<string | null>(null)

  const [isDragOver, setIsDragOver] = useState(false)
  const [blocks, setBlocks] = useState<ITemplatePageBlocks[]>([])
  const [pages, setPages] = useState<ITemplatePage[]>([])
  const [lastActivePage, setLastActivePage] = useState('')
  const [historyCleared, setHistoryCleared] = useState(false)
  const [resizingDirection, setResizingDirection] = useState<IResizingDirection | null>(null)
  const [layout, setLayout] = useState<ILayout>()
  const [routeIndexes, setRouteIndexes] = useState<number[]>([])
  const [routeSelect, setRouteSelect] = useState<IRouteSelect>({})
  const [liveErrors, setLiveErrors] = useState<IErrorRequestDataErrors | null>(null)
  const [fetchingImage, setFetchingImage] = useState<string | null>(null)

  const [deleteTemplateImage] = useDeleteTemplateImageMutation()

  const isFetching = useCallback((id: string) => fetchingImage === id, [fetchingImage])
  const isDefaultTemplate = useMemo(() => templateType === TemplateType.Default, [templateType])
  const isEmergencyTemplate = useMemo(() => templateType === TemplateType.Emergency, [templateType])

  const isTextType = (componentName: TemplateComponentName) => componentName === TemplateComponentName.text
  const isImageType = (componentName: TemplateComponentName) => componentName === TemplateComponentName.image

  const isRelatedBlock = (componentName: TemplateComponentName) =>
    componentName === TemplateComponentName.connection || componentName === TemplateComponentName.eta

  const isParentBlock = (componentName: TemplateComponentName) => componentName === TemplateComponentName.route

  const asideBar = useMemo(
    () => (isDraftStatus(template.status) || (!readOnly && isDefaultTemplate) || isEmergencyTemplate ? 'two' : 'one'),
    [readOnly, isDefaultTemplate, isEmergencyTemplate, template.status],
  )

  const { canvasRowHeight, canvasGutter, canvasRows, canvasColWidth, canvasCols } = useCanvasProperties({
    windowWidth,
    windowHeight,
    headerPanelDisabled: !layout?.header.isActive,
    footerPanelDisabled: !layout?.footer.isActive,
    asideBar,
    displaySize,
  })

  const handleTextBlockSettingsOpen = useCallback(
    (id: string) => {
      const targetBlock = blocks.find((block) => block.id === id)

      if (targetBlock?.text && targetBlock?.cssStyles) {
        const { text, cssStyles } = targetBlock

        setEditableBlockSettings({ id, text, cssStyles })
        setTextBlockSettingsOpen(true)
      }
    },
    [blocks],
  )

  const handleImageBlockSettingsOpen = useCallback(
    (id: string) => {
      const targetBlock = blocks.find((block) => block.id === id)

      if (targetBlock && isImageType(targetBlock.templateBlock.componentName)) {
        setEditableImageBlockId(id)
        setImageBlockSettingsOpen(true)
      }
    },
    [blocks],
  )

  const handleImageSettingsClose = useCallback(() => {
    setImageBlockSettingsOpen(false)
  }, [])

  const handleBlockSettingsClose = useCallback(() => {
    setTextBlockSettingsOpen(false)
    setEditableBlockSettings(null)
  }, [])

  const handleSaveTextBlockSettings = useCallback(
    (blockSettings: ISetTemplateStaticTextBlockSettings) => {
      const copyBlocks = [...blocks]
      const objIndex = copyBlocks.findIndex((obj) => obj.id === blockSettings.id)

      copyBlocks[objIndex] = {
        ...copyBlocks[objIndex],
        cssStyles: blockSettings.cssStyles,
        text: blockSettings.text,
      }
      if (JSON.stringify(blocks) !== JSON.stringify(copyBlocks)) {
        setBlocks([...copyBlocks])
      }
    },
    [blocks],
  )

  const handleSaveImageBlockSettings = useCallback(
    ({ id, imageSrc }: ISetTemplateStaticImageBlockSettings) => {
      const changeBlockImage = (prevState: ITemplatePageBlocks[]) => {
        const copyBlocks = [...prevState]
        const objIndex = copyBlocks.findIndex((obj) => obj.id === id)

        copyBlocks[objIndex] = {
          ...copyBlocks[objIndex],
          image: imageSrc,
        }
        if (JSON.stringify(blocks) !== JSON.stringify(copyBlocks)) {
          return copyBlocks
        }

        return prevState
      }

      setBlocks((prevState) => changeBlockImage(prevState))
    },
    [blocks],
  )

  const contentClass = useMemo(() => {
    let className = styles.content

    className += isDragOver ? ` ${styles.dragOver}` : ''
    className += isDrag ? ` ${styles.isDrag}` : ''
    className += resizingDirection ? ' react-draggable-resizing' : ''
    className += displaySize === 'TwentyEightInchesEInk' ? ` ${styles.wideCanvas}` : ''

    return className
  }, [isDrag, isDragOver, resizingDirection])

  const activePageNumber = useMemo(() => {
    if (!template.pages) return

    const activePage = template.pages.find((page) => page.id === activePageId)

    if (activePage) return activePage.orderNumber
  }, [activePageId, template.pages])

  useEffect(() => {
    if (layoutData?.result && !layoutsDataIsLoading) {
      setLayout({ ...layoutData.result })
    }
  }, [layoutData?.result, layoutsDataIsLoading])

  useEffect(() => {
    const filteredRouteBLocks = blocks
      .filter(({ templateBlock }) => isParentBlock(templateBlock.componentName))
      .map((item) => item.routeIndex || 0)
      .sort((a, b) => a - b)

    setRouteIndexes(filteredRouteBLocks)

    const pageIndex = template.pages.findIndex((page) => page.id === activePageId)

    if (
      lastActivePage === activePageId &&
      pageIndex !== -1 &&
      JSON.stringify(template.pages[pageIndex].blocks) !== JSON.stringify(blocks)
    ) {
      dispatch(setTemplateBlocks({ activePageId, blocks }))

      return
    }

    setLastActivePage(activePageId)
  }, [dispatch, blocks, lastActivePage, activePageId])

  useEffect(() => {
    if (!isEqual(template.pages, pages)) {
      const newRouteSelectState: IRouteSelect = {}

      template.pages.map((page) => {
        newRouteSelectState[page.id] = {}
        page.blocks
          .filter(({ templateBlock }) => isRelatedBlock(templateBlock.componentName))
          .map(({ id, routeIndex }) => {
            newRouteSelectState[page.id][id] = routeIndex ? String(routeIndex) : 'null'
          })
      })

      setPages([...template.pages])

      setRouteSelect({
        ...newRouteSelectState,
      })
    }
  }, [template, pages])

  useEffect(() => {
    if (!activePageId) {
      dispatch(setTemplateActivePage(template.pages[0].id))
      setBlocks([])
      dispatch(ActionCreators.clearHistory())
    }

    let pageIndex = template.pages.findIndex((page) => page.id === activePageId)

    if (pageIndex === -1) {
      dispatch(setTemplateActivePage(template.pages[0].id))
      pageIndex = 0
    }

    if (JSON.stringify(template.pages[pageIndex].blocks) !== JSON.stringify(blocks)) {
      setBlocks(template.pages[pageIndex].blocks)
    }
  }, [activePageId, template])

  useEffect(() => {
    if (templateErrors) {
      setLiveErrors({ ...templateErrors.errors })
    }

    return () => {
      dispatch(setTemplateErrors(null))
    }
  }, [dispatch, templateErrors])

  const removeBlock = useCallback(
    (id: string) => {
      const removedBlockRouteIndex = blocks.find(
        (item) => item.id === id && isParentBlock(item.templateBlock.componentName),
      )?.routeIndex

      if (removedBlockRouteIndex) {
        const newBlocks = [...blocks].map((block) => {
          const newBlock = { ...block }

          if (newBlock.routeIndex === removedBlockRouteIndex) {
            delete newBlock.routeIndex
          }

          return newBlock
        })

        setBlocks([...newBlocks.filter((item) => item.id !== id)])
      } else {
        setBlocks([...blocks.filter((item) => item.id !== id)])
      }
    },
    [blocks],
  )

  const removeImageBlock = useCallback(
    async (id: string) => {
      const removedBlockImage = blocks.find((item) => item.id === id)?.image

      try {
        dispatch(setTemplateIsFetching(true))
        if (removedBlockImage) {
          await deleteTemplateImage({ agencyId, fileName: removedBlockImage })
        }
      } catch (err) {
        console.error(err)
      } finally {
        dispatch(setTemplateIsFetching(false))
        setBlocks((prevState) => [...prevState.filter((item) => item.id !== id)])
      }
    },
    [blocks, deleteTemplateImage, dispatch],
  )

  const getFreeRoutIndex = useCallback(() => {
    let index = 1

    routeIndexes.every((block) => {
      if (index === block) {
        index = block + 1

        return true
      }
    })

    return index
  }, [routeIndexes])

  const addDragBlock = useCallback(
    (x: number, y: number) => {
      const { block, cursorOffset } = dragElement
      const cursorOffsetX = cursorOffset?.x || 0
      const cursorOffsetY = cursorOffset?.y || 0
      let col = Math.floor((x - cursorOffsetX) / canvasColWidth)
      let row = Math.floor((y - cursorOffsetY) / canvasRowHeight)
      const { cols, rows } = block.properties

      if (col < 0) {
        col = 0
      } else if (canvasCols - col - cols < 0) {
        col = canvasCols - cols
      }

      if (row < 0) {
        row = 0
      } else if (canvasRows - row - rows < 0) {
        row = canvasRows - rows
      }

      const newId = uuid.v4().toString()

      setBlocks([
        ...blocks,
        {
          ...(isTextType(block.componentName) && {
            text: 'Text',
            cssStyles: {
              color: '#ffffff',
              backgroundColor: '#000000',
              fontSize: `${TEXT_DEFAULT_FONT_SIZE}rem`,
              textAlign: TextAlign.center,
              alignItems: AlignItems.center,
            },
          }),
          ...(isImageType(block.componentName) && {
            image: '',
          }),
          id: newId,
          templateBlockId: block.id,
          ...(isParentBlock(block.componentName) && {
            routeIndex: getFreeRoutIndex(),
          }),
          size: {
            cols,
            rows,
          },
          templateBlock: block,
          position: {
            col,
            row,
          },
        },
      ])

      dispatch(clearDragBlock())
    },
    [blocks, canvasColWidth, canvasCols, canvasRowHeight, canvasRows, dispatch, dragElement, getFreeRoutIndex],
  )

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (isDrag) {
      setIsDragOver(true)
    }
  }

  const leaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()

    setIsDragOver(false)
  }

  const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (isDrag) {
      const x = e.pageX - e.currentTarget.offsetLeft
      const y = e.pageY - e.currentTarget.offsetTop

      addDragBlock(x, y)
      setIsDragOver(false)
    }
  }

  useEffect(() => {
    if (addingBlock) {
      addDragBlock(canvasColWidth * 3, canvasRowHeight * 6)
    }
  }, [addDragBlock, addingBlock, canvasColWidth, canvasRowHeight])

  const onDragStop = (e: DraggableEvent, data: DraggableData, id: string) => {
    e.preventDefault()

    const x = data.x - data.deltaX
    const y = data.y - data.deltaY

    const copyBlocks = [...blocks]
    const objIndex = copyBlocks.findIndex((obj) => obj.id === id)

    copyBlocks[objIndex] = {
      ...copyBlocks[objIndex],
      position: {
        col: Math.round(x / canvasColWidth),
        row: Math.round(y / canvasRowHeight),
      },
    }
    if (JSON.stringify(blocks) !== JSON.stringify(copyBlocks)) {
      setBlocks([...copyBlocks])
    }
  }

  const onResizeStop = (e: Event, ref: HTMLElement, position: Position, id: string) => {
    e.preventDefault()

    const copyBlocks = [...blocks]
    const objIndex = copyBlocks.findIndex((obj) => obj.id === id)

    copyBlocks[objIndex] = {
      ...copyBlocks[objIndex],
      position: {
        col: Math.round(position.x / canvasColWidth),
        row: Math.round(position.y / canvasRowHeight),
      },
      size: {
        cols: Math.round(parseFloat(ref.style.width) / canvasColWidth),
        rows: Math.round(parseFloat(ref.style.height) / canvasRowHeight),
      },
    }
    if (JSON.stringify(blocks) !== JSON.stringify(copyBlocks)) {
      setBlocks([...copyBlocks])
    }

    setResizingDirection(null)
  }

  const onResizeStart = (direction: ResizeDirection, id: string) => {
    setResizingDirection({ id, direction })
  }

  useEffect(() => {
    rndRefs.current = rndRefs.current.slice(0, blocks.length)
  }, [blocks.length])

  useEffect(() => {
    blocks?.map(({ templateBlock, position, size }, index) => {
      const current = rndRefs.current[index]

      if (current) {
        current.updatePosition({
          x: canvasColWidth * position.col,
          y: canvasRowHeight * position.row,
        })
        current.updateSize({
          width: size?.cols ? size.cols * canvasColWidth : canvasColWidth * templateBlock.properties.cols,
          height: size?.rows ? size.rows * canvasRowHeight : canvasRowHeight * templateBlock.properties.rows,
        })
      }
    })
  }, [blocks, canvasColWidth, canvasRowHeight])

  useEffect(() => {
    if (template.pages[0].id && !historyCleared) {
      dispatch(ActionCreators.clearHistory())

      setHistoryCleared(true)
    }
  }, [template])

  const getResizeYHandleStyles = useCallback(
    (pos: ResizeDirection, id: string, componentName: TemplateComponentName, backgroundColor?: string) => ({
      height: '2em',
      left: '8em',
      right: '8em',
      width: 'auto',
      [pos]: `${canvasGutter}px`,
      opacity: resizingDirection?.direction === pos && resizingDirection.id === id ? '0.8' : '',
      color: isParentBlock(componentName) || isDarkColor(backgroundColor) ? '#fff' : '#555',
    }),
    [resizingDirection, canvasGutter],
  )

  const getResizeXHandleStyles = useCallback(
    (pos: ResizeDirection, id: string, componentName: TemplateComponentName, backgroundColor?: string) => ({
      width: '2em',
      top: '2em',
      bottom: '2em',
      height: 'auto',
      [pos]: `${canvasGutter}px`,
      opacity: resizingDirection?.direction === pos && resizingDirection.id === id ? '0.8' : '',
      color: isParentBlock(componentName) || isDarkColor(backgroundColor) ? '#fff' : '#555',
    }),
    [resizingDirection, canvasGutter],
  )

  const handleSelect = (event: SelectChangeEvent, id: string) => {
    const isValue = event.target.value !== 'null'

    const copyBlocks = [...blocks]
    const targetBlockIndex = copyBlocks.findIndex((block) => block.id === id)

    if (targetBlockIndex !== -1) {
      copyBlocks[targetBlockIndex] = {
        ...copyBlocks[targetBlockIndex],
        ...(isValue && { routeIndex: Number(event.target.value) }),
      }

      if (!isValue) {
        delete copyBlocks[targetBlockIndex].routeIndex
      }

      if (isValue && liveErrors) {
        setLiveErrors((prevState) => {
          if (!prevState) return prevState

          const targetErrorKey = `Pages[${activePageNumber}].Blocks[${targetBlockIndex}].RouteIndex`

          const { [targetErrorKey]: _, ...newErrors } = prevState

          return newErrors
        })
      }

      setBlocks([...copyBlocks])
    }
  }

  const getMinHeight = (rows: number) => {
    const minRows = displaySize === 'TwentyEightInchesEInk' && rows < 13 ? 12 : rows

    return minRows * canvasRowHeight
  }

  if (layoutsDataIsLoading) {
    return <Spinner />
  }

  if (!layout) {
    return null
  }

  return (
    <div className={styles.container}>
      {layout.header.isActive && (
        <CanvasHeader headerBlocks={layout.header.blocks} headerCssStyles={layout.header.cssStyles} />
      )}
      <div className={contentClass} onDragLeave={leaveHandler} onDragOver={dragOverHandler} onDrop={dropHandler}>
        <span className={styles.contentStripe} />
        {blocks.map(
          (
            { id, position, size, templateBlock: { properties, componentName }, routeIndex, cssStyles, text, image },
            index,
          ) => (
            <Rnd
              bounds="parent"
              className={`row-${size?.rows ? size.rows : properties.rows} col-${properties.cols} ${
                readOnly ? 'read-only' : ''
              } ${resizingDirection?.id === id ? 'active' : ''}`}
              default={{
                x: canvasColWidth * position.col,
                y: canvasRowHeight * position.row,
                width: size?.cols ? size.cols * canvasColWidth : canvasColWidth * properties.cols,
                height: size?.rows ? size.rows * canvasRowHeight : canvasRowHeight * properties.rows,
              }}
              disableDragging={readOnly || isFetching(id)}
              dragGrid={[canvasColWidth, canvasRowHeight]}
              dragHandleClassName="draggable-container-handler"
              enableResizing={
                readOnly || isFetching(id)
                  ? false
                  : {
                      top: true,
                      bottom: true,
                      right: isTextType(componentName) || isImageType(componentName),
                      left: isTextType(componentName) || isImageType(componentName),
                    }
              }
              key={id}
              minHeight={getMinHeight(properties.rows)}
              minWidth={properties.cols * canvasColWidth}
              onDragStop={(e, data) => onDragStop(e, data, id)}
              onResizeStart={(e, direction) => onResizeStart(direction, id)}
              // eslint-disable-next-line max-params
              onResizeStop={(e, direction, ref, delta, position) => onResizeStop(e, ref, position, id)}
              ref={(el) => (rndRefs.current[index] = el)}
              resizeGrid={[canvasColWidth, canvasRowHeight]}
              resizeHandleClasses={{
                bottom: 'handle-resize',
                top: 'handle-resize',
                right: 'handle-resize-x',
                left: 'handle-resize-x',
              }}
              resizeHandleStyles={{
                top: {
                  ...getResizeYHandleStyles('top', id, componentName, cssStyles?.backgroundColor),
                },
                bottom: {
                  ...getResizeYHandleStyles('bottom', id, componentName, cssStyles?.backgroundColor),
                },
                right: {
                  ...getResizeXHandleStyles('right', id, componentName, cssStyles?.backgroundColor),
                },
                left: {
                  ...getResizeXHandleStyles('left', id, componentName, cssStyles?.backgroundColor),
                },
              }}
            >
              <div
                className={`${styles.draggableHolder} ${isRelatedBlock(componentName) ? styles.draggableHolderEta : ''} 
                ${isTextType(componentName) ? styles.draggableHolderText : ''} 
                ${isImageType(componentName) ? styles.draggableHolderImage : ''}
                ${isFetching(id) ? 'box-loading' : ''} 
                ${
                  liveErrors && liveErrors[`Pages[${activePageNumber}].Blocks[${index}].Image`]
                    ? styles.draggableHolderImageError
                    : ''
                } 
                ${
                  isDarkColor(cssStyles?.backgroundColor) || componentName === 'TemplateRouteBlock'
                    ? 'dark-mode'
                    : 'light-mode'
                }`}
              >
                <TemplateBlockComponent
                  componentName={componentName}
                  cssStyles={cssStyles}
                  id={id}
                  image={image}
                  properties={properties}
                  readOnly={readOnly}
                  routeIndex={routeIndex}
                  text={text}
                  view={TemplateBlocksView.fakeData}
                />
                {isParentBlock(componentName) && (
                  <div className={styles.templateBlockPanel}>
                    <span>R #{routeIndex}</span>
                  </div>
                )}
                {isTextType(componentName) && !readOnly && (
                  <>
                    {/* <CanvasTextSettings */}
                    {/*   alignItems={editableBlockSettings?.cssStyles.alignItems} */}
                    {/*   backgroundColor={editableBlockSettings?.cssStyles.backgroundColor} */}
                    {/*   id={id} */}
                    {/* /> */}
                    <IconButton
                      className={styles.btnBlockSettings}
                      color="primary"
                      onClick={() => handleTextBlockSettingsOpen(id)}
                    >
                      <Settings />
                    </IconButton>
                    {/* <Popover */}
                    {/*   anchorEl={anchorEl} */}
                    {/*   anchorOrigin={{ */}
                    {/*     vertical: 'top', */}
                    {/*     horizontal: 'left', */}
                    {/*   }} */}
                    {/*   id={idPop} */}
                    {/*   key={index} */}
                    {/*   onClose={handleClose} */}
                    {/*   open={open} */}
                    {/* > */}
                    {/*   {index}- {id} */}
                    {/*   <VerticalAlignButtons
                    alignItems={editableBlockSettings?.cssStyles.alignItems} blockId={id} /> */}
                    {/* </Popover> */}
                  </>
                )}
                {isImageType(componentName) && !readOnly && !isFetching(id) && (
                  <IconButton
                    className={styles.btnBlockSettings}
                    color="primary"
                    onClick={() => handleImageBlockSettingsOpen(id)}
                  >
                    <Settings />
                  </IconButton>
                )}
                {isRelatedBlock(componentName) && routeSelect[activePageId] && (
                  <div className={styles.templateBlockInfo}>
                    {readOnly ? (
                      <span className={styles.templateBlockInfoText}>
                        <Link />R #{routeSelect[activePageId][id]}
                      </span>
                    ) : (
                      <Select
                        MenuProps={{ className: 'text-capitalize' }}
                        className="text-capitalize"
                        error={Boolean(
                          liveErrors && liveErrors[`Pages[${activePageNumber}].Blocks[${index}].RouteIndex`],
                        )}
                        fullWidth
                        onChange={(e) => handleSelect(e, id)}
                        value={routeSelect[activePageId][id] || 'null'}
                        variant="standard"
                      >
                        <MenuItem value="null">(Choose)</MenuItem>
                        {routeIndexes.map((route) => (
                          <MenuItem key={route} value={route}>
                            R #{route}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </div>
                )}
                {!readOnly && <div className="draggable-container-handler" />}
                {!readOnly && !isFetching(id) && (
                  <IconButton className={styles.btnBlockRemove} color="primary" onClick={() => removeBlock(id)}>
                    <Close />
                  </IconButton>
                )}
              </div>
            </Rnd>
          ),
        )}
      </div>
      {layout.footer.isActive && (
        <CanvasFooter
          footerBlocks={layout.footer.blocks}
          footerCssStyles={layout.footer.cssStyles}
          template={template}
        />
      )}
      {editableImageBlockId && (
        <ImageBlockSettingsPopup
          blockId={editableImageBlockId}
          handleClose={handleImageSettingsClose}
          handleFetching={setFetchingImage}
          handleSave={handleSaveImageBlockSettings}
          isOpen={imageBlockSettingsOpen}
        />
      )}
      {editableBlockSettings && (
        <TextBlockSettingsPopup
          handleClose={handleBlockSettingsClose}
          handleSave={handleSaveTextBlockSettings}
          open={textBlockSettingsOpen}
          textBlockSettings={editableBlockSettings}
        />
      )}
    </div>
  )
}
