import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'

import { LANGUAGES } from '~constants/constants'
import { RootState } from '~stores/store'

export const prepareHeaders: FetchBaseQueryArgs['prepareHeaders'] = (headers, { getState }) => {
  const state = getState() as RootState
  const { accessToken } = state.auth.data
  const language = localStorage.getItem('i18nextLng')

  headers.set('Accept', 'application/json')
  headers.set('Authorization', `Bearer ${accessToken}`)
  headers.set('I18N-Language', language || LANGUAGES[0])

  return headers
}
