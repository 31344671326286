import React, { FC } from 'react'

import styles from './spinner.module.scss'

interface SpinnerProps {
  size?: 'small' | 'large'
}

export const Spinner: FC<SpinnerProps> = ({ size }) => (
  <div className={`${styles.spinner}  ${styles.absolute} ${size === 'small' ? styles.small : ''}`} />
)
