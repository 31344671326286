import React, { FC, useMemo } from 'react'

import { TemplateBlocksView, TemplateComponentName } from '~/enums/template-blocks.enum'
import { TemplateBlockComponent } from '~components/TemplateBlockComponent/template-block-component'
import { sortPageBlocksByRouteIndex } from '~helpers/sort-page-blocks-by-route-index'
import { ITemplatePage } from '~models/template.model'

import styles from './preview-canvas.module.scss'

interface PreviewCanvasPageProps {
  page: ITemplatePage
  canvasRowHeight: number
  canvasColWidth: number
  activePageId?: string
  renderView?: boolean
}

export const PreviewCanvasPage: FC<PreviewCanvasPageProps> = ({
  page,
  canvasRowHeight,
  canvasColWidth,
  activePageId,
  renderView,
}) => {
  const sortedBlocks = useMemo(() => sortPageBlocksByRouteIndex(page.blocks), [page.blocks])

  return (
    <div
      className={styles.content}
      id={page.id}
      key={page.id}
      style={{ display: renderView || (activePageId && page.id !== activePageId) ? 'none' : 'block' }}
    >
      {sortedBlocks.map(
        ({ id, position, size, cssStyles, text, image, templateBlock: { componentName, properties }, routeIndex }) => (
          <div
            className={`${styles.previewBlock} preview-canvas-block-root ${
              componentName === TemplateComponentName.image ? styles.draggableHolderImage : ''
            }`}
            key={id}
            style={{
              top: canvasRowHeight * position.row,
              left: canvasColWidth * position.col,
              height: size?.rows ? size.rows * canvasRowHeight : canvasRowHeight * properties.rows,
              width: size?.cols ? size.cols * canvasColWidth : canvasColWidth * properties.cols,
            }}
          >
            <TemplateBlockComponent
              componentName={componentName}
              cssStyles={cssStyles}
              image={image}
              properties={properties}
              routeIndex={routeIndex}
              text={text}
              view={TemplateBlocksView.realData}
            />
          </div>
        ),
      )}
    </div>
  )
}
