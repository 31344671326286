import { Button, FormControlLabel, Switch } from '@mui/material'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNavigateBlocker } from '~/hooks/use-navigate-blocker'
import { getFormData } from '~/networks/get-form-data'
import { ConfirmLeavingPopup } from '~components/Modal/ConfirmLeavingPopup/confirm-leaving-popup'
import { getBase64 } from '~helpers/get-base64'
import { getErrorsValues } from '~helpers/get-errors-values'
import { IErrorRequest, IErrorRequestData } from '~models/error-request.model'
import { ILayout } from '~models/layouts.model'
import styles from '~pages/Configurator/configurator.module.scss'
import { useAppDispatch } from '~stores/hooks'
import { usePostAgencyLogoMutation } from '~stores/services/agency.api'
import { usePutLayoutsMutation } from '~stores/services/layouts.api'
import { clearAgencyLogoTemp, setAgencyLogo, useAgencyLogoBlob } from '~stores/slices/agency-logo.slice'
import { useAgencyId } from '~stores/slices/auth.slice'
import {
  setLayoutFieldErrors,
  setLayoutFooterIsActive,
  setLayoutHeaderIsActive,
  useLayout,
  useLayoutFieldErrors,
  useLayoutFooterIsActive,
  useLayoutHeaderIsActive,
} from '~stores/slices/layout.slice'

const confirmPopupText = {
  title: 'changes-not-saved',
  message: 'do-you-want-to-save-changes-before-leaving',
  notSaveText: 'discard',
  saveText: 'save',
}

interface ConfiguratorBarProps {
  initialLayout: ILayout
  handleLoading: (val: boolean) => void
}

export const ConfiguratorBar: FC<ConfiguratorBarProps> = ({ initialLayout, handleLoading }) => {
  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const agencyId = useAgencyId()

  const layout = useLayout()
  const agencyLogoBlob = useAgencyLogoBlob()
  const headerIsActive = useLayoutHeaderIsActive()
  const footerIsActive = useLayoutFooterIsActive()
  const layoutFieldErrors = useLayoutFieldErrors()

  const [savedLayout, setSavedLayout] = useState<ILayout>(initialLayout)
  const [isNavigateBlocked, setIsNavigateBlocked] = useState(false)
  const [showConfirmPopup, confirmNavigation, cancelNavigation] = useNavigateBlocker(isNavigateBlocked)
  const [layoutWasChanged, setLayoutWasChanged] = useState(false)
  const [layoutErrors, setLayoutErrors] = useState(false)

  const [updateLayouts, { isLoading: updateLayoutsIsLoading }] = usePutLayoutsMutation()
  const [updateAgencyLogo, { isLoading: updateAgencyLogoIsLoading }] = usePostAgencyLogoMutation()

  const getEnqueueSnackbarErrors = useCallback(
    (errorData: IErrorRequestData) => {
      getErrorsValues(errorData).forEach((error) => {
        enqueueSnackbar(<div style={{ maxWidth: 300 }}>{error}</div>, {
          variant: 'error',
        })
      })
    },
    [enqueueSnackbar],
  )

  const handleConfiguration = useCallback(async () => {
    let abilityToSaveLayout = true

    if (agencyLogoBlob) {
      try {
        await updateAgencyLogo({
          agencyId,
          image: getFormData('logoFile', agencyLogoBlob),
        }).unwrap()

        getBase64(agencyLogoBlob).then((base64) => {
          if (typeof base64 === 'string') {
            dispatch(setAgencyLogo(base64))
            dispatch(clearAgencyLogoTemp())
          }
        })

        if (!layoutWasChanged) {
          enqueueSnackbar(translate('changes-saved'))
        }

        if (showConfirmPopup) {
          confirmNavigation()
        }
      } catch (err) {
        console.error(err)
        enqueueSnackbar(translate('logo-not-saved'), { variant: 'warning' })
        abilityToSaveLayout = false

        cancelNavigation()
      }
    }

    if (abilityToSaveLayout && layoutWasChanged) {
      try {
        await updateLayouts({ ...layout, agencyId }).unwrap()

        enqueueSnackbar(translate('changes-saved'))

        setSavedLayout({ ...layout })
        if (showConfirmPopup) {
          confirmNavigation()
        }

        dispatch(setLayoutFieldErrors(null))
      } catch (err) {
        const error = err as IErrorRequest

        console.error(err)
        dispatch(setLayoutFieldErrors(error.data))
        getEnqueueSnackbarErrors(error.data)

        cancelNavigation()
      }
    }
  }, [layout, showConfirmPopup, agencyLogoBlob, layoutWasChanged])

  useEffect(() => {
    if (isEqual(savedLayout, layout)) {
      setLayoutWasChanged(false)
    } else {
      setLayoutWasChanged(true)
    }
  }, [savedLayout, layout])

  useEffect(() => {
    if (layoutWasChanged || agencyLogoBlob) {
      setIsNavigateBlocked(true)

      return
    }

    setIsNavigateBlocked(false)
  }, [layoutWasChanged, agencyLogoBlob])

  const handleHeaderVisibility = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setLayoutHeaderIsActive(event.target.checked))
    },
    [dispatch],
  )

  const handleFooterVisibility = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setLayoutFooterIsActive(event.target.checked))
    },
    [dispatch],
  )

  const confirmNavigationAndSave = useCallback(() => {
    handleConfiguration()
  }, [handleConfiguration, confirmNavigation])

  useEffect(() => {
    handleLoading(updateLayoutsIsLoading || updateAgencyLogoIsLoading)
  }, [updateLayoutsIsLoading, updateAgencyLogoIsLoading])

  useEffect(() => {
    if (layoutFieldErrors?.length) {
      setLayoutErrors(true)

      return
    }

    setLayoutErrors(false)
  }, [layoutFieldErrors])

  return (
    <>
      <div className={styles.configuratorBar}>
        <FormControlLabel
          control={<Switch checked={headerIsActive} name="header" onChange={handleHeaderVisibility} size="small" />}
          label="Header"
        />
        <FormControlLabel
          control={<Switch checked={footerIsActive} name="footer" onChange={handleFooterVisibility} size="small" />}
          label="Footer"
        />
        <div className="spacer" />
        <Button
          disabled={(!layoutWasChanged && !agencyLogoBlob) || layoutErrors}
          onClick={handleConfiguration}
          size="medium"
          variant="contained"
        >
          {translate('save')}
        </Button>
      </div>
      <ConfirmLeavingPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        confirmNavigationAndSave={confirmNavigationAndSave}
        confirmPopupText={confirmPopupText}
        showDialog={showConfirmPopup}
      />
    </>
  )
}
