import { Clear } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface TableSearchProps {
  placeholder?: string
  width?: string | number
  handleSearch: (value: string) => void
}

export const TableSearch: FC<TableSearchProps> = ({ placeholder, width = 250, handleSearch }) => {
  const { t: translate } = useTranslation()
  const [searchValue, setSearchValue] = useState('')

  const handleClearSearchField = () => {
    setSearchValue('')
    handleSearch('')
  }

  const onChangeSearch = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue.length > 2) {
        handleSearch(searchValue)
      } else {
        handleSearch('')
      }
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [handleSearch, searchValue])

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton onClick={handleClearSearchField} size="small" sx={{ display: !searchValue ? 'none' : '' }}>
            <Clear fontSize="small" />
          </IconButton>
        ),
      }}
      onChange={onChangeSearch}
      placeholder={placeholder || translate('template-name')}
      sx={{
        width,
        '.MuiInput-input': {
          height: 28,
        },
      }}
      value={searchValue}
      variant="standard"
    />
  )
}
