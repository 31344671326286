import React, { CSSProperties, FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { IBusStopStatusesModel } from '~/enums/bus-stop-templates.enum'
import { splitByCapitalLetter } from '~helpers/split-by-capital-letter'
import { ITemplateStatusesModel } from '~models/template.model'

import styles from './status.module.scss'

interface StatusProps {
  status: ITemplateStatusesModel | IBusStopStatusesModel
  secondary?: ReactNode
  button?: ReactNode
  style?: CSSProperties
}

export const Status: FC<StatusProps> = ({ status, secondary, button, style }) => {
  const { t: translate } = useTranslation()

  return (
    <div className={styles.status} style={style}>
      <div className={styles.statusHolder}>
        <div className={styles.statusText}>
          <span className={styles.statusMark} style={{ color: status.color }} />
          {translate(splitByCapitalLetter(status.name))}
        </div>
        {secondary && <div className={styles.statusSecondary}>{secondary}</div>}
      </div>
      {button && button}
    </div>
  )
}
