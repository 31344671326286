import { Colors } from '~/enums/colors.enum'
import { PageUrls } from '~/enums/page-urls.enum'

export enum TemplateType {
  Default = 'Default',
  Emergency = 'Emergency',
  Transit = 'Transit',
}

export type TemplateTypeKeys = keyof typeof TemplateType

type TemplateTypeWithoutDefault = Exclude<TemplateType, TemplateType.Default>

export interface IBusStopDeployedModel {
  color: string
  name: TemplateType
  label: string
}

export type IBusStopDeployed = {
  [key in TemplateTypeKeys]: IBusStopDeployedModel
}

export interface ITemplateTypeConfigModel {
  pageUrl: string
  listUrl: string
  createUrl: string
}

export type ITemplateTypeConfig = {
  [key in TemplateTypeWithoutDefault]: ITemplateTypeConfigModel
}

export const BusStopDeployed: IBusStopDeployed = {
  Default: {
    color: Colors.info,
    name: TemplateType.Default,
    label: 'def',
  },
  Emergency: {
    color: Colors.danger,
    name: TemplateType.Emergency,
    label: 'emg',
  },
  Transit: {
    color: Colors.success,
    name: TemplateType.Transit,
    label: 'tran',
  },
}

export const TemplateTypeConfig: ITemplateTypeConfig = {
  Transit: {
    pageUrl: PageUrls.Template,
    listUrl: PageUrls.Templates,
    createUrl: PageUrls.TemplateCreate,
  },
  Emergency: {
    pageUrl: PageUrls.EmergencyTemplate,
    listUrl: PageUrls.EmergencyTemplates,
    createUrl: PageUrls.EmergencyTemplateCreate,
  },
}
