import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TemplateComponentName } from '~/enums/template-blocks.enum'
import { DragBlockModel, IDragBlock } from '~models/drag-block.model'
import { useAppSelector } from '~stores/hooks'
import { RootState } from '~stores/store'

const initialState: IDragBlock = {
  data: {
    block: {
      id: 0,
      componentName: TemplateComponentName.route,
      properties: {
        cols: 0,
        rows: 0,
        mode: '',
      },
    },
    cursorOffset: {
      x: 0,
      y: 0,
    },
  },
  isDrag: false,
  addingBlock: false,
}

const dragBlockSlice = createSlice({
  name: 'DragBlock',
  initialState,
  reducers: {
    setDragBlock: (state, { payload }: PayloadAction<DragBlockModel>) => {
      state.data = payload
    },
    clearDragBlock: (state) => {
      state.data = initialState.data
      state.isDrag = false
      state.addingBlock = false
    },
    setIsDrag: (state, { payload }: PayloadAction<boolean>) => {
      state.isDrag = payload
    },
    setAddingBlock: (state, { payload }: PayloadAction<boolean>) => {
      state.addingBlock = payload
    },
  },
})

export const getDragBlock = (state: RootState) => state.dragBlock.data
export const useDragBlock = () => useAppSelector(getDragBlock)
export const getIsDrag = (state: RootState) => state.dragBlock.isDrag
export const useIsDrag = () => useAppSelector(getIsDrag)
export const getAddingBlock = (state: RootState) => state.dragBlock.addingBlock
export const useAddingBlock = () => useAppSelector(getAddingBlock)

export const {
  reducer: dragBlockReducer,
  actions: { setDragBlock, setIsDrag, setAddingBlock, clearDragBlock },
} = dragBlockSlice
