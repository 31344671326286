import React, { FC, useMemo } from 'react'

import { TemplateBlocksView } from '~/enums/template-blocks.enum'
import styles from '~components/TemplateBlockComponent/blocks/template-blocks.module.scss'
import { TemplateFrameSimple } from '~components/TemplateBlockComponent/frames/template-frame-simple'
import { ITemplateBlockProperties } from '~models/template-block.model'

export interface TemplateMessageBlockProps extends ITemplateBlockProperties {
  mode: 'rte-message' | 'gen-message' | string
}

export const TemplateMessageBlock: FC<TemplateMessageBlockProps> = ({ mode, view }) => {
  const blockClass = view === TemplateBlocksView.pure ? styles.templateBlockPure : styles.templateBlock
  const text = useMemo(() => {
    if (mode === 'rte-message') return 'Rte Message'
    if (mode === 'gen-message') return 'Gen Message'

    return null
  }, [mode])

  return <div className={blockClass}>{text && <TemplateFrameSimple dataType="message" text={text} view={view} />}</div>
}
