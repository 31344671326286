import { PageUrls } from '~/enums/page-urls.enum'
import { ITemplateTypeConfigModel, TemplateType, TemplateTypeConfig } from '~/enums/template-type.enum'

export const getTemplateUrl = (templateType: TemplateType, value: keyof ITemplateTypeConfigModel = 'pageUrl') => {
  if (templateType === TemplateType.Default) {
    return PageUrls.DefaultTemplate
  }

  return TemplateTypeConfig[templateType][value]
}
