import React from 'react'
import { Outlet } from 'react-router-dom'

import { Header } from '~components/Header/header'

import styles from './layout.module.scss'

export const Layout = () => (
  <main className={styles.main}>
    <Header />
    <Outlet />
  </main>
)
